import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { privateAPI } from "../../config/constants";
import useDebounce from "../../Hook/useDebounce";

const { Option } = Select;

interface SearchableDropdownProps {
  mode: boolean;
  // onSelect?: (value: string | string[]) => void;
  onChange?: any;
  defaultValue?: string | string[] | any | undefined;
  setFetchedOptions?: any;
  url: string;
  dataSend?: any;
  selectedUrl?: string;
  onEdit?: boolean | undefined;
  columns?: string[];
  placeholder?: string;
  type?: string;
  currentData?: any;
  params?:{}
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  mode,
  onChange,
  defaultValue,
  url,
  selectedUrl,
  onEdit,
  setFetchedOptions,
  // sendData,
  columns = ["firstName", "lastName"],
  placeholder,
  type,
  currentData,
  params={}
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  // const [searchTerm, setSearchTerm] = useState<String[] | String>([] || "");
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });

  const debouncedFunction = useDebounce((value: string, key: string) => {
    setOptions([]);
    setSearchTerm(value);
    setPagination({ page: 1, perPage: 10 });
  }, 1000);
  // useEffect(() => {
  //   dataSend();
  // }, []);

  useEffect(() => {
    // if (searchTerm.length >= 3) {
    //   fetchData();
    // }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, pagination.page, defaultValue,currentData]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await privateAPI.post(url, {
        search: searchTerm ? searchTerm : "",
        page: pagination.page,
        limit: pagination.perPage,
        ...params
      });
      let responseSelected = { data: { data: { users: [] } } };
      if (onEdit && selectedUrl) {
        responseSelected = await privateAPI.post(selectedUrl, {
          selectedIds: defaultValue,
        });
      }

      if (response) {
        const data: any = Object.entries(response?.data)[0][1];
        const totalDoc = response?.data?.count;
      if (pagination.page === 1) {
          setOptions(data);
          // sendData(data);
          setFetchedOptions && setFetchedOptions(data);
        } else {
          setOptions((prevOptions) => [...prevOptions, ...data]);
          // sendData((prevOptions: any) => [...prevOptions, ...data]);
          const _options = [...options, ...data];
          setFetchedOptions && setFetchedOptions(_options);
        }
        setTotalDocs(totalDoc);
      }
      if (responseSelected) {
        setSelectedOptions(responseSelected?.data?.data?.users);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
      //
    }
  };

  const handleSearch = (value: string) => {
    debouncedFunction(value);
  };

  const handleDropdownScroll = (event: any) => {
    const { target } = event;
    if (
      Math.floor(target.scrollHeight - target.scrollTop) ===
        target.clientHeight &&
      options.length < totalDocs
    ) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleChange = (value: any) => {
    onChange && onChange(value);
  };
  return (
    <Select
      mode={mode ? "multiple" : undefined}
      showSearch
      value={defaultValue}
      placeholder={placeholder ? placeholder : "Search by Name or Email"}
      notFoundContent={
        loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              width={60}
              height={50}
              src={require("../../assets/loadingGrid.gif")}
              alt="loading"
            />
            <p>Loading....</p>
          </div>
        ) : null
      }
      filterOption={false}
      onSearch={handleSearch}
      // onChange={(e) => {
      //   sendData(e);
      // }}
      onChange={handleChange}
      onPopupScroll={handleDropdownScroll}
      style={{ width: "100%" }}
      loading={loading}
      maxTagCount={"responsive"}
    >
      {/* {options?.map((item: any, index: number) => (
        <Option key={type === 'monitoring' ? item.id : item.classId} value={type === 'monitoring' ? item.id : item.classId}>
          {`${columns.map((col: string) =>
            col === "email" ? `(${item[col]})` : item[col]
          )}`.replace(/,/g, " ")}
        </Option>
      ))} */}
      {options
        ?.filter((item: any) =>
          type === "classes" ? item.classId !== currentData.classId : true
        )
        ?.map((item: any, index: number) => (
          <Option
          key={
            type === "monitoring"
              ? item.id
              : type === "subject"
              ? item.subjectId
              : type === "firstName"
              ? item.firstName
               : columns.includes("firstName") && columns.includes("lastName")
              ? `${item.firstName} ${item.LastName}`
              : item.classId
          }
          value={
            type === "monitoring"
              ? item.id
              : type === "subject"
              ? item.subjectId
              : type === "firstName"
              ? item.firstName
              : columns.includes("firstName") && columns.includes("lastName")
              ? `${item.firstName} ${item.LastName}` 
              : item.classId
          }
      
      >
        {columns.includes("firstName") && !columns.includes("lastName")
          ? `${item.firstName}` // Only firstName present in columns
          : columns.includes("firstName") && columns.includes("lastName")
          ? `${item.firstName} ${item.LastName}` // Both firstName and lastName present
          : `${columns.map((col: string) => item[col]).join(" ")}`} {/* Other columns */}
      </Option>
        ))}

      {onEdit &&
        selectedOptions &&
        selectedOptions
          .filter(
            (item) => !options.some((opt) => opt.classId === item.classId)
          ) // Filter out selected options that are already in 'options'
          .map((item: any, index: number) => (
            // <Option key={ type === 'monitoring' ? item.id: item.classId} value={ type === 'monitoring' ? item.id: item.classId}>
            //   {`${columns.map((col: string) =>
            //     col === "email" ? `(${item[col]})` : item[col]
            //   )}`.replace(/,/g, " ")}
            // </Option>
            <Option
              key={type === "monitoring" ? item.id : item.classId || item.id}
              value={type === "monitoring" ? item.id : item.classId || item.id}
            >

              {`${columns.map((col: string) =>
                col === "email" ? `(${item[col]})` : item[col]
              )}`.replace(/,/g, " ")}
            </Option>
          ))}
    </Select>
  );
};

export default SearchableDropdown;

// import React, { useState, useEffect } from "react";

// import { Select } from "antd";

// import { privateAPI } from "../../config/constants";

// import useDebounce from "../../Hook/useDebounce";

// const { Option } = Select;

// interface SearchableDropdownProps {

//   mode: boolean;

//   // onSelect?: (value: string | string[]) => void;

//   onChange?: any;

//   defaultValue?: string | string[] | any | undefined;

//   setFetchedOptions?: any;

//   url: string;

//   selectedUrl?: string;

//   onEdit?: boolean | undefined;

//   columns?: string[];

//   placeholder?: string;

// }

// const SearchableDropdown: React.FC<SearchableDropdownProps> = ({

//   mode,

//   onChange,

//   defaultValue,

//   url,

//   selectedUrl,

//   onEdit,

//   setFetchedOptions,

//   columns = ["className"],

//   placeholder,

// }) => {

//   const [options, setOptions] = useState<any[]>([]);

//   console.log("options",options);

//   const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

//   const [totalDocs, setTotalDocs] = useState<number>(0);

//   const [loading, setLoading] = useState<boolean>(false);

//   const [searchTerm, setSearchTerm] = useState<String>("");

//   const [pagination, setPagination] = useState<{

//     page: number;

//     perPage: number;

//   }>({ page: 1, perPage: 10 });

//   const debouncedFunction = useDebounce((value: string, key: string) => {

//     setOptions([]);

//     setSearchTerm(value);

//     setPagination({ page: 1, perPage: 10 });

//   }, 1000);

//   useEffect(() => {

//     // if (searchTerm.length >= 3 || defaultValue) {

//     //   fetchData();

//     // }

//     fetchData();

//     // eslint-disable-next-line react-hooks/exhaustive-deps

//   }, [searchTerm, pagination.page, defaultValue]);

//   const fetchData = async () => {

//     setLoading(true);

//     try {

//       const response = await privateAPI.post(url, {

//         search: searchTerm,

//         page: pagination.page,

//         limit: pagination.perPage,

//       });

//       let responseSelected = { data: { data: { users: [] } } };

//       if (onEdit && selectedUrl) {

//         responseSelected = await privateAPI.post(selectedUrl, {

//           selectedIds: defaultValue,

//         });

//       }

//       if (response) {

//         const data: any = Object.entries(response?.data?.data)[0][1];

//         const totalDoc = response?.data?.data?.totalDocs;

//         console.log("totalDoc",totalDoc);

//         if (pagination.page === 1) {

//           setOptions(data);

//           setFetchedOptions && setFetchedOptions(data);

//         } else {

//           setOptions((prevOptions) => [...prevOptions, ...data]);

//           const _options = [...options, ...data];

//           setFetchedOptions && setFetchedOptions(_options);

//         }

//         setTotalDocs(totalDoc);

//       }

//       if (responseSelected) {

//         setSelectedOptions(responseSelected?.data?.data?.users);

//       }

//     } catch (error) {

//       console.error("Failed to fetch data:", error);

//     } finally {

//       setLoading(false);

//     }

//   };

//   const handleSearch = (value: string) => {

//     debouncedFunction(value);

//   };

//   const handleDropdownScroll = (event: any) => {

//     const { target } = event;

//     if (

//       Math.floor(target.scrollHeight - target.scrollTop) ===

//         target.clientHeight &&

//       options.length < totalDocs

//     ) {

//       setPagination((prev) => ({ ...prev, page: prev.page + 1 }));

//     }

//   };

//   const handleChange = (value: any) => {

//     onChange && onChange(value);

//   };

//   useEffect(() => {

//     if (onEdit && defaultValue) {

//       fetchData();

//     }

//   }, [onEdit, defaultValue]);

//   return (

//     <Select

//       mode={mode ? "multiple" : undefined}

//       showSearch

//       value={defaultValue}

//       placeholder={placeholder ? placeholder : "Search by Name or Email"}

//       notFoundContent={

//         loading ? (

//           <div

//             style={{

//               display: "flex",

//               flexDirection: "column",

//               justifyContent: "center",

//               alignItems: "center",

//               textAlign: "center",

//             }}

//           >

//             <img

//               width={60}

//               height={50}

//               src={require("../../assets/loadingGrid.gif")}

//               alt="loading"

//             />

//             <p>Loading....</p>

//           </div>

//         ) : null

//       }

//       filterOption={false}

//       onSearch={handleSearch}

//       onChange={handleChange}

//       onPopupScroll={handleDropdownScroll}

//       style={{ width: "100%" }}

//       loading={loading}

//       maxTagCount={"responsive"}

//     >

//       {options.map((item: any, index: number) => {
//       console.log("items",item);
//       return (

//         <Option key={item._id} value={item._id}>

//           {`${columns.map((col: string) => item[col])}`.replace(/,/g, " ")}

//         </Option>

//       )})}

//       {onEdit &&

//         selectedOptions &&

//         selectedOptions

//           .filter((item) => !options.some((opt) => opt._id === item._id)) // Filter out selected options that are already in 'options'

//           .map((item: any, index: number) => (

//             <Option key={item._id} value={item._id}>

//               {`${columns.map((col: string) => item[col])}`.replace(/,/g, " ")}

//             </Option>

//           ))}

//     </Select>

//   );

// };

// export default SearchableDropdown;
