
import { createSlice } from "@reduxjs/toolkit";
import { SettingState } from "../interfaces/settings";


interface StatesState {
    loading: boolean,
    aboveTarget: null | [],
    belowTarget: null | [],
    topForformer: null | [],
    leastForformer: null | [],
};
const initialState: StatesState = {
    loading: false,
    aboveTarget: [],
    belowTarget: [],
    topForformer: [],
    leastForformer:[]
};

export const states = createSlice({
    name: "states",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setAboveTarget(state, action) {
            console.log("action", action)
            state.aboveTarget = action?.payload;
        },

        setBelowTarget(state, action) {
            state.belowTarget = action?.payload
        },
        setTopForformer(state, action) {
            state.topForformer = action?.payload
        },
        setLeastForformer(state, action) {
            state.leastForformer = action?.payload
        }
    },
});

export const { setLoading, setAboveTarget, setBelowTarget, setLeastForformer, setTopForformer } = states.actions;

export default states.reducer;
