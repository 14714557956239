


import { createSlice } from "@reduxjs/toolkit";
import { Planner, InitialPlanner } from "../../interfaces/studentPlanner/studentPlanner";
import { PlannerDetails } from "../../interfaces/studentPlannerDetials/studentPlannerDetials";
import { setLoading } from "../settingSlice";
import { stat } from "fs";

const initialState: PlannerDetails = {
  plans: [],
  loading:false
};

const planDetail = createSlice({
  name: "planner",
  initialState,
  reducers: {
    setStudentPlanner(state,action){
        state.plans = action.payload;
    },
    setLoadingTrue(state){
        state.loading = true
    },
    setLoadingFalse(state){
        state.loading = false;
    }
  },
});

export const {setStudentPlanner,setLoadingFalse,setLoadingTrue} = planDetail.actions;

export default planDetail.reducer;
