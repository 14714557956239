import { Button, Input } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import Layout from "../components/layout";
import { useNavigate } from "react-router-dom";

const CreateAnnouncment = () => {
  const Navigate =useNavigate()
  return (

  //  < Layout>
    <div className="crateAnnouncment">
      <div className="announcmentTop">
        <div>
          <h2>Announcment</h2> <p>Some random text here.</p>
        </div>
      </div>
      <div className="createAnnouncmentmain">
        <div className="createAnnouncmentHead">
          <div className="yourprofile">
            <h2>Create announcement</h2>
          </div>
          <div className="buttons">
            <Button   
            //@ts-ignore
            onClick={ ()=>{Navigate(-1)} }>Cancel</Button>
            <Button>save</Button>
          </div>
        </div>
        <div className="createannouncmentFilds">
          <div>
            <h2 className="name">Title</h2>
          </div>
          <Input
            className="InputField"
            placeholder="Professional Development Workshop Series"
          />
        </div>
        <hr />
        <div className="createannouncmentFilds">
          <div>
            <h2 className="name">Description</h2>
          </div>
          <textarea
            //@ts-ignore
            row="5"
            className="InputField"
            placeholder="Calling all educators! Enhance your teaching skills and stay updated with the latest pedagogical trends through our series of professional development workshops. Explore innovative teaching strategies, technology integration, classroom management techniques, and more. Don't miss this opportunity for continuous growth and learning!"
          />
        </div>

        <hr />

        <div className="createannouncmentUploadimag">
          <div className="email">
            <h2 className="">Profile Photo</h2>
          </div>
          <div className="Dragger">
            <Dragger className="custom_dragger">
              <p className="ant-upload-drag-icon">
                <LuUploadCloud className="uploadicon" />
              </p>
              <p className="ant-upload-text">
                {" "}
                <strong>Click to upload</strong> or drag and drop
              </p>
              <p className="ant-upload-hint">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </Dragger>
          </div>
        </div>
        <hr />

        <div className="bottombtton">
          <div className="buttonsmobile">
            <Button>Update logo</Button>
            <Button  
            //@ts-ignore
            onClick={()=>{Navigate(-1)} }>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
    // {/* </Layout> */}
  );
};

export default CreateAnnouncment;
