import React, { useState } from "react";
import moment from "moment";

// @ts-ignore
import clockIcon from "../../assets/Clock.svg";
import { ScheduleType } from "../../store/interfaces/schedule";
import { useAppSelector } from "../../store/hooks";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { CgRadioChecked } from "react-icons/cg";
import { ScheduleCardIcon } from "../../components/Icons";

import { BsTrash } from "react-icons/bs";
import { Modal } from "antd";
import { PiWarningCircleFill } from "react-icons/pi";
import { deleteSchedule } from "../../store/services/schedule";
import CreateSchedule from "../../components/Modals/CreateSchedule";
import { checkRoutePermissions } from "../../utils/permission";

const { confirm } = Modal;

interface Props {
  days: string[];
  times: string[];
}

const ScheduleMobileScreen = ({ days, times }: Props) => {
  const { schedules } = useAppSelector((state) => state.schedule);
  const [currentWeekDay, setCurrentWeekDay] = useState(moment().day());

  const handleDayChange = (action: string) => {
    let newDay = currentWeekDay;
    if (action === "next") {
      newDay = (currentWeekDay + 1) % 7;
    } else {
      newDay = (currentWeekDay - 1 + 7) % 7;
    }
    setCurrentWeekDay(newDay);
  };

  return (
    <div className="_scheduleContainerMobile responsive">
      <h2>Schedule</h2>
      <div className="_scheduleBoxMobile">
        <div className="_scheduleBoxMobileHeader">
          <MdArrowBackIos onClick={() => handleDayChange("back")} />
          <h2>{days[currentWeekDay]}</h2>
          <MdArrowForwardIos onClick={() => handleDayChange("next")} />
        </div>
        {times.map((time, index) => {
          const currentTime = moment().format("HH:mm");
          const nextTime = times[index + 1] || "17:00";
          const showChecked = moment(currentTime, "HH:mm").isBetween(
            moment(time, "HH:mm"),
            moment(nextTime, "HH:mm"),
            null,
            "[]"
          );

          const scheduleForTimeSlot = schedules?.filter((schedule) => {
            return (
              schedule.day.toLowerCase() ===
                days[currentWeekDay].toLowerCase() &&
              moment(schedule.startTime, "HH:mm").isSameOrAfter(
                moment(time, "HH:mm")
              ) &&
              moment(schedule.startTime, "HH:mm").isBefore(
                moment(nextTime, "HH:mm")
              )
            );
          });

          return (
            <div key={index} className="_scheduleBoxMobileRow">
              <div className="_time">
                {time}
                {showChecked && <CgRadioChecked color="rgba(23, 92, 211, 1)" />}
              </div>
              <div
                className="_schedule"
                style={{
                  borderTop:
                    index === 0 ? "none" : "1px solid rgba(236, 238, 245, 1)",
                }}
              >
                {scheduleForTimeSlot &&
                  scheduleForTimeSlot?.map((data, i) => {
                    return <ScheduleCard key={i} data={data} />;
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScheduleCard = ({ data }: { data: ScheduleType }) => {
  const showPromiseConfirm = () => {
    confirm({
      title: "Are you sure you want to delete?",
      icon: (
        <PiWarningCircleFill
          size={30}
          color="orange"
          style={{ marginRight: "10px" }}
        />
      ),
      content:
        "This item will be deleted immediately. You can't undo this action.",
      async onOk() {
        await deleteSchedule({ scheduleId: data?.scheduleId });
      },
      onCancel() {},
    });
  };

  return (
    <div
      className="_scheduleCard _scheduleCardForMobile "
      style={{
        borderColor: `rgb(${data.color})`,
        backgroundColor: `rgba(${data.color}, 0.1)`,
      }}
    >
      <div className="_scheduleCardTop">
        <img src={clockIcon} alt="Clock" />
        <p>
          {data.startTime} - {data.endTime}
        </p>
      </div>
      <div className="_scheduleCardBottom">
        <p>{data.className}</p>
      </div>
      <ScheduleCardIcon fill={`rgb(${data.color})`} className="_scheduleIcon" />
      <div className="_info">
        {/* For View Only */}
        {checkRoutePermissions("viewSchedule") && (
          <CreateSchedule view={true} editData={data} />
        )}

        {/* For Update Only */}
        {checkRoutePermissions("updateSchedule") && (
          <CreateSchedule editData={data} />
        )}

        {checkRoutePermissions("deleteSchedule") && (
          <BsTrash
            size={18}
            style={{ cursor: "pointer" }}
            onClick={showPromiseConfirm}
          />
        )}
      </div>
    </div>
  );
};

export default ScheduleMobileScreen;
