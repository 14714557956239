


import { createSlice } from "@reduxjs/toolkit";
import { Planner, InitialPlanner } from "../../interfaces/studentPlanner/studentPlanner";

const initialState: InitialPlanner = {
  studentPlanners: []
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    setStudentPlanner(state,action){
        state.studentPlanners = action.payload;
    }
  },
});

export const {setStudentPlanner} = plannerSlice.actions;

export default plannerSlice.reducer;
