import { Button, Form, Modal, notification, Upload } from "antd";
import { FiPlus } from "react-icons/fi";
import { UploadOutlined } from "@ant-design/icons";
import { createStudentBriefCategory } from "../../../store/services/student";
import { useState } from "react";

interface CategoryModalProps {
  visible: boolean;
  onCancel: () => void;
  onCategoryAdded: () => void;
}


const CategoryModal = ({ visible, onCancel,onCategoryAdded }: CategoryModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleOk = async (values: { categoryName: string; image: any }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("categoryName", values.categoryName);
      values.image?.file && formData.append("pictures", values.image.file);
      await createStudentBriefCategory(formData);
      notification.success({ message: "Category added successfully" });
      onCategoryAdded();
      onCancel();
    } catch (error) {
      console.log(error);
    }finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="classesStdModal"
        centered
        footer={null}
        open={visible}
        onCancel={onCancel}
      >
        <FiPlus className="classStdIcon" />
        <h2>Add a Category</h2>
        <p>Add a category about student</p>
        <br />
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleOk}
        >
          <Form.Item
            name="categoryName"
            label="Category"
            className="labels"
            rules={[
              { required: true, message: "Please enter the category Name" },
            ]}
          >
            <input className="classesStdInputOne" />
          </Form.Item>
          <Form.Item name="image" label="Upload Image">
            <Upload beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <br />
          <Form.Item>
            <Button onClick={onCancel}  disabled={loading} className="classesStdCancel">
              Cancel
            </Button>
            <Button htmlType="submit" loading={loading} className="add-message">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CategoryModal;
