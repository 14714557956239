import { createSlice } from "@reduxjs/toolkit";
import { notificationCountStateType } from "../../interfaces/NotificationCount/notificationCount";

const initialState: notificationCountStateType = {
  loadingCounts: false,
  unreadCount: 0,
};

export const notificationCountSlice = createSlice({
    name:"notificationCounts",
    initialState,
   reducers:{
    setLoadingCounts:(state, action)=>{
        state.loadingCounts = action.payload
    },
    setUnreadCounts:(state, action)=>{
        state.unreadCount = action.payload
    }
   }
})

export const {setLoadingCounts, setUnreadCounts} = notificationCountSlice.actions;
export default notificationCountSlice.reducer;