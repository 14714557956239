import { notification } from "antd";
import { privateAPI, publicAPI } from "../../config/constants";

export const getAllThemes = async () => {
    try {
    //   store.dispatch(setLoadingTeachers(true));
      const res = await privateAPI.get("/theme/get-all");
      if (res) {
        // store?.dispatch(setAllTeachers(res?.data?.lists));
      }
      return res?.data; // Assuming the data is in res.data
    } catch (error: any) {
      notification?.error({
        message: error?.response?.data?.message || "Server Error",
      });
    } finally {
    //   store.dispatch(setLoadingTeachers(false));
    }
  };
export const updateTheme = async (payload:any) => {
    try {
        
    //   store.dispatch(setLoadingTeachers(true));
      const res = await privateAPI.post("/theme/update-theme",{id:payload});
 
      if (res) {
        // store?.dispatch(setAllTeachers(res?.data?.lists));
      }
      return res; // Assuming the data is in res.data
    } catch (error: any) {
      notification?.error({
        message: error?.response?.data?.message || "Server Error",
      });
    } finally {
    //   store.dispatch(setLoadingTeachers(false));
    }
  };