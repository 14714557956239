import { notification } from "antd";
import { privateAPI } from "../../config/constants";



export const CreateAssisment = async (classId: { classId: string }) => {
    try {
        if (classId) {
            const res = await privateAPI.post("/subject/get-subjects-from-class", classId);
            console.log(res);
            if (res.status === 200) {
                return res
            }
        }

    } catch (error) {
        console.log("create assigment error");
    }
}



export const getModuleBySubjects = async (data: { subjectId: number }) => {
    try {
        const res = await privateAPI.post("/module/get-module-by-subject", data);
        if (res.status === 200) {
            return res;
        }
    } catch (error) {
        console.log("error generate module", error);
    }
}


export const getModuleByIds = async (data: { moduleId: number }) => {
    try {
        const res = await privateAPI.post("/lesson/get-lessons-by-module", data);
        if (res.status === 200) {
            return res;
        }
    } catch (error) {
        console.log("error module", error);
    }
}


export const getLessonsByModuleId = async (data: { moduleId: number }) => {
    try {
        const res = await privateAPI.post("/lesson/get-lessons-by-module", data);
        if (res.status === 200) {
            return res;
        }
    } catch (error) {
        console.log("error get lessons", error);
    }
}


export const createExam = async (data: any) => {
    try {
        const res = await privateAPI.post("/exam/create", data);
        if (res.status === 200) {
            notification.success({ message: res.data.message });
            return res;
        } else {
            notification.error({ message: res?.data.message });
        }
    } catch (error) {
        console.log("teacher exam", error);
    }
}