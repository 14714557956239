import React, { useState } from "react";
import { Button, Input, Form, message } from "antd";
import { userUpdatePassword } from "../../store/services/settings";

const Password = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const updatePasswordHandler = async () => {
    try {
      const values = await form.validateFields(); 
      setLoading(true);
     const res = await userUpdatePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      if(res){
        message.success("Password updated successfully!");
        form.resetFields(); 
      }
    } catch (error) {
      console.log("Error updating password:", error);
      message.error("Failed to update password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="yourprofilemain">
      <div className="profileAndButtons">
        <div className="yourprofile">
          <h2>Password</h2>
          <p>Please enter your current password to change your password.</p>
        </div>
        <div className="buttons">
          <Button disabled={loading}>Cancel</Button>
          <Button onClick={updatePasswordHandler} loading={loading} disabled={loading}>
            Update Password
          </Button>
        </div>
      </div>
      <hr />

      <Form form={form} layout="vertical">
        <div className="Nameinputdata">
          <div>
            <h2 className="name">Current password</h2>
          </div>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
          >
            <Input.Password
              className="InputField"
              placeholder="Current password"
            />
          </Form.Item>
        </div>
        <hr />

        <div className="Nameinputdata">
          <div>
            <h2 className="email">New password</h2>
          </div>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter a new password",
              },
              {
                min: 8,
                message: "Your new password must be at least 8 characters long",
              },
            ]}
          >
            <Input.Password
              className="newpassword"
              placeholder="New Password"
            />
          </Form.Item>
          {/* <span>Your new password must be more than 8 characters.</span> */}
        </div>
        <hr />

        <div className="Nameinputdata">
          <div>
            <h2 className="email">Confirm new password</h2>
          </div>
          <Form.Item
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password
              className="InputField"
              placeholder="Confirm New Password"
            />
          </Form.Item>
        </div>
      </Form>

      <div className="buttonsmobile">
        <Button loading={loading} onClick={updatePasswordHandler} disabled={loading}>
          Update
        </Button>
        <Button>Cancel</Button>
      </div>
    </div>
  );
};

export default Password;
