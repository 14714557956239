import { createSlice } from "@reduxjs/toolkit";
import { AnnouncementType } from "../../interfaces/Annoucements/Announcement";
import { create } from "domain";



const initialState: AnnouncementType = {
    annnoucement: false,
    announceData: [],
    loading: false,
    count: 0,
    increamentCount: 0,
    userRole:[]
};


export const annoucementSlice = createSlice({
    name: "aanoucement",
    initialState,
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        setLoadingFalse(state) {
            state.loading = false
        },
        setAnnouncementData(state, action) {
            state.announceData = action.payload
        },
        setCreateAnnoucementLoading(state) {
            state.loading = true
        },
        setCreateAnnoucementFalse(state) {
            state.loading = false;
        },
        setCount(state, action) {
            state.count = action.payload;
        },
        increaseIncreamentCount(state,action) {
            alert("working");
            // if (state.increamentCount === 10) {
            //     state.increamentCount = 0;
            //     return;
            // }
            state.increamentCount = action.payload;

        }, setUserRole(state,action){
            state.userRole = action.payload
        }

    }
})

export const { setLoading, setLoadingFalse,setUserRole,setCreateAnnoucementFalse, setAnnouncementData, setCreateAnnoucementLoading, setCount, increaseIncreamentCount } = annoucementSlice.actions;

export default annoucementSlice.reducer;




