import React, { useEffect, useState } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/support.css";
import avatar from "../../assets/moulviAvatar.jpeg";
import NotAccess from "../NotAccess";
import { Input, Select } from "antd";
import { IoIosSend } from "react-icons/io";
import { FaRocketchat } from "react-icons/fa";
import TeacherSupports from "./TeacherSupports";
import { useAppSelector } from "../../store/hooks";
import { getAllTeachersList } from "../../store/services/teacher";
import axios from "axios";
import {
  createChatRoom,
  getallMessage,
  getAllRooms,
  sendMessage,
  markReadMessage,
  getUnreadMessages,
} from "../../store/services/supports";
import { setActiveUserChat } from "../../store/slices/support/suportSlice";
import TeacherSupport from "./TeacherSupports";
import AdminSupport from "./Admin";
import store from "../../store";
import {
  setMessages,
  setUnreadmesg,
} from "../../store/slices/support/suportSlice";
import { useNavigate } from "react-router-dom";

interface SelectUser {
  name: string;
  img: string;
  id: number;
  message: number;
  firstName: string;
  LastName: string;
}

const Support = () => {
  const {
    loading,
    messageloading,
    chatrooms,
    messages,
    unReadmesg,
    activeUserChat,
  } = useAppSelector((state) => state.support);
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const student: string = "student";

  useEffect(() => {
    if (!checkRoutePermissions("support")) {
      navigate("/");
    }

    return () => {
        store.dispatch(setUnreadmesg([{ unread: 0 }]));
        store.dispatch(setActiveUserChat(null));
      //   markReadMessage(activeUserChat.id)
    };
  }, []);

  if (user?.role === student) {
    return null;
  }

  return checkRoutePermissions("support") ? (
    <div className="mainDiv responsive mainDiv-support">
      <div style={{ height: "100%" }}>
        {user?.role === "admin" ? <AdminSupport /> : <TeacherSupport />}
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Support;
