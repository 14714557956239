import React, { useState } from "react";
import { Avatar, Button, Modal } from "antd";
import { IoMdHeart } from "react-icons/io";
import "../../styles/pages/linktree.css";
import masjidQaba from "../../assets/masjidQaba.jpeg";
import calender from "../../assets/calender.jpeg";
import calender2 from "../../assets/calender2.jpeg";

function LinkTree() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(""); 

  const showModal = (src:any) => {
    setImageSrc(src); 
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container_linktree">
      <div className="profile">
        <Avatar
          size={160}
          style={{ width: 250, height: 160 }}
          src={masjidQaba}
        />
      </div>
      <div className="top-heading">
        <h1 className="greeting">Masjid Quba</h1>
        <p className="intro-text">Madressa</p>
      </div>

      <div className="social_links">
        <h1>Links</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            window.open(
              "https://parents.ibeuk.org/healeyislamictrust/",
              "_blank"
            )
          }
        >
          Ibeams Portal
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            window.open("https://www.healeyislamictrust.co.uk/madrasah/", "_blank")
          }
        >
          Madressah Admissions
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            window.open("https://www.healeyislamictrust.co.uk/madrasah/", "_blank")
          }
        >
          emasjid Live
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() =>
            window.open(
              "https://www.healeyislamictrust.co.uk/wp-content/uploads/2020/02/Madrasah-Rules.pdf",
              "_blank"
            )
          }
        >
          Masdressa Rules
        </Button>
      </div>

      <div className="details_link">
        <h1>Calendars</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() => showModal(calender2)}
        >
          2024-2025
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
          onClick={() => showModal(calender)}
        >
          2025-2026
        </Button>
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <img src={imageSrc} alt="Selected Year" style={{ width: "100%" }} />
      </Modal>
      </div>
  

      {/* <div className="details_link">
        <h1>My Blog</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Hashnode
        </Button>
      </div>

      <div className="details_link">
        <h1>My Business</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Business
        </Button>
      </div> */}

      {/* <footer className="footer">
        <IoMdHeart className="heart" /> <p>2024 - Madressa</p>{" "}
        <IoMdHeart className="heart" />
      </footer> */}
    </div>
  );
}

export default LinkTree;
