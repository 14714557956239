import React from "react";
import { checkRoutePermissions } from "../../utils/permission";
import AdminDashboard from "./AdminDashboard";
import TeacherDashboard from "./TeacherDashboard";
import StudentDashboard from "./StudentDashboard";
import NotAccess from "../NotAccess";


const Dashboard = () => {
  return checkRoutePermissions("adminDashboard") ? (
    <AdminDashboard />
  ) : checkRoutePermissions("teacherDashboard") ? (
    <TeacherDashboard />
  ) : checkRoutePermissions("studentDashboard") ? (
    <StudentDashboard />
  ) : (
    <NotAccess />
  );
};

export default Dashboard;
