import { Button, Col, Form, Modal, Row, Select } from "antd";
import React, { memo, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { isMobile } from "../../../config/constants";
import SearchableDropdown from "../../SearchableDropdown";
import { getSubjectListByClassDropdown } from "../../../store/services/subject";
import { useAppSelector } from "../../../store/hooks";
import { AssignSubjectsToClass, getAllTeachersList } from "../../../store/services/teacher";

interface Props {
  teacherId?: number;
  classId?: number;
}

const AssignSubjectToTeacherModal: React.FC<Props> = ({
  teacherId,
  classId,
}) => {
  const { loadingSubjectList, subjectListByClass } = useAppSelector(
    (state) => state.subjectListByClassSlice
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [load,setLoad] = useState(false);

  const [selectedSubjectValue, setSelectedSubjectValue] = useState<
    string | string[]
  >();

  const handleShowModal = (): void => {
    setShowModal(true);
    getSubjects();
  };

  const getSubjects = async () => {
    const payload = {
      classId: classId,
    };
    await getSubjectListByClassDropdown(payload);
  };

  const handleSelectSubjectChange = (value: string | string[]) => {
    setSelectedSubjectValue(value);
  };

  const handleCancel = (): void => {
    setShowModal(false);
    handleReset();
  };

  const onFinish = async (values: any) => {
    const payload = {
      subjectIds: selectedSubjectValue,
      teacherId: teacherId,
      classId: classId,
    };
    setLoad(true)
    const res = await AssignSubjectsToClass(payload);
    setLoad(false);
    if(res) {
        handleReset();
        const payload = {
            page: 1,
            perPage: 10,
            search:""
          };
        await getAllTeachersList(payload);
        setShowModal(false);
        handleReset();
        handleCancel();
    }
    setShowModal(false);
    handleReset();
  };

  const handleReset = (): void => {
    form.resetFields();
    setSelectedSubjectValue([]);
  };

  return (
    <div>
      <p onClick={handleShowModal} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}><FaPlusCircle size={15} />{ "Add Subject"}</p>
      <Modal
        forceRender
        title={"Assign Subject to Teacher"}
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        className="create_role_modal"
        centered
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                name="subjects"
                label="Select Subject to Assign"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  value={selectedSubjectValue}
                  onChange={handleSelectSubjectChange}
                  optionFilterProp="children"
                  showSearch
                  mode={"multiple"}
                  loading={loadingSubjectList}
                >
                  {subjectListByClass.map((item: any) => (
                    <Select.Option key={item.subjectId} value={item.subjectId}>
                      {item.subjectName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="default" onClick={handleCancel} disabled={load}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button loading={load} disabled={load} className="btn_assign" htmlType="submit">
                  {"Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(AssignSubjectToTeacherModal);
