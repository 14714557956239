import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import { setStudentPlanner } from "../slices/studentPlanner/StudentPlanner";
import store from "..";



export const plannerModal = async()=>{
    try {
        const res = await privateAPI
    } catch (error) {
        console.log("error planner modal",error);
    }
}


export const getPlannerClasses =async ()=>{
    try {
        const res = await privateAPI.get("/teacher/get-teacher-classes");
        if(res.status === 200){
            return res;
        }
    } catch (error) {
        console.log("error",error);
    }
}


export const fetchStudent = async (data?:{classId:number})=>{
    try {
        const res = await privateAPI.post("/teacher/get-students-by-teacher-class",data);
        if(res.status === 200){
            return res;
        }else{
            notification.error({message:res.data.message});
        }
    } catch (error) {
        console.log("error",error)
    }
}


export const getPlanner = async (data:{studentId:number}) => {
    try {
        const res = await privateAPI.post("/student-planner/get",data);
        if (res.status === 200) {
            store.dispatch(setStudentPlanner(res.data.planner));
            return res;
        }
    } catch (error) {
        console.log("error", error);
    }
}