import React, { useState } from "react";
import { Button, notification, Select, Spin, Upload } from "antd";
import type { UploadProps } from "antd";
import "../../styles/components/logo.css";
import { LuUploadCloud } from "react-icons/lu";
import { useAppSelector } from "../../store/hooks";
import { privateAPI } from "../../config/constants";
import { getImage } from "../../store/services/settings";
import { UploadImage } from "../../store/services/settings";
import { MdOutlineDelete } from "react-icons/md";

const { Dragger } = Upload;

const Logo = () => {
  const [fileLogo, setFileLogo] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null); // Explicitly type as File or null
  const [selectedValue, setSelectedValue] = useState("");
  const { logo, favicon } = useAppSelector(state => state.settings);



  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: () => {},
    showUploadList: false,
    onChange(info) {
      if (info.file.status === 'done' || info.file.status === 'uploading') {
        setFileLogo(info.file);
        setImage(info.file.originFileObj as File); // Cast to File
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const logoUploadHandler = async () => {
  
    
    if (!selectedValue) {
      return notification.error({ message: "Image type is required" });
    }
    if (!image) {
      return notification.error({ message: "Image is required" });
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("type", selectedValue);
      formData.append("pictures", image); // Use image directly
      const res = await UploadImage(formData,selectedValue);
      if (res) {
        setLoading(false);
        getImage();
        setSelectedValue("");
        setImage(null); // Reset image to null
      }
    } catch (error: any) {
      notification?.error({
        message: error?.response?.data?.message || "Server Error",
      });
    }finally{
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setSelectedValue(""); // Reset the dropdown value
    setFileLogo(null);    // Clear the fileLogo state
    setImage(null);       // Clear the image state
  };

  return (
    <div className="yourprofilemain">
      <div className="profileAndButtons">
        <div className="yourprofile">
          <h2>Logo</h2>
          <p>Set your logo here.</p>
        </div>
        <div className="buttons">
        <Button onClick={handleCancel} disabled={loading}>Cancel</Button>
          <Button disabled={!fileLogo && !selectedValue} onClick={logoUploadHandler}>
            Update {selectedValue && selectedValue}
          </Button>
        </div>
      </div>
      <hr />

      {loading ? (
        <div
          style={{
            marginTop: "5rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div className="uploadimag">
          <div>
            <Select
              placeholder="Select Image type"
              optionFilterProp="label"
              className="select_logo"
              value={selectedValue || undefined}
              onChange={(e) => {
                setSelectedValue(e);

              }}
              options={[
                {
                  value: "favIcon",
                  label: "favIcon",
                },
                {
                  value: "logo",
                  label: "logo",
                },
              ]}
            />
          </div>
          {image ? (
            <div className="uploaded_image_container">
              <div className="image_container">
                <img src={URL.createObjectURL(image)} alt="Uploaded logo" />
              </div>

              <div className="icon_container" onClick={() => setImage(null)} >
                <MdOutlineDelete fontSize={"22px"} />
              </div>
            </div>
          ) : (
            <div className="Dragger">
              <Dragger {...props} className="custom_dragger">
                <p className="ant-upload-drag-icon">
                  <LuUploadCloud className="uploadicon" />
                </p>
                <p className="ant-upload-text">
                  <strong>Click to upload</strong> or drag and drop
                </p>
                <p className="ant-upload-hint">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </p>
              </Dragger>
            </div>
          )}
        </div>
      )}
      <div className="buttonsmobile">
          <Button disabled={!fileLogo || loading} loading={loading} onClick={logoUploadHandler}>
            Update {selectedValue && selectedValue}
          </Button>
          <Button>Cancel</Button>
        </div>
    </div>
  );
};

export default Logo;
