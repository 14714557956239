
import { createSlice } from "@reduxjs/toolkit";
import { any } from "lodash/fp";

interface Message {
    id: number; // or string, based on your use case
    sender_id: number; // or string, based on your use case
    content: string;
    file: null | string; // Adjust based on what type of file you may want to store
}
interface ReportState {
    loading: boolean,
    messageloading: boolean,
    chatrooms: any,
    messages: Message[] ,
    activeUserChat:any,
    unReadmesg:any;
    singlMessage:any
};

const initialState: ReportState = {
    loading: false,
    messageloading: false,
    chatrooms: [],
    messages: [],
    activeUserChat:null,
    unReadmesg:[],
    singlMessage:{}

};


export const support = createSlice({
    name: "support",
    initialState,
    reducers: {
        
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMessageloading(state, action) {
            state.messageloading = action.payload;
        },
        setChatrooms(state, action) {
            state.chatrooms = action?.payload;
        },
        setMessages(state, action) {
            state.messages = action?.payload;
        },
        setActiveUserChat(state, action) {
            console.log("action slice active id", action.payload)
            state.activeUserChat = action?.payload;
        },
        setUnreadmesg(state, action) {
            state.unReadmesg = action?.payload;
        },
        addMessage(state, action) {
            state.messages.push(action.payload);
        },
        setSingleMessage(state,action){
            state.singlMessage = action.payload;
        }
    },
});

export const { setLoading, setChatrooms, setMessages,setSingleMessage, setMessageloading, setActiveUserChat, setUnreadmesg,addMessage} = support.actions;

export default support.reducer;
