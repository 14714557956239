import { Select, Spin } from "antd";
import SingleStudentCard from "../../pages/classesOverview/ClassesProgress/SingleStudentCard";
import CustomTable from "../../pages/createAssesment/CustomTable";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getExamDetailsByStudent,
  getStudentExams,
} from "../../store/services/teacherExam";
import {
  setCurrentStudent,
  setExamDetail,
  setSelectExamId,
} from "../../store/slices/teacherExamSlice";
import { useEffect, useState } from "react";
import { RiExpandUpDownLine } from "react-icons/ri";
import Loading from "../Loading/Loading";

function Exam({ studentData }: any) {
  const [exam, setExam] = useState([]);
  const [examDropdownLoad, setExamDropdownLoad] = useState<boolean>(false);
  const { examDetail, isLoading } = useAppSelector(
    (state) => state.teacherExam
  );

  const dispatch = useAppDispatch();

  const _handleSelectExam = async (e: any) => {
    const data = {
      examId: e,
      studentId: studentData?.id,
    };
    await getExamDetailsByStudent(data);
    dispatch(setSelectExamId(e));
  };

  const fetchStudentExam = async () => {
    try {
      setExamDropdownLoad(true);
      const res: any = await getStudentExams({ studentId: studentData?.id });
      setExam(res?.data?.examList);
      setExamDropdownLoad(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    dispatch(setCurrentStudent(studentData));
    dispatch(setExamDetail({}));
    fetchStudentExam();
  }, [studentData]);

  return (
    <div className="_examsContainer">
      <div className="exam_parent">
        <div></div>
        <div className="_selectBoxSection">
          <Select
            loading={isLoading === "exam"}
            placeholder="Select Exam"
            optionFilterProp="children"
            onChange={_handleSelectExam}
            notFoundContent={
              examDropdownLoad ? (
                <div style={{ margin: "5px 0px" }}>
                  <Loading />
                </div>
              ) : undefined
            }
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
            options={exam?.map((v: any) => {
              return {
                value: v?.id,
                label: v?.examName,
              };
            })}
          />
        </div>
      </div>
      {isLoading === "detail" || examDropdownLoad ? (
        <div className="_emptyDetail">
          <Spin />
        </div>
      ) : examDetail ? (
        <>
          <div className="_contentSection">
            {examDetail?.subjects?.length > 0 ? (
              examDetail.subjects.map((v: any, i: number) => (
                <CustomTable key={i} customize tableData={v} />
              ))
            ) : (
              <div className="_emptyDetail">
                {exam?.length > 0 ? (
                  <p>No Exam Selected</p>
                ) : (
                  <p>No Exams Yet</p>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="_emptyDetail">
          <p>Empty Detail</p>
        </div>
      )}
    </div>
  );
}

export default Exam;
