import React, { useEffect, useRef, useState } from "react";
import "../../styles/pages/teacherDashboard/teacherDashboard.css";
import { Row, Col, Button, Spin, Tag, Table, Card, Tooltip } from "antd";
import iconOne from "../../assets/teacherDashboardIconOne.png";
import iconTwo from "../../assets/teacherDashboardIconTwo.png";
import { useAppSelector } from "../../store/hooks";
import StudentPerformanceCard from "../../components/Cards/StudentPerformanceCard";
import WeekCalendar from "../../components/WeekCalendar";
import UpcomingClassesCard from "../../components/UpcomingClassesCard/UpcomingClassesCard";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getFocusTeaher,
  getStudentPerformance,
  getTopPerformer,
} from "../../store/services/dashboard";
import { setActiveClass } from "../../store/slices/dashboardSlice/teacherDashboardSlice";
import {
  fetchStudent,
  getPlannerClasses,
} from "../../store/services/PlannerModal";
import { Link } from "react-router-dom";
import {
  getTopFerformer,
  getTopFerformerBySubject,
} from "../../store/services/state";
import { getLessonsByModuleId } from "../../store/services/createAssisment";
import { isError } from "lodash";
import { FaEye } from "react-icons/fa";
export interface data {
  students?: number;
  teachers?: {
    classId?: number;
    curriculumId?: number;
    id?: number;
    mindsetToFocusOn?: string;
    practicesTopicsToFocusOn?: string;
    primaryFocusQuaidahQuran?: string;
    primaryFocusSubject?: string;
    subjectId?: number;
    teacherId?: number;
  };
}

const TeacherDashboard = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [selectClass, setSelectClass] = useState("");
  const [classes, setClasses] = useState([]);
  const [student, setStudnet] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoadig] = useState(false);
  const [topPerformer, setTopPerformer] = useState([]);
  const [maqaasid, setMaqaasid] = useState<data | null>(null);
  const refs = {
    mindsetToFocusOn: useRef(null),
    practicesTopicsToFocusOn: useRef(null),
    primaryFocusQuaidahQuran: useRef(null),
    primaryFocusSubject: useRef(null),
  };
  const [isOverflowing, setIsOverflowing] = useState({
    mindsetToFocusOn: false,
    practicesTopicsToFocusOn: false,
    primaryFocusQuaidahQuran: false,
    primaryFocusSubject: false,
  });

  const currentYear = new Date().getFullYear();
  const {
    upcomingLoading,
    activeClass,
    upcomingClassesData,
    topPerformerData,
  } = useAppSelector((state) => state.teacherDashboard);

  const fetchStudnetData = async () => {
    try {
      const res = await getPlannerClasses();
      setClasses(res?.data?.classes);
      setSelectClass(
        res?.data?.classes.length > 0 && res?.data.classes[0].classId
      );

      // const resp = await getFocusTeaher()
    } catch (error) {
      console.log("error", error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    _getData();
    fetchStudnetData();
  }, []);

  const _getData = () => {
    getStudentPerformance();
  };

  const fetchTopPerformer = async () => {
    try {
      setLoad(true);
      const response = await getTopFerformerBySubject({
        search: "", //search
        classId: selectClass, //classid
        subjectId: "", //subjd
        year: currentYear,
        page: 1,
        perPage: 3,
      });

      if (response) {
        setTopPerformer(response.monitoringList);
        setLoad(false);
      }
      if (user?.id && selectClass) {
        setLoad(true);
        setMaqaasid(null);
        const resp = await getFocusTeaher({
          classId: Number(selectClass),
          teacherId: Number(user?.id),
          // teacherId: 43,
        });
        console.log(resp);
        if (resp) {
          setMaqaasid(resp?.data);
        }
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchTopPerformer();
  }, [selectClass]);

  useEffect(() => {
    const checkOverflow = (ref: any) =>
      ref.current && ref.current.scrollHeight > ref.current.clientHeight;

    setIsOverflowing({
      mindsetToFocusOn: checkOverflow(refs.mindsetToFocusOn),
      practicesTopicsToFocusOn: checkOverflow(refs.practicesTopicsToFocusOn),
      primaryFocusQuaidahQuran: checkOverflow(refs.primaryFocusQuaidahQuran),
      primaryFocusSubject: checkOverflow(refs.primaryFocusSubject),
    });
  }, [maqaasid]);

  console.log(upcomingClassesData)

  return (
    <div className="_teacherDashboardWrapper">
      {loading ? (
        <div className="clasDash_loader">
          <Spin />
        </div>
      ) : (
        <div className="_teacherDashboard">
          {/* ----------> Left Section <----------*/}
          <div className="_leftSection">
            <div className="_leftSectionWrapper">
              <h2 className="_mainHeading">
                Welcome back, {`${user?.firstName || ""}`}{" "}
                {`${user?.LastName || ""}`}! 👋🏼
              </h2>
              <p className="_description">Review and manage the school data.</p>
              <div className="_mySubject">
                <h2 className="_subHeading">My Subject</h2>
                <div className="_subjectSectionBox">
                  <div className="_subjectSectionBoxWrapper">
                    <h4>Maqaasid</h4>
                    {load ? (
                      <div className="class_spin">
                        <Spin />
                      </div>
                    ) : (
                      <div
                        className="maqaasid_cards_container"
                        style={{ padding: "10px", paddingLeft: "15px" }}
                      >
                        {maqaasid?.teachers &&
                        Object.keys(maqaasid?.teachers).length > 0 ? (
                          <>
                            <Card
                              title="Mindset to focus on"
                              bordered={false}
                              className="maqaasid_card"
                            >
                              <>
                                <p ref={refs.mindsetToFocusOn}>
                                  {maqaasid?.teachers?.mindsetToFocusOn}
                                </p>
                                {isOverflowing.mindsetToFocusOn && (
                                  <span className="eye_icon">
                                    <Tooltip
                                      title={
                                        maqaasid?.teachers?.mindsetToFocusOn
                                      }
                                    >
                                      <FaEye size={20} />
                                    </Tooltip>
                                  </span>
                                )}
                              </>
                            </Card>
                            <Card
                              title="Practices / Topics to focus on"
                              bordered={false}
                              className="maqaasid_card"
                            >
                              <>
                                <p ref={refs.practicesTopicsToFocusOn}>
                                  {maqaasid?.teachers?.practicesTopicsToFocusOn}
                                </p>
                                {isOverflowing.practicesTopicsToFocusOn && (
                                  <span className="eye_icon">
                                    <Tooltip
                                      title={
                                        maqaasid?.teachers
                                          ?.practicesTopicsToFocusOn
                                      }
                                    >
                                      <FaEye size={20} />
                                    </Tooltip>
                                  </span>
                                )}
                              </>
                            </Card>
                            <Card
                              title="Primary focus Qaidah / Quraan"
                              bordered={false}
                              className="maqaasid_card"
                            >
                              <>
                                <p ref={refs.primaryFocusQuaidahQuran}>
                                  {maqaasid?.teachers?.primaryFocusQuaidahQuran}
                                </p>
                                {isOverflowing.primaryFocusQuaidahQuran && (
                                  <span className="eye_icon">
                                    <Tooltip
                                      title={
                                        maqaasid?.teachers
                                          ?.primaryFocusQuaidahQuran
                                      }
                                    >
                                      <FaEye size={20} />
                                    </Tooltip>
                                  </span>
                                )}
                              </>
                            </Card>
                            <Card
                              title="Primary focus subject"
                              bordered={false}
                              className="maqaasid_card"
                            >
                              <>
                                <p ref={refs.primaryFocusSubject}>
                                  {maqaasid?.teachers?.primaryFocusSubject}
                                </p>
                                {isOverflowing.primaryFocusSubject && (
                                  <span className="eye_icon">
                                    <Tooltip
                                      title={
                                        maqaasid?.teachers?.primaryFocusSubject
                                      }
                                    >
                                      <FaEye size={20} />
                                    </Tooltip>
                                  </span>
                                )}
                              </>
                            </Card>
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            No Data
                          </div>
                        )}
                      </div>
                      // <ul style={{ padding: "10px", paddingLeft: "15px" }}>
                      //   <li>{maqaasid?.teachers?.mindsetToFocusOn}</li>
                      //   <li>{maqaasid?.teachers?.practicesTopicsToFocusOn}</li>
                      //   <li>{maqaasid?.teachers?.primaryFocusQuaidahQuran}</li>
                      //   <li>{maqaasid?.teachers?.primaryFocusSubject}</li>
                      // </ul>
                    )}
                    <div
                      className="_bottomContent"
                      style={{ marginTop: "5px" }}
                    >
                      {/* <div>
                        <img src={iconOne} alt="Icon" />
                        <p>23 lessons</p>
                      </div> */}
                      <div>
                        <img src={iconTwo} alt="Icon" />
                        <p>
                          {load ? (
                            <Spin size="small" />
                          ) : maqaasid?.students ? (
                            maqaasid?.students
                          ) : (
                            0
                          )}{" "}
                          students
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="_subHeading">Classes</h2>
                <div className="_classesSection">
                  {classes.length > 0 &&
                    classes?.map((v: any, i) => {
                      return (
                        <Button
                          key={v.classId}
                          className={
                            selectClass === v.classId ? "activeButton" : ""
                          }
                          onClick={() => setSelectClass(v.classId)}
                        >
                          {v.className}
                        </Button>
                      );
                    })}
                </div>

                <div className="_topPerformerHeader">
                  <h2 className="_subHeading" style={{ marginTop: 0 }}>
                    Top Performers
                  </h2>
                  <Link
                    to={{
                      pathname: "/dashboard/top-performers",
                    }}
                    state={{ topPerformer: student }}
                  >
                    <p style={{ cursor: "pointer" }}>View All</p>
                  </Link>
                </div>
                {load ? (
                  <div className="class_spin">
                    <Spin />
                  </div>
                ) : (
                  <div className="_topPerformerSection">
                    {topPerformer?.map((item: any) => {
                      return (
                        <>
                          <StudentPerformanceCard
                            key={item?.name}
                            data={item}
                          />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div style={{ height: "20px" }} />
          </div>

          {/* ----------> Right Section <----------*/}
          <div className="_rightSection">
            <div className="_weekCalenderSection">
              <WeekCalendar classId={selectClass} />
            </div>
            <div className="_upcomingClassesSection">
              <h2>Upcoming Classes</h2>
              <div className="_upcomingClassesBody">
                {upcomingLoading ? (
                  <div className="_noUpcomingClasses">
                    <Spin />
                  </div>
                ) : upcomingClassesData?.length > 0 ? (
                  upcomingClassesData?.map((v: any, i: number) => {
                    return <UpcomingClassesCard key={i} data={v} />;
                  })
                ) : (
                  <div className="_noUpcomingClasses">
                    <p>No upcoming classes found!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeacherDashboard;
