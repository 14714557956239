import { createSlice } from "@reduxjs/toolkit";
import { TeacherStateType } from "../../interfaces/Teacher/Teacher";

const initialState: TeacherStateType = {
  loadingTeachers: false,
  allTeachers: [],
  allAssignTeachers: [],
};
export const teachersSlice = createSlice({
    name: "teachers",
    initialState,
    reducers: {
      setLoadingTeachers(state, action) {
        state.loadingTeachers = action.payload;
      },
      setAllTeachers(state, action) {
        state.allTeachers = action.payload;
      },
      setAssignedTeacher(state,action) {
        state.allAssignTeachers = action.payload
      }

    },
});
  
  export const { setLoadingTeachers, setAllTeachers, setAssignedTeacher} = teachersSlice.actions;
  
  export default teachersSlice.reducer;
  
