import React, { useEffect, useState } from "react";
import { Button, Table, Tooltip, Tag, Spin, Flex, Popconfirm } from "antd";
import { IoMdAdd } from "react-icons/io";
//@ts-ignore
import cloud from "../../assets/cloud.png";
import CreateNotifications from "../Modals/createNotifications/CreateNotifications";
import "../../styles/pages/recentAnnouncement/index.css"; // Ensure your custom styles are imported correctly
import { getAllAnnouncements } from "../../store/services/announcement";
import { useAppSelector } from "../../store/hooks";
import CustomPagination from "../Pagination/CustomPagination";
import GHeader from "../GeneralHeader/GHeader";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";

interface User {
  id: number;
  firstName: string;
  lastName: string;
}

interface Announcement {
  id: number;
  title: string;
  notificationType: string;
  message: string;
  users?: User[];
}

const RecentAnnouncement: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { announceData, count, increamentCount, loading } = useAppSelector(
    (state) => state.announcement
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "22%",
      render: (text: any) => {
        return (
          <div className="table_data_text">
            <p>{text.charAt(0).toUpperCase() + text.slice(1)}</p>
          </div>
        );
      },
    },
    {
      title: "Notification Type",
      dataIndex: "notificationType",
      key: "notificationType",
      width: "20%",
      render: (text: any) => {
        return (
          <div className="table_data_text">
            <p>{text.charAt(0).toUpperCase() + text.slice(1)}</p>
          </div>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "20%",
      render: (text: any) => {
        return (
          <div className="table_data_text">
            <p>{text.charAt(0).toUpperCase() + text.slice(1)}</p>
          </div>
        );
      },
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      width: "22%",
      render: (users: User[]) => {
        if (!users || users.length === 0) {
          return <Tag className="table_users_tag">0</Tag>;
        }
        return (
          <Tooltip
            title={
              <div>
                {users.map((item, index) => (
                  <Tag key={index} className="table_users_tooltip_tag">
                    {item.firstName} {item.lastName}
                  </Tag>
                ))}
              </div>
            }
            overlayInnerStyle={{ backgroundColor: "#F7F7F7", border: "none" }}
          >
            <Tag className="table_users_tag">{users.length}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: " Action",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: () => {
        return (
          <div className="table_actions">
            {/* <FiEdit2 style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> */}
            {/* {'active' === "active" ? ( */}
            <Popconfirm
              // style={style}
              title="Archive Level"
              description="Are you sure add to archive ?"
              onConfirm={(e) => {
                e?.stopPropagation();
                // levelArch(level.levelId);
              }}
              //  onCancel={(e)=> e?.stopPropagation()}

              okText="Yes"
              cancelText="No"
            >
              <MdOutlineArchive
                onClick={(e) => {
                  e.stopPropagation();
                  // addArchive(record);
                }}
              />
            </Popconfirm>
            {/* ) : (
              <MdOutlineUnarchive
                onClick={(e) => {
                  e.stopPropagation();
                  // addUnlevelArch(level.levelId);
                }}
              />
            )} */}
          </div>
        );
      },
    },
  ];

  const dataSource = announceData.map((data: Announcement, index: number) => ({
    key: index + 1,
    id: data.id,
    title: data.title,
    notificationType: data.notificationType,
    message: data.message,
    users: data.users || [],
  }));

  useEffect(() => {
    getAllAnnouncements({ perPage: 8, page: currentPage });
  }, [currentPage]);

  return (
    <>
      <CreateNotifications
        isModalOpen={isModalOpen}
        currentPage={currentPage}
        setIsModalOpen={setIsModalOpen}
      />
      <div className="main_parent responsive">
        <GHeader
          heading="Announcements"
          buttonText="Create Announcement"
          onClick={showModal}
        />
        <div className="mian_tab">
          {loading ? (
            <Flex
              align="center"
              style={{ height: "50vh" }}
              justify="center"
              gap="middle"
            >
              <Spin size="large" />
            </Flex>
          ) : (
            <>
              {announceData.length > 0 ? (
                <div>
                  <div className="table_container">
                    <Table
                      className="custom_table_design"
                      dataSource={announceData}
                      columns={columns}
                      pagination={false}
                      scroll={{ x: 600 }}
                    />
                  </div>
                </div>
              ) : (
                <div className="noAnnouncmentContainer">
                  <div>
                    <div>
                      <img width={"110px"} src={cloud} alt="cloud" />
                    </div>
                    <div>
                      <h2>You have no announcement here</h2>
                      <p>Create a first announcement</p>
                    </div>
                    {announceData.length > 0 && (
                      <Button
                        className=""
                        style={{ width: "360px" }}
                        icon={<IoMdAdd className="mark-read" />}
                        onClick={showModal}
                      >
                        Create Announcement
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {announceData.length > 0 && (
          <div className="main_pagination">
            <CustomPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={count}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RecentAnnouncement;
