import { Flex, Input, Popconfirm,  Spin, Table } from "antd";
import GHeader from "../../components/GeneralHeader/GHeader";
import { CiSearch } from "react-icons/ci";
import { useEffect, useState } from "react";
import useDebounce from "../../Hook/useDebounce";
import { FiEdit2 } from "react-icons/fi";
import AddTabModal from "../../components/Curriculum/AddTabmodal";
import {
  getAllSubject,
  subArchive,
  subUnArchive,
} from "../../store/services/subject";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";

const style: React.CSSProperties = {
  width: "300vw",
  height: "300vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Subjects: React.FC = () => {
  const [subjects, setSubjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalEditMode, setIsModalEditMode] = useState<boolean>(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [totalSubject, setTotalSubjects] = useState<number>(0);
  const [filterState, setFilterState] = useState({
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  useEffect(() => {
    getData();
  }, [currentPage, filterState]);

  const getData = async (filter = currentFilter) => {
    setLoading(true);
    const _subjects = await getAllSubject(
      currentPage, //page
      10, //perpage
      filterState.search, //search
      // filter
      "active" //status
    );
    setTotalSubjects(_subjects?.count);
    if (_subjects?.subjectList?.length > 0) {
      setSubjects(_subjects?.subjectList);
      setLoading(false);
    } else {
      setSubjects(_subjects?.subjectList);
      setLoading(false);
    }
  };

  const selectValue = async (e: any) => {
    setLoading(true);
    setCurrentPage(1);
    setCurrentFilter(e);
    const _subjects = await getAllSubject(currentPage, 8, "", e);
    setTotalSubjects(_subjects?.count);
    if (_subjects?.subjectList?.length > 0) {
      setSubjects(_subjects?.subjectList);
      setLoading(false);
    } else {
      setSubjects(_subjects?.subjectList);
      setLoading(false);
    }
  };

  const col: any = [
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      key: "subjectName",
      width: "75%",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) =>
        record.status === "active" ? (
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <FiEdit2
              style={{
                width: "20px",
                height: "20px",
                margin: "10px 0px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsModalEditMode(true);
                setIsModalVisible(true);
                setSelectedItemForEdit(record);
              }}
            />
            {/* {record.status === "active" ? ( */}
            <Popconfirm
              style={style}
              title="Archive Subject"
              description="Are you sure add to archive ?"
              onConfirm={(e) => {
                //  e?.stopPropagation();
                addArchive(record);
              }}
              //  onCancel={(e)=> e?.stopPropagation()}

              okText="Yes"
              cancelText="No"
            >
              <MdOutlineArchive
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "10px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // addArchive(record);
                }}
              />
            </Popconfirm>
            {/* ) : (
            <MdOutlineUnarchive
              style={{
                width: "20px",
                height: "20px",
                margin: "10px 0px",
                cursor: "pointer",
              }}
              onClick={() => unArchive(record)}
            />
          )} */}
          </div>
        ) : null,
    },
  ];

  const addArchive = async (record: any) => {
    setIsDisable(true);
    const payload = {
      subjectId: record.subjectId,
    };
    const data = await subArchive(payload);
    getData(currentFilter);
    setIsDisable(false);
  };
  const unArchive = async (record: any) => {
    const payload = {
      subjectId: record.subjectId,
    };
    const data = await subUnArchive(payload);
    getData(currentFilter);
  };

  return (
    <>
      <div className="mainDiv responsive">
        <GHeader
          heading="Subjects"
          buttonText="Add a subject"
          numberOfPeople={`${totalSubject} Subjects`}
          onClick={() => {
            setIsModalEditMode(false);
            setIsModalVisible(true);
          }}
        />
        <div className="fliters_container">
          <Input
            placeholder="Search..."
            onChange={(e) => debouncedFunction(e.target.value, "search")}
            prefix={
              <CiSearch
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            className="filter_search"
          />
          {/* <Select
            defaultValue="All"
            style={{ width: 120, height: 45 }}
            onChange={(e: any) => selectValue(e)}
            options={[
              { value: "", label: "All Classes" },
              { value: "archive", label: "Archived" },
              { value: "active", label: "Active" },
            ]}
          /> */}
        </div>
        {loading ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: "50vh" }}
            gap="middle"
          >
            <Spin size="large" />
          </Flex>
        ) : (
          <div className="main_tab" style={{ marginTop: "10px" }}>
            <div className="table_container">
              <Table
                dataSource={subjects}
                className="custom_table_design"
                scroll={{ x: 600 }}
                columns={col}
                pagination={false}
                loading={isDisable}
              />
            </div>
          </div>
        )}
        <div className="main_pagination">
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={totalSubject}
          />
        </div>
      </div>

      <AddTabModal
        isModalVisible={isModalVisible}
        handleOk={() => setIsModalVisible(false)}
        handleCancel={() => setIsModalVisible(false)}
        getData={getData}
        editMode={isModalEditMode}
        editItem={selectedItemForEdit}
      />
    </>
  );
};

export default Subjects;
