import { useEffect, useState } from "react";
import {
  getLearningByYear,
  getLevelProgress,
  getpredictionLevel,
  getTargetsToReachNextLevel,
} from "../../store/services/learning";
import HistoricalLevel from "../HistoricalLevel/HistoricalLevel";
import SubjectLevel from "../subjectLevel/SubjectLevel";
import TargetToReach from "../targetToReach/TargetToReach";
import { getStudentSubjects } from "../../store/services/class";
import { getStudentCurrentLevel, getStudentUnArchiveTarget } from "../../store/services/report";
import CustomPagination from "../Pagination/CustomPagination";

interface SubjectStudentLevelProps {
  studentData: any;
  activeSubjectId:any
}

function SubjectStudentLevel({
  studentData,
  activeSubjectId
}: SubjectStudentLevelProps) {
  const [year, setYear] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [activeSubject, setActiveSubject] = useState<any>();
  const [currentLevelProgress, setCurrentLevelProgress] = useState<any>(null);
  const [targets, setTargets] = useState<any>(null);
  const [yearlyProgress, setYearlyProgress] = useState<any>(null);
  const [targetsLoading, setTargetLoading] = useState<boolean>(true);
  const [currentLevelLoading, setCurrentLevelLoading] = useState<boolean>(true);
  const [yearlyProgressLoading, setYearlyProgressLoading] =
    useState<boolean>(true);
    const [currentTarget, setCurrentTarget] = useState<any>([]);
    const [currentProgress, setCurrentProgress] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    console.log(studentData, "<<<>>>>" , activeSubjectId )
    console.log(activeSubject)

  useEffect(() => {
    if (studentData && studentData?.subjects) {
      const findActiveSubject = studentData?.subjects?.find((subj:any)=>subj.subjectId === activeSubjectId)
      setActiveSubject(findActiveSubject);
    }
  }, [subjects,activeSubjectId]);

  useEffect(() => {
    const getSubjects = async () => {
      const res = await getStudentSubjects(studentData?.id);
      setSubjects(res?.subjects);
      console.log(subjects)
    };

    getSubjects();
  }, [studentData]);

  useEffect(() => {
    if(activeSubject){
      const getLearning = async () => {
        setYearlyProgressLoading(true);
        const res = await getLearningByYear(
          year,
          activeSubject?.subjectId,
          studentData?.id,
        );
        if (res) {
          setYearlyProgress(res?.progress);
          setYearlyProgressLoading(false);
        }
      };
      if (activeSubject) {
        getLearning();
        getStudentLevel()
      }
    }
  }, [year, activeSubject]);

  useEffect(() => {
    const getCurrentLevelProgress = async () => {
      setCurrentLevelLoading(true);
      const res = await getpredictionLevel({
        studentId: studentData?.id,
        subjectId: activeSubject?.subjectId,
      });
      if (res) {
        console.log(res.predict);
        setCurrentLevelProgress(res?.predict);
        setCurrentLevelLoading(false);
      }
    };

    const getTargets = async () => {
      setTargetLoading(true);
      const res = await getStudentUnArchiveTarget({
        studentId: studentData?.id,
        subjectId: activeSubject?.subjectId,
      });
      if (res) {
        setCurrentTarget(res?.data.unAchivments);
        setTargetLoading(false);
      }
    };

    if (activeSubject) {
      getCurrentLevelProgress();
      getTargets();
    }
  }, [activeSubject]);

  const getStudentLevel = async () => {
    const res = await getStudentCurrentLevel({
      // studentId: reportInfo?.report?.studentId,
      // subjectId: activeItem?.subjectId,

      studentId: studentData?.id,
      subjectId: activeSubject?.subjectId,
    });

    if (res) setCurrentProgress(res?.data.result);
  };
 // Calculate pagination
 const indexOfLastItem = currentPage * itemsPerPage;
 const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 const currentItems = (currentTarget || []).slice(
   indexOfFirstItem,
   indexOfLastItem
 );
 // Handle page change
 const handlePageChange = (newPage: any) => setCurrentPage(newPage);

  return (
    <>
      <div className="learing_Child">{activeSubject?.subjectName}</div>
      <div className="leaning_card">
      <SubjectLevel
          percent={Number(
            ((Number(currentProgress?.percentage) * 100) / 100).toFixed(0)
          )}
          level="Current level"
          link="#"
          btnData="10% vs last month"
          btn={true}
          currentLevel={currentProgress?.levelName}
          loading={currentLevelLoading}
        />
         <SubjectLevel
          percent={
            Number(currentProgress?.percentage) === 0
              ? 100
              : Number(
                  ((Number(currentProgress?.percentage) * 100) / 100).toFixed(0)
                )
          }
          // currentLevel="Level 2"
          currentLevel={
            Number(currentProgress?.percentage) === 0
              ? currentLevelProgress?.currentLevel
              : currentLevelProgress?.progressLevel
          }
          level="Target Level By End Of Year"
          loading={currentLevelLoading}
        />
      </div>
      <div className="table_container learning_tab">
      <TargetToReach
          loading={targetsLoading}
          report={true}
          // nextLevel={targets?.nextLevel?.levelName}
          tableData={currentItems.map((item: any) => ({
            name: item?.levelName,
            curriculum: item?.lessonName,
          }))}
        />
        <div className="main_pagination">
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
            total={currentTarget?.length}
          />
        </div>
      </div>
      <div className="tab_learning_header">
        <HistoricalLevel
          year={year}
          setYear={setYear}
          progress={yearlyProgress}
          loading={yearlyProgressLoading}
          studentId={studentData?.id}
          activeSubject={activeSubject}
        />
      </div>
    </>
  );
}

export default SubjectStudentLevel;
