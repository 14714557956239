import React, { ReactNode } from "react";
import type { CollapseProps } from "antd";
import { Button, Col, Collapse, Row } from "antd";
import "../../styles/pages/differentVariations.css";
import { MdOutlineEdit, MdAddBox, MdOutlineMonitor } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { BsEmojiLaughing, BsGraphUpArrow } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";
import { LuBook } from "react-icons/lu";
import { SlCalender } from "react-icons/sl";
import { BsCollectionPlay } from "react-icons/bs";
// import { BsGraphUpArrow } from "react-icons/bs";
import { RiLinkM } from "react-icons/ri";
import ProjectButton from "../../components/button/ProjectButton"
import { LuClock8 } from "react-icons/lu";
import Layout from "../../components/layout/index";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const collapseDate = "11 September, 2024";
const contentOne =
  "Rachel is introverted and bookish, preferring solitude and quiet activities like reading and writing.";

const collapseDateTwo = "12 September, 2024";
const contentTwo =
  "She is driven by a desire to succeed academically and to prove herself to her parents, who have high expectations for her.";

const collapseDateThree = "21 September, 2024";
const contentThree =
  "She is driven by a desire to succeed academically and to prove herself to her parents, who have high expectations for her.";

const items: CollapseProps["items"] = [
  // objective
  {
    key: "1",
    label: (
      <span className="hide-bottom-border">
        <MdOutlineMonitor className="outlineMonitor " />
        Objective
      </span>
    ),

    children: (
      <div>
        {/* 1st content */}
        <Row justify="space-between">
          <Col lg={22}>
            <div>
              <h3 className="head-one">1. Introdction(10 mins):</h3>
              <p className="para-one">Display examples of stacked letters.</p>
            </div>
          </Col>
        </Row>
        {/* 2nd content */}
        <Row justify="space-between" align="middle" className="second-row">
          <Col lg={22}>
            <div>
              <h3 className="head-two">2. Explanation(15 mins):</h3>
              <p className="para-two">
                Define letter stacking and discuss its importance, explain key
                terms like kerning, leading, and tracking.
              </p>
            </div>
          </Col>
        </Row>
        {/* 3rd content */}
        <Row justify="space-between" align="middle" className="third-row">
          <Col lg={22}>
            <div>
              <h3 className="head-three">3. Activity(30 mins):</h3>
              <p className="para-three">
                Divide students into pairs/groups, analyze examples.
              </p>
            </div>
          </Col>
        </Row>
        {/* 4th content */}
        <Row justify="space-between" align="middle" className="fourth-row">
          <Col lg={22}>
            <div>
              <h3 className="head-four">4. Conculusion(5 mins):</h3>
              <p className="para-four">
                Summarize key points, encourage further exploration.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    ),
  },
  // material needed
  {
    key: "2",
    label: (
      <span>
        <LuBook className="luBook" />
        Material Needed
      </span>
    ),
    children: (
      <div>
        {/* 1st content */}
        <Row justify="space-between">
          <Col lg={22}>
            <div>
              <h3 className="head-one">1. Introdction(10 mins):</h3>
              <p className="para-one">Display examples of stacked letters.</p>
            </div>
          </Col>
        </Row>
        {/* 2nd content */}
        <Row justify="space-between" align="middle" className="second-row">
          <Col lg={22}>
            <div>
              <h3 className="head-two">2. Explanation(15 mins):</h3>
              <p className="para-two">
                Define letter stacking and discuss its importance, explain key
                terms like kerning, leading, and tracking.
              </p>
            </div>
          </Col>
        </Row>
        {/* 3rd content */}
        <Row justify="space-between" align="middle" className="third-row">
          <Col lg={22}>
            <div>
              <h3 className="head-three">3. Activity(30 mins):</h3>
              <p className="para-three">
                Divide students into pairs/groups, analyze examples.
              </p>
            </div>
          </Col>
        </Row>
        {/* 4th content */}
        <Row justify="space-between" align="middle" className="fourth-row">
          <Col lg={22}>
            <div>
              <h3 className="head-four">4. Conclusion(5 mins):</h3>
              <p className="para-four">
                Summarize key points, encourage further exploration.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    ),
  },
  // plan
  {
    key: "3",
    label: (
      <span>
        <SlCalender className="slCalender" />
        Plan
      </span>
    ),
    children: (
      <div>
        {/* 1st content */}
        <Row justify="space-between">
          <Col lg={22}>
            <div>
              <h3 className="head-one">1. Introdction(10 mins):</h3>
              <p className="para-one">Display examples of stacked letters.</p>
            </div>
          </Col>
        </Row>
        {/* 2nd content */}
        <Row justify="space-between" align="middle" className="second-row">
          <Col lg={22}>
            <div>
              <h3 className="head-two">2. Explanation(15 mins):</h3>
              <p className="para-two">
                Define letter stacking and discuss its importance, explain key
                terms like kerning, leading, and tracking.
              </p>
            </div>
          </Col>
        </Row>
        {/* 3rd content */}
        <Row justify="space-between" align="middle" className="third-row">
          <Col lg={22}>
            <div>
              <h3 className="head-three">3. Activity(30 mins):</h3>
              <p className="para-three">
                Divide students into pairs/groups, analyze examples.
              </p>
            </div>
          </Col>
        </Row>
        {/* 4th content */}
        <Row justify="space-between" align="middle" className="fourth-row">
          <Col lg={22}>
            <div>
              <h3 className="head-four">4. Conclusion(5 mins):</h3>
              <p className="para-four">
                Summarize key points, encourage further exploration.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    ),
  },
  // resources
  {
    key: "4",
    label: (
      <span>
        <BsCollectionPlay className="bsCollectionPlay" />
        Resources
      </span>
    ),
    children: (
      <div>
        {/* 1st content */}
        <Row justify="space-between">
          <Col lg={22}>
            <div>
              <h3 className="head-one">1. Introdction(10 mins):</h3>
              <p className="para-one">Display examples of stacked letters.</p>
            </div>
          </Col>
        </Row>
        {/* 2nd content */}
        <Row justify="space-between" align="middle" className="second-row">
          <Col lg={22}>
            <div>
              <h3 className="head-two">2. Explanation(15 mins):</h3>
              <p className="para-two">
                Define letter stacking and discuss its importance, explain key
                terms like kerning, leading, and tracking.
              </p>
            </div>
          </Col>
        </Row>
        {/* 3rd content */}
        <Row justify="space-between" align="middle" className="third-row">
          <Col lg={22}>
            <div>
              <h3 className="head-three">3. Activity(30 mins):</h3>
              <p className="para-three">
                Divide students into pairs/groups, analyze examples.
              </p>
            </div>
          </Col>
        </Row>
        {/* 4th content */}
        <Row justify="space-between" align="middle" className="fourth-row">
          <Col lg={22}>
            <div>
              <h3 className="head-four">4. Conclusion(5 mins):</h3>
              <p className="para-four">
                Summarize key points, encourage further exploration.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    ),
  },
  // links
  {
    key: "5",
    label: (
      <span>
        <RiLinkM className="riLinkM" />
        Link
      </span>
    ),
    children: (
      <>
        <div>
          {/* 1st content */}
          <Row justify="space-between">
            <Col lg={22}>
              <div>
                <ol>
                  <li>
                    Canva Design School - Typography Basics:
                    <a
                      className="anchor-tag"
                      href="https://www.canva.com/learn/typography-basics/"
                    >
                      https://www.canva.com/learn/typography-basics/
                    </a>
                  </li>
                  <li>
                    Adobe Creative Cloud - Typography Tutorials:
                    <a
                      className="anchor-tag"
                      href="https://www.canva.com/learn/typography-basics/"
                    >
                      https://www.canva.com/learn/typography-basics/
                    </a>
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
        </div>
      </>
    ),
  },
];

const DifferentVariations: React.FC = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <Layout>
        <main className="main-container">
          <h1 className="all-letters">All letters in different variations</h1>
          <p className="manage-target">Review and manage the targets.</p>
          <h3 className="about-the-target">About the target</h3>
          <Row gutter={23}>
            <Col lg={5}>
              <Row
                className="clock-and-text"
                justify="space-evenly"
                align="top"
              >
                <Col lg={2}>{<LuClock8 className="lu-clock" />}</Col>
                <Col lg={22}>Target Duration: 2 lessons</Col>
              </Row>
            </Col>
            <Col lg={7}>
              <Row
                className="clock-and-text"
                justify="space-evenly"
                align="top"
              >
                <Col lg={2}>{<LuClock8 className="lu-clock" />}</Col>
                <Col lg={22}>Lesson Duration: 46 - 60 minutes</Col>
              </Row>
            </Col>
          </Row>
          <Collapse
            className="project-collapse"
            defaultActiveKey={["1"]}
            onChange={onChange}
            items={items}
          />
          <Row gutter={10} className="edit-back-parent">
            <Col lg={12}>
              <button className="back-btn">Back</button>
            </Col>
            <Col lg={12}>
              <button className="edit-btn">Edit</button>
            </Col>
          </Row>
        </main>
      </Layout>
    </>
  );
};

export default DifferentVariations;
