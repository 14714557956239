import { Button, Form, Input, Modal, notification } from "antd";
import { FiPlus } from "react-icons/fi";
import {
  createStudentBriefCategory,
  createStudentNote,
  getAllStudentNote,
  UpdateStudentNote,
} from "../../../store/services/student";
import { useEffect, useState } from "react";

interface NoteModalProps {
  visible: boolean;
  onCancel: () => void;
  data: any;
  editMode?: boolean;
}

const NoteModal = ({ visible, onCancel, data, editMode }: NoteModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOk = async (values: { message: string }) => {
    try {
      setLoading(true);
      if (!editMode) {
        await createStudentNote({
          studentId: data.studentId,
          categoryId: data.category.categoryId,
          note: values.message,
        });
        notification.success({ message: "Note added successfully" });
      } else {
        await UpdateStudentNote({
          noteId: data?.note?.noteId,
          note: values?.message,
        });
        notification.success({ message: "Note updated successfully" });
      }
      form.resetFields();
      setLoading(false);
      onCancel();
      await getAllStudentNote(data.studentId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && data?.note && data?.note?.note) {
      form.setFieldsValue({ message: data.note.note });
    }
  }, [editMode, data]);

  return (
    <>
      <Modal
        className="classesStdModal"
        open={visible}
        onCancel={onCancel}
        centered
        footer={null}
      >
        <FiPlus className="classStdIcon" />
        <h2>{editMode ? "Edit a Note" : "Add a Note"}</h2>
        <p>
          {editMode ? "Edit a note about student" : "Add a note about student"}
        </p>
        <br />
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleOk}
        >
          <Form.Item
            name="message"
            label="Message"
            className="labels"
            rules={[{ required: true, message: "Write your message" }]}
          >
            <Input.TextArea className="classesStdInputOne" />
          </Form.Item>
          <Form.Item>
            <Button onClick={onCancel} disabled={loading} className="classesStdCancel">
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="submit"
              className="add-message"
            >
              {editMode ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NoteModal;
