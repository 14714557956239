import store from "..";
import { privateAPI } from "../../config/constants";
import { notification } from "antd";
import { setSchedules, setLoading } from "../slices/scheduleSlice";

export const createSchedule = async (payload: any) => {
  try {
    const response = await privateAPI.post("/schedule/create", payload);
    if (response) {
      getAdminSchedule();
    }
    return response;
  } catch (error: any) {
    console.log("CREATE SCHEDULE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};

export const getAdminSchedule = async (classId?: number) => {
  try {
    store.dispatch(setLoading(true));
    let query = "";
    if (typeof classId === "number") {
      query = `?classId=${classId}`;
    }
    const response = await privateAPI.get(`/schedule/get-all${query}`);
    if (response) {
      store.dispatch(setSchedules(response?.data?.scheduleList));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET SCHEDULE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getTeacherSchedule = async (payload: { teacherId: number }) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.post(
      "/schedule/get-teacher-wise",
      payload
    );
    if (response) {
      store.dispatch(setSchedules(response?.data?.scheduleList));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET SCHEDULE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateSchedule = async (payload: any) => {
  try {
    const response = await privateAPI.post("/schedule/update", payload);
    if (response) {
      getAdminSchedule();
    }
    return response;
  } catch (error: any) {
    console.log("UPDATE SCHEDULE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};

export const deleteSchedule = async (payload: { scheduleId: number }) => {
  try {
    const response = await privateAPI.post("/schedule/delete", payload);
    await getAdminSchedule();
    return response;
  } catch (error: any) {
    console.log("DELETE SCHEDULE ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};
