import React, { useEffect } from "react";
import { notification } from "antd";
import store from "..";
import { privateAPI } from "../../config/constants";
import {
  setActiveUserChat,
  setChatrooms,
  setLoading,
  setMessageloading,
  setMessages,
  setUnreadmesg,
} from "../slices/support/suportSlice";

// import {setLoading, setChatrooms, setMessages}

export const createChatRoom = async (payload: any) => {
  try {
    const response = await privateAPI.post(`/chatroom/create/`, payload);
    if (response) {
      return response?.data;
    }
    return response;
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
  }
};
export const getAllRooms = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get(`/chatroom/getall-chatrooms`);

    if (response) {
      store.dispatch(setLoading(false));
      store.dispatch(setChatrooms(response?.data?.data));
      // store.dispatch(setActiveUserChat(response?.data?.data[0]))
      console.log("all rooms here", response?.data);
      return response?.data;
    }
    return response;
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
    store.dispatch(setLoading(false));
  }
};

export const CreateRoomByTeacher = async () => {
  try {
    const res = await privateAPI.post("/chatroom/createForTeacher", {});
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getallMessage = async (roomId: any, page?: number) => {
  try {
    store.dispatch(setMessageloading(true));
    const response = await privateAPI.get(
      `/chatroom/getall-message/${roomId}?page=${page}`
    );
    console.log("response12345", response);
    if (response) {
      store.dispatch(setMessageloading(false));
      const totalMessagesCount = response?.data?.count;
      console.log("GET TOTAL COUNT >>" ,totalMessagesCount );
      
      const currentMessages = store.getState().support.messages;
      if (response?.data?.data.length > 0) {
        const newMessages = response?.data?.data.filter(
          (msg: any) =>
            !currentMessages.some((existingMsg) => existingMsg.id === msg.id)
        );
        store.dispatch(setMessages([...newMessages, ...currentMessages]));
      } else {
        store.dispatch(setMessages(currentMessages));
      }
      // store.dispatch(setMessages([...response?.data?.data]));
      return response?.data;
    }
    return response;
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
    store.dispatch(setMessageloading(false));
  }
};

export const getAllChatRooms = async () => {
  try {
    const res = await privateAPI.get("/chatroom/getall-chatrooms");
    console.log("chatRooms4321", res);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const sendMessage = async (roomId: any, payload: any) => {
  try {
    // store.dispatch(setLoading(true))
    const response = await privateAPI.post(
      `/chatroom/send-message/${roomId}`,
      payload
    );
    if (response) {
      // console.log("message send ", response?.data)
      // store.dispatch(setLoading(false))
      return response?.data;
    }
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
    // store.dispatch(setLoading(false))
  }
};
export const getUnreadMessages = async () => {
  try {
    const response = await privateAPI.get(`/chatroom/unread-message`);

    if (response) {
      store.dispatch(setUnreadmesg(response?.data?.data));

      return response?.data;
    }
    return response;
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
  }
};
export const markReadMessage = async (roodId: string) => {
  try {
    const response = await privateAPI.get(
      `/chatroom/markRead-message/${roodId}`
    );
    if (response) {
      // console.log("markREadMessage >>", response.data);

      // store.dispatch(setUnreadmesg(response?.data?.data))

      return response?.data;
    }
    return response;
  } catch (error) {
    notification.error({ message: "server error" });
    console.log(error);
  }
};
