import React, { ReactNode } from "react";

import VerticalLayout from "./VerticalLayout";
import MobileLayout from "./MobileLayout"
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <MobileLayout  />
      <VerticalLayout children={children} />
    </div>
  );
};

export default Layout;
