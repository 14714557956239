import { configureStore } from "@reduxjs/toolkit";

import themeReducer from "./slices/themeSlice";
import authReducer from "./slices/authSlice";
import settingSlice from "./slices/settingSlice";
import classSlice from "./slices/Classes/classSlice";
import teacherSlice from "./slices/Teachers/teacherSlice";
import subjectSlice from "./slices/Subject/subjectSlice";
import curriculumSlice from "./slices/Curriculum/curriculumSlice";
import scheduleSlice from "./slices/scheduleSlice";
import studentSlice from "./slices/students/studentSlice";
import notificationsSlice from "./slices/Notifications/notifications";
import notificationCountSlice from "./slices/notificationCount/NotificationsCount";
import AnnouncementsSlice from "./slices/Annoucements/Announcements.slice";
import subjectListByClassSlice from "./slices/Subject/subjectListByClassSlice";
import teacherDashboardSlice from "./slices/dashboardSlice/teacherDashboardSlice";
import assesment from "./slices/assesment/assesment";
import teacherExamSlice from "./slices/teacherExamSlice";
import plannerSlice from "./slices/studentPlanner/StudentPlanner";
import planDetial from "./slices/studentPlannerDetails/studentPlannerDetails";
import reportSlice from "./slices/reportSlice"
import statesSlice from "./slices/statesSlice"
import supportslice from "./slices/support/suportSlice"
import activeSlice from "./slices/Classes/activeSlice"

const store = configureStore({
  reducer: {
    support:supportslice,
    theme: themeReducer,
    auth: authReducer,
    settings: settingSlice,
    reports: reportSlice,
    states:statesSlice,
    class: classSlice,
    teacher: teacherSlice,
    subject: subjectSlice,
    subjectListByClassSlice: subjectListByClassSlice,
    curriculum: curriculumSlice,
    schedule: scheduleSlice,
    student: studentSlice,
    notification: notificationsSlice,
    notificationCount: notificationCountSlice,
    teacherDashboard: teacherDashboardSlice,
    announcement: AnnouncementsSlice,
    exam: assesment,
    teacherExam: teacherExamSlice,
    planner: plannerSlice,
    plan: planDetial,
    active: activeSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
