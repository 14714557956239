import React, { useState } from "react";
import { Button, Modal, notification, Select, Spin, UploadProps } from "antd";
import { MdOutlineDelete } from "react-icons/md";
import Dragger from "antd/es/upload/Dragger";
import { LuUploadCloud } from "react-icons/lu";
import { useAppSelector } from "../../../store/hooks";
import { getImage, UploadImage } from "../../../store/services/settings";
import { IoIosSend } from "react-icons/io";
import { sendMessage, getallMessage } from "../../../store/services/supports";
import store from "../../../store";
import { setMessages } from "../../../store/slices/support/suportSlice";
import { base_url } from "../../../config/constants";

const FileUploadModal = ({
  isModalOpen,
  setIsModalOpen,
  load,
  setLoad,
  Page,
}: any) => {
  const [document, setdocument] = useState<any>(null);
  // const [loading, setLoading] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [file, setFile] = useState<File | null>(null);
  // const [selectedValue, setSelectedValue] = useState("");
  const {
    loading,
    messageloading,
    chatrooms,
    messages,
    unReadmesg,
    activeUserChat,
  } = useAppSelector((state) => state.support);

  const { user } = useAppSelector((state) => state.auth);

  // console.log("active modal chat ", activeUserChat)
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: () => {},
    showUploadList: false,
    onChange(info) {
      if (info.file.status === "done" || info.file.status === "uploading") {
        setdocument(info.file);
        setFile(info.file.originFileObj as File);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  console.log("file", file);

  const handleSendMessage = async (roomId: any) => {
    setLoad(true);
    try {
      if (!file) {
        setLoad(false);
        return notification.error({ message: "Image is required" });
      }
      const formData = new FormData();
      formData.append("pictures", file);
      formData.append("sender", user?.id || "");
      formData.append("content", messageContent);
      const res = await sendMessage(roomId, formData);
      // console.log("Message sent:", res);
      // console.log("Message sent formData:", formData);
      // console.log("Message sent roomId:", roomId);

      //     const chatMessage = {
      //         sender_id: user?.id,
      //         content: messageContent || 'Sent an image',
      //         id: Math.random().toString(36).substr(2, 9) + Date.now(),
      //         file: file ? base_url+file.name : null,
      //     };
      //     console.log("chatMessage",chatMessage);
      //     const allMessages = [...messages, chatMessage];
      // store.dispatch(setMessages(allMessages));

      if (res) {
        setMessageContent("");
        getallMessage(activeUserChat?.id, Page);
        setIsModalOpen(false);
        setLoad(false);
        setFile(null);
      } else {
        notification.error({ message: "Failed to send message" });
        setLoad(false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setLoad(false);
      notification.error({ message: "Error sending message" });
    }
  };

  return (
    <>
      <Modal
        title="Upload file"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="yoursupport-modal">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div className="uploadimag-support">
              <div className="Dragger-support">
                {file ? (
                  <>
                    <div className="uploaded_image_container-support">
                      <div className="image_container">
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Uploaded file"
                        />
                      </div>

                      <div
                        className="icon_container"
                        onClick={() => setFile(null)}
                      >
                        <MdOutlineDelete fontSize={"22px"} />
                      </div>
                    </div>
                    <div className="send-message-modal">
                      <input
                        className="message_input"
                        placeholder="Write your message"
                        type="text"
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <Dragger {...props} className="custom_dragger-support">
                    <p className="ant-upload-drag-icon">
                      <LuUploadCloud className="uploadicon" />
                    </p>
                    <p className="ant-upload-text">
                      <strong>Click to upload</strong> or drag and drop
                    </p>
                    <p className="ant-upload-hint">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </Dragger>
                )}
              </div>
            </div>
          )}
          <div className="sendButtons-support">
            <div className="buttons">
              <Button onClick={handleCancel}>Cancel</Button>
              {load ? (
                <Spin />
              ) : (
                <Button
                  // disabled={!document} onClick={logoUploadHandler}
                  onClick={() => handleSendMessage(activeUserChat?.id)}
                >
                  Send
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default FileUploadModal;
