import { useState } from "react";

import { Layout } from "antd";
import SiderMenu from "./SiderMenu";

const {
  Sider,
  Content,
} = Layout;

const VerticalLayout = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ height: "100%", }} className="lob hideOnMobileScreen">
      <Sider
        className={collapsed ? "collapsed_sider" : "unCollapsed_sider"}
        collapsed={collapsed}
        style={{ backgroundColor: "black", width: "500px", minWidth: "200px", paddingTop: "10px" }}
      >
        <SiderMenu
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </Sider>
      <Layout>
        <Content className="custom_main">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default VerticalLayout;
