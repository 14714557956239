import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import SetNewPassword from "../pages/SetNewPassword";
import SignUpTeacher from "../pages/SignupTeacher";
import SignUpStudent from "../pages/SignupStudent";
import Notification from "../pages/notifications/Notification";
import Dashboard from "../pages/dashboard/index";
import Teachers from "../pages/Teachers/TeacherNew";
import Setting from "../pages/settings/Setting";
import Reporting from "../pages/Reporting";
import Students from "../pages/Student";
import Announcment from "../pages/Announcment";
import CreateAnnouncment from "../pages/CreateAnnouncment";
import LogOut from "../pages/LogOut";
import LoginTeacher from "../pages/LoginTeacher";
import LoginStudent from "../pages/LoginStudent";
import Monitoring from "../pages/Monitoring";
import Curriculum from "../pages/Curriculum";
import Signuplinks from "../pages/SendSignup";
import PrivateWrapper from "./PrivateRoutes";
import AddTraget from "../components/Curriculum/AddTarget";
import DifferentVariations from "../pages/differentVariations/DifferentVariations";
import CurriculumDetails from "../components/Curriculum/CurriculumDetails";
import SendSignup from "../pages/SendSignup";
import GenerateReport from "../pages/generateReport/GenerateReport";
import Success from "../pages/Succes";
import ClassesOverview from "../pages/classesOverview/ClassesOverview";
import CreateAssesment from "../pages/createAssesment/CreateAssesment";
import Assessment from "../pages/assessment/Assessment";
import ExamDetails from "../pages/examDetails/examDetails";
import Schedule from "../pages/Schedule";
import ClassesStudent from "../pages/classesStudent/ClassesStudent";
import ClassesProgress from "../pages/classesOverview/ClassesProgress";
import LessonDetails from "../components/Curriculum/LessonDetails";
import Reports from "../pages/Reports";
import StudentPlannerDetials from "../pages/studentPlannerDetials/studentPlannerDetials";
import Learning from "../pages/Learning";
import Exams from "../pages/Exams";
import StudentPlanner from "../pages/StudentPlanner";
import StudentReport from "../pages/studentReport/StudentReport";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import Support from "../pages/Support/Support";
import AuthWrapper from "./AuthWrapper";
import DashboardTopPerformers from "../pages/dashboard/DashboardTopPerformers";
import TeacherReview from "../pages/Teachers/TeacherReview";
import Subjects from "../pages/subjects/Subjects";
import LinkTree from "../pages/linktree/LinkTree";
import Targets from "../pages/targets/Targets";

const Routers = () => {

  const Loading = ({ children }: any) => {
    const [loading, setLoading] = useState<boolean>(true);

    setTimeout(() => {
      setLoading(false);
    }, 4000);
    return <>{loading ? <LoadingComponent /> : children}</>;
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Uncommit next line to apply token security */}
        {/* @ts-ignore */}
        {/* <Route path='/layout' element={<Layout/>}/> */}
        <Route element={<AuthWrapper />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signupteacher" element={<SignUpTeacher />} />
          <Route path="/loginteacher" element={<LoginTeacher />} />
          <Route path="/signupstudent" element={<SignUpStudent />} />
          <Route path="/loginstudent" element={<LoginStudent />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
        </Route>

        <Route path="/success" element={<Success />} />
        <Route path="/linktree" element={<LinkTree />} />
                      
        {/* Protected Routes */}
        <Route element={<PrivateWrapper />}>
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/dashboard/top-performers"
            element={<DashboardTopPerformers />}
          />
          <Route path="/dashboard/teachersreview" element={<TeacherReview />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/students" element={<Students />} />
          <Route path="/subjects" element={<Subjects />} />
          <Route path="/student/planner" element={<StudentPlanner />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/student/report" element={<StudentReport />} />
          <Route path="/report/generate" element={<GenerateReport />} />
          <Route path="/curriculum/:classId" element={<Curriculum />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/support" element={<Support />} />
          <Route path="/announcement" element={<Announcment />} />
          <Route path="/createannouncement" element={<CreateAnnouncment />} />
          <Route path="/monitoring" element={<Monitoring />} />
          <Route path="/notifications" element={<Notification />} />
          <Route
            path="/planner/details/student-details"
            element={<StudentPlannerDetials />}
          />
          <Route path="/signuplink" element={<Signuplinks />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/addTarget" element={<AddTraget />} />
          <Route path="/sendsignup" element={<SendSignup />} />
          <Route path="/setnewpassword" element={<SetNewPassword />} />
          <Route
            path="/different-variations"
            element={<DifferentVariations />}
          />
          {/* <Route path="/classes" element={<Classes/>}/> */}
          <Route path="/classes" element={<ClassesOverview />} />
          <Route
            path="/classes-progress/:classId"
            element={
              <ClassesProgress />
            }
          />
          <Route path="/student-report" element={<ClassesStudent />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/curriculum" element={<Curriculum />} />
          {/* <Route
            path="/curriculumdetails/:curriculumId"
            element={<CurriculumDetails />}
          /> */}
          <Route path="/lessondetails/:id" element={<LessonDetails />} />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/create-assesment" element={<CreateAssesment />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/exams" element={<Exams />} />
          <Route path="/examDetails/:id" element={<ExamDetails />} />
          <Route path="/student-planner" element={<StudentPlanner />} />
          <Route path="/targets" element={<Targets />} />
          {/* <Route path="/targets/:curriculumId" element={<CurriculumDetails />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
