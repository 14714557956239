import React from "react";
import { Modal } from "antd";

import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser } from "../../store/slices/authSlice";
const LogoutModal: React.FC = ({ openModal, setOpenModal }: any) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const OperatOk = () => {
    setOpenModal(false);
    localStorage.clear();
    dispatch(clearUser());
    Navigate("/login");
  };
  return (
    <>
      <Modal
        className="logout_modal"
        centered
        open={openModal}
        onOk={OperatOk}
        onCancel={() => setOpenModal(false)}
        okText={"Log out"}
      >
        <div className="logout_icons">
          <TbLogout />
        </div>

        <h2 className="logoutheading">LogOut</h2>
        <p>Are you sure you want to log out? </p>
      </Modal>
    </>
  );
};

export default LogoutModal;
