import { notification } from "antd";
import store from "..";
import { privateAPI } from "../../config/constants";
// import { setLoading } from "../slices/settingSlice";
import {
  setLoading,
  setAboveTarget,
  setBelowTarget,
  setLeastForformer,
  setTopForformer,
} from "../slices/statesSlice";

export const getAboveTarget = async (
  search: any,
  classId: any,
  subjectid: any
) => {
  console.log("all value of classes", classId);
  try {
    console.log("hit the getabove target api");
    store.dispatch(setLoading(true));
    let query = "";
    const condition = [];

    if (search) {
      condition.push(`search=${search}`);
    }
    if (classId) {
      condition.push(`classId=${classId}`);
    }
    if (subjectid) {
      condition.push(`subjectId=${subjectid}`);
    }
    if (condition?.length > 0) {
      query = `?${condition?.join("&")}`;
    }

    console.log("query", query);

    const response = await privateAPI.get(
      `/stats/students-above-target${query}`
    );
    if (response) {
      console.log(
        "response of above the target student 2",
        response?.data?.studentAboveTarget
      );
      store.dispatch(setAboveTarget(response?.data?.studentAboveTarget));
      store.dispatch(setLoading(false));
      return response?.data;
    }
    return response;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};
// export const getBelowTarget = async (search: any, classId: any, subjectid: any) => {
export const getBelowTarget = async (
  search: any,
  classId: any,
  subjectId: any
) => {
  console.log("all value of classes", classId);
  try {
    console.log("hit the getabove target api");
    store.dispatch(setLoading(true));

    let query = ``;
    let condition = [];

    if (search) {
      condition.push(`search=${search}`);
    }
    if (classId) {
      condition.push(`classId= ${classId}`);
    }
    if (subjectId) {
      condition.push(`subjectId=${subjectId}`);
    }
    if (condition?.length > 0) {
      query = `?${condition?.join("&")}`;
    }
    const response = await privateAPI.get(
      `/stats/students-below-target${query}`
    );
    if (response) {
      console.log(
        "response of above the target student",
        response?.data?.studentAboveTarget
      );
      store.dispatch(setBelowTarget(response?.data?.studentAboveTarget));
      store.dispatch(setLoading(false));
      return response?.data;
    }
    return response;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};

export const getTopFerformer = async (
  search?: string,
  classId?: string,
  subjectId?: string,
  levelId?: string,
  year?: any,
  page?: any,
  perPage?: any
) => {
  try {
    store.dispatch(setLoading(true));

    // let query = ``;
    // let condition = [];

    // if (search) {
    //   condition.push(`search=${search}`);
    // }
    // if (classId) {
    //   condition.push(`classId= ${classId}`);
    // }
    // if (subjectId) {
    //   condition.push(`subjectId=${subjectId}`);
    // }
    // if (condition?.length > 0) {
    //   query = `?${condition?.join("&")}`;
    // }
    // ("/student/signup", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });

    const response = await privateAPI.post("/stats/top-performer-students", {
      search: search,
      classId: classId,
      subjectId: subjectId,
      levelId: levelId,
      year: year,
      page: page,
      perPage: perPage,
    });
    if (response && response.data) {
      console.log("response of above the topforfromer  students 2", response);
      store.dispatch(setTopForformer(response.data?.result));
      store.dispatch(setLoading(false));
      // return response;
    }
    return response.data;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};
export const getTopFerformerBySubject = async (
   data:{ search?: string,
    classId?: string,
    subjectId?: string,
    year?:any,
    page?: any,
    perPage?:any}
) => {
  try {
    store.dispatch(setLoading(true));

    const response = await privateAPI.post("/stats/top-performer-students-by-subject", data);
    if (response && response.data) {
      console.log("response of above the topforfromer  students 2", response);
      store.dispatch(setTopForformer(response.data?.
        monitoringList));
      store.dispatch(setLoading(false));
    }
    return response.data;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};

export const getLeastFerformer = async (
  search?: string,
  classId?: string,
  subjectId?: string,
  levelId?: string,
  year?: any,
  page?: any,
  perPage?: any
) => {
  console.log("all value of classes", classId);
  try {
    console.log("hit the getabove target api");
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("stats/least-performer-students", {
      search: search,
      classId: classId,
      subjectId: subjectId,
      levelId: levelId,
      year: year,
      page: page,
      perPage: perPage,
    });
    if (response) {
      console.log(
        "response of above the least student",
        response?.data?.result
      );
      store.dispatch(setLeastForformer(response?.data?.result));
      store.dispatch(setLoading(false));
      return response?.data;
    }
    return response;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};

// export const topPerformer
