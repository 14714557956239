import { FaUser } from "react-icons/fa6";
import { IoNotifications } from "react-icons/io5";
import { LuMessageSquare } from "react-icons/lu";

export const NotificationStatusIcon : any = {
  onboarding: (
    <FaUser
      size={40}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        // background: "black",
        background: "var(--secondary-color)",
        color: "white",
        borderRadius: "20px",
      }}
    />
  ),
  message: (
    <LuMessageSquare
      size={40}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        // background: "black",
        background: "var(--secondary-color)",
        color: "white",
        borderRadius: "20px",
      }}
    />
  ),
  general: (
    <IoNotifications
      size={40}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        // background: "black",
        background: "var(--secondary-color)",
        color: "white",
        borderRadius: "20px",
      }}
    />
  ),
  undefined: (
    <IoNotifications
      size={40}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        // background: "black",
        background: "var(--secondary-color)",
        color: "white",
        borderRadius: "20px",
      }}
    />
  ),
};
