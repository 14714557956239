import { notification } from "antd";
import { privateAPI, publicAPI } from "../../config/constants";
import { Navigate } from "react-router-dom";

interface TeacherFormData {
  salutation: string;
  firstName: string;
  lastName: string;
  token: string;
}

// export const attachToken = () => {
//     const jwt = localStorage.getItem('token');
//     if (jwt) {
//       privateAPI.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
//     }
// };

// Function to send signup link
export const sendSignupLinkStudent = async (email: string) => {
  // attachToken();
  try {
    const response = await privateAPI.post("/student/send-signup-link", {
      email,
    });
    if (response) {
      notification.success({ message: "Email Sent Successfully" });
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const sendSignupLinkTeacher = async (email: string, icon: any) => {
  try {
    const response = await privateAPI.post("/teacher/send-signup-link", {
      email,
    });
    if (response) {
      notification.success({ message: "Email Sent Successfully" });
      // notification.open({ message: 'Email Sent Successfully', icon, duration: 1000})
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};


export const createTeacher = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/teacher/create", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating teacher:", error);
    //@ts-ignore
    notification.error({ message: error.message });
  }
};

export const createStudent = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/student/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // if(response.status === 200){
    //   notification.success({message:response.data.message})
    // }
      // console.log(response.status);
      // console.log(response.data);
      
    return response.data;
  } catch (error: any) {
    let errorMessage = "An error occurred while creating the student.";

    if (error.response) {
      errorMessage = error.response.data?.message || error.response.statusText;
    } else if (error.request) {
      errorMessage = "No response received from the server.";
    } else if (error.message) {
      errorMessage = error.message;
    }

    notification.error({
      message: errorMessage,
    });
    console.error("Error creating student:", errorMessage);
  }
};

export const signupTeacher = async (formData: FormData) => {
  console.log(formData);
  console.log(FormData);
  try {
    const response = await publicAPI.post("/teacher/signup", formData);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error signup teacher:", error);
    //@ts-ignore
    notification.error({ message: error.message });
  }
};

export const signupStudent = async (formData: FormData) => {
  try {
    const response = await publicAPI.post("/student/signup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    let errorMessage = "An error occurred while signup the student.";

    if (error.response) {
      errorMessage = error.response.data?.message || error.response.statusText;
    } else if (error.request) {
      errorMessage = "No response received from the server.";
    } else if (error.message) {
      errorMessage = error.message;
    }

    notification.error({
      message: errorMessage,
    });
    console.error("Error signup student:", errorMessage);
  }
};

export const updateStudent = async (payload: any) => {
  try {
    const response = await privateAPI.post("/student/update", payload);
    if (response) {
     
      notification.success({
        message:response.data.message
      })
    }
    return response;
  } catch (error: any) {
    console.log("UPDATE STUDENT ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};
export const updateTeacher = async (payload: any) => {
  try {
    const response = await privateAPI.post("teacher/update", payload);
    if (response) {
     
      notification.success({
        message:response.data.message
      })
    }
    return response;
  } catch (error: any) {
    console.log("UPDATE TEACHER ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};
