import { privateAPI } from "../../config/constants";
import store from "..";
import {
  setGroupAllNotification,
  setLoadingGetAllNotification,
  setTotal,
} from "../slices/Notifications/notifications";
import { notification } from "antd";

export const getAllNotificationsData = async (page:number, limit:number, status:string) => {
  try {
    store.dispatch(setLoadingGetAllNotification(true));
    store.dispatch(setGroupAllNotification([]));
    const response = await privateAPI.post(`/notification/get-all`, {
      page,
      limit,
      status
    });
    if (response) {
      store.dispatch(setGroupAllNotification(response?.data?.notifications));
      store.dispatch(setTotal(response?.data?.total));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    store.dispatch(setLoadingGetAllNotification(false));
  } finally {
    store.dispatch(setLoadingGetAllNotification(false));
  }
};

export const markAsRead = async (payload: any) => {
  try {
    store.dispatch(setLoadingGetAllNotification(true));
    const response = await privateAPI.post(`/notification/read-notifications`, payload);
    if (response) {
      notification.success({
        message: response.data.message || "Marked as Read",
      })
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    store.dispatch(setLoadingGetAllNotification(false));
  } finally {
    store.dispatch(setLoadingGetAllNotification(false));
  }
}
