
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActiveState {
  activeProcessing: boolean;
}

const initialState: ActiveState = {
  activeProcessing: false,
};

const activeSlice = createSlice({
  name: "active",
  initialState,
  reducers: {
    setActiveProcess(state, action: PayloadAction<boolean>) {
      state.activeProcessing = action.payload;
    },
  },
});

export const { setActiveProcess } = activeSlice.actions;

export default activeSlice.reducer;
