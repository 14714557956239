import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import App from "./config/App";

import store from "./store";

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Root element not found in the document");
}

const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgba(189, 229, 86, 1)",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);
