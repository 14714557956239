
import { createSlice } from "@reduxjs/toolkit";
import { SettingState } from "../interfaces/settings";

interface ReportState  {
    loading: boolean,
    reports: null | [],
  };
const initialState: ReportState = {
  loading: false,
  reports: [],
};

export const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setReports(state, action) {
        console.log("action",action)
      state.reports = action?.payload;
    },
  },
});

export const { setLoading, setReports } = reports.actions;

export default reports.reducer;
