import { notification } from "antd";
import store from "..";
import { privateAPI } from "../../config/constants"
import { Role, getAllAnnouncementsData } from "../interfaces/Annoucements/Announcement";
import { setLoadingFalse } from "../slices/Annoucements/Announcements.slice";
import { setLoading } from "../slices/Annoucements/Announcements.slice";
import { setAnnouncementData } from "../slices/Annoucements/Announcements.slice";
import { setCreateAnnoucementFalse } from "../slices/Annoucements/Announcements.slice";
import { setCreateAnnoucementLoading } from "../slices/Annoucements/Announcements.slice";
import { setCount } from "../slices/Annoucements/Announcements.slice";
import { setUserRole } from "../slices/Annoucements/Announcements.slice";
import { setUser } from "../slices/authSlice";


export const getAllAnnouncements = async (data: getAllAnnouncementsData) => {
    store.dispatch(setLoading());
    try {
        const res = await privateAPI.post("/notification/get-announcement", data);
        if (res) {
            store.dispatch(setCount(res?.data?.count));
            store.dispatch(setAnnouncementData(res?.data?.records));
        }

    } catch (error) {
        console.log("error announcement", error)
    }
    finally {
        store.dispatch(setLoadingFalse());
    }
}


export const createAnnoucenment = async (data: any) => {
    store.dispatch(setCreateAnnoucementLoading());
    try {
        const res = await privateAPI.post("/notification/create-announcement", data);
        if(res.status === 200){
            return res;
        }
    } catch (error: any) {
        console.log("error announcement", error.message);
    }
    finally {
        store.dispatch(setCreateAnnoucementFalse());
    }
}


export const usersRole = async()=>{
    try {
        const res = await privateAPI.post("/user/get-users-by-role",{page:1,perPage:10,search:'',role:'student'});
        return res;
    } catch (error) {
        console.log("error annoucement",error);
    }
}


