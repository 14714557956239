import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

function BarChartComp({ data }: any) {
//@ts-ignore
  const maxDataValue = Math.max(...data.map(item => Math.max(item.thisMonth, item.lastMonth)));
  
  // Determine ticks dynamically based on the max value
  const maxTick = Math.ceil(maxDataValue);
  // const ticks = Array.from({ length: maxTick + 1 }, (_, i) => i);
  const generateTicks = (data: any, initialInterval: number) => {
    const maxValue = Math.ceil(
      Math.max(
        ...data.map((entry: any) => Math.max(entry.thisMonth, entry.lastMonth))
      )
    );

    let interval = initialInterval;
    let ticks = Array.from(
      { length: Math.ceil(maxValue / interval) + 1 },
      (_, i) => i * interval
    );
    while (ticks.length > 5) {
      interval += 1;
      ticks = Array.from(
        { length: Math.ceil(maxValue / interval) + 1 },
        (_, i) => i * interval
      );
    }

    return ticks;
  };

  const ticks = generateTicks(data, 1);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}
          tickLine={false}
          axisLine={false}
          tick={({ x, y, payload }) => {
            const displayName =
              payload.value.length > 7
                ? `${payload.value.substring(0, 7)}...`
                : payload.value;
            return (
              <text
                x={x}
                y={y + 10}
                textAnchor="middle"
                fill="#666"
                spacing={20}
              >
                {displayName}
              </text>
            );
          }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          interval={0}
          domain={[0, maxTick]}
          ticks={ticks} 
          tickFormatter={(value) => String(value)}
          allowDuplicatedCategory={false}
          type="number"
        />
        <Tooltip />
        <Legend
          verticalAlign="top"
          style={{ marginBottom: "20px", textAlign: "start" }}
        />
        <Bar
          dataKey="thisMonth"
          barSize={30}
          stackId="a"
          fill="var(--primary-color)"
        >
          {data.map((entry: any, index: any) => {
            return (
              <Cell
                key={index}
                radius={
                  entry.lastMonth > 0
                    ? ([0, 0, 0, 0] as any)
                    : ([10, 10, 0, 0] as any)
                }
              />
            );
          })}
        </Bar>
        <Bar
          dataKey="lastMonth"
          stackId="a"
          fill="var(--secondary-color)"
          barSize={30} // Adjust the width of the bars here
          radius={[10, 10, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartComp;
