import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

function NeedleMeterChart({ percentage }: any) {
  const [primaryColor, setPrimaryColor] = useState("var(--primary-color)");

  useEffect(() => {
    // Dynamically get the CSS variable --primary-color from the :root
    const rootStyle = getComputedStyle(document.documentElement);
    const primaryColorValue = rootStyle.getPropertyValue("--primary-color"); // Fixed this line

    console.log(primaryColorValue);

    // Update the state with the CSS variable value if it's available
    if (primaryColorValue) {
      setPrimaryColor(primaryColorValue.trim());
    }
  }, []);

  console.log("PERCENTAGE >>", percentage);

  return (
    <GaugeChart
      id="gauge-chart"
      nrOfLevels={1} // Only one segment
      arcsLength={[percentage / 100, 1 - percentage / 100]} // Fill the entire gauge with one segment
      colors={[primaryColor, "#D3D3D3"]} // Color for the fill
      percent={percentage / 100} // Set the percentage (0.0 - 1.0 range)
      needleColor="var(--secoundary-color)" // Needle color
      needleBaseColor={primaryColor} // Needle base color
      arcPadding={0} // No padding between arcs
      cornerRadius={3} // Smooth corners
      hideText={true} // Hides default percentage text inside the chart
    />
  );
}

export default NeedleMeterChart;
