import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClassInfo } from "../../interfaces/Class/Class";



interface examState {
    examList: exam[]
}
interface exam {
    id: number,
    firstName: string,
    lastName: string,
    teacherId: number,
    classId: number,
    className: string
}

const initialState: examState = {
    examList: [],

};

const examSlice = createSlice({
    name: "assesment",
    initialState,
    reducers: {
        setExam(state, action) {
            state.examList = action.payload;
        }
    },
});

export const {
    setExam
} = examSlice.actions;

export default examSlice.reducer;
