import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createLevel } from '../../interfaces/Curriculum/Curriculum';

interface LevelState {
  levels: createLevel[];
  loading: boolean;
  currentSubjectId: number | null; // Assuming you have a field for the current subject ID
}

const initialState: LevelState = {
  levels: [],
  loading: false,
  currentSubjectId: null, // Assuming you have a field for the current subject ID
};

const levelSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {
    addLevel(state, action: PayloadAction<createLevel>) {
      state.levels.push(action.payload);
    },
    setLearningPath(state, action: PayloadAction<number>) {
      state.currentSubjectId = action.payload;
    },
    setLearningPathTarget(state, action: PayloadAction<number>) {
      state.currentSubjectId = action.payload;
    },
    clearCurrentSubjectId(state) {
      state.currentSubjectId = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { addLevel, setLearningPath, clearCurrentSubjectId, setLoading,setLearningPathTarget } = levelSlice.actions;

export default levelSlice.reducer;
