
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Space, Input, message } from 'antd';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import { updatePassword } from '../store/services/adminLogin';
// @ts-ignore
import screenimg from '../assets/screenimg.png'
import LoginHeader from '../components/LoginHeader/LoginHeader';

interface UpdatePasswordParams {
  email: string;
  code: string;
  newPassword: string;
}

const SetNewPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<string | null>(null); // Initialize code state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    if (codeParam) {
      setCode(String(codeParam)); // Convert codeParam to a number and set in state
    }
  }, [location.search]);

  const onFinish = async (values: UpdatePasswordParams) => {
    setLoading(true);
    try {
      if (!code) {
        throw new Error('Code parameter is missing.'); // Validate that code is present
      }
      const response = await updatePassword({ ...values, code });
      console.log(response);
      message.success('Password updated successfully.');
      navigate('/login');
    } catch (error) {
      console.error(error);
      message.error('Failed to update password. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="credentials-page">
      <LoginHeader />
      <Row>
        <Col lg={12}>
        <div className='tank'>
        <div className='auth-form'>
        <h1>Set New Password</h1>
        <p>Please Enter your Details</p>
              {/* <h1>Set New Password</h1>
              <p>Please Enter your Details</p> */}
              <Space />
              <Form name="updatePassword" layout="vertical" onFinish={onFinish}>
                
                <Form.Item name="email" label="Email"  className='labels' rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}>
                  <Input placeholder="Enter your email" className='input' />
                </Form.Item>
                <Form.Item name="newPassword" label="New Password"  className='labels' rules={[{ required: true, message: 'Please enter your new password' }]}>
                  <Input.Password placeholder="Enter your new password" className='input' />
                </Form.Item>
                <Form.Item>
                <Button
                    className='submit-btn'
                    htmlType="submit"
                    loading={loading}
                  >
                    Update Password
                  </Button>
                </Form.Item>
                <p
                // type="link" icon={} 
                // style={{ background: 'transparent', fontWeight: '600', fontSize: '14px', width: "max-content"}}
                onClick={() => navigate('/login')}
                className='back-to-login'
                
              >
                <FaArrowLeft />
                Back to login
              </p>
              </Form>
              {/* <Button 
                type="link" 
                icon={<FaArrowLeft />} 
                style={{color:'white', fontWeight:'600', fontSize:'14px'}}
                onClick={() => navigate('/login')}
                className='button'
              >
                Back to login
              </Button> */}
            </div>
          </div>
        </Col>
        <Col xs={0} md={12} lg={12}>
          <div className='image-div'>
            <img src={screenimg} alt="" className='screenimg' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SetNewPassword;
