import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useBlocker = (isActive: boolean, message: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isActive) {
        event.preventDefault();
        event.returnValue = message; 
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    console.log("blocker enter");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      console.log("blocker return");
      
    };
  }, [isActive, message]);

  useEffect(() => {
    if (!isActive) {
      const handleClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (target.closest("a") && !window.confirm(message)) {
          event.preventDefault();
          event.stopPropagation();
          navigate(window.location.pathname);
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [isActive, message, navigate]);
};

export default useBlocker;
