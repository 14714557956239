import React, { useState } from "react";
import Yourprofile from "../../components/SettingComponents/Yourprofile";
import Password from "../../components/SettingComponents/Password";
import "../../styles/pages/setting.css";

// import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Logo from "../../components/SettingComponents/Logo";
import Platform from "../../components/SettingComponents/PlatformColor";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { useAppSelector } from "../../store/hooks";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import Admins from "../../components/SettingComponents/Admins";

const Setting = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [active, setActive] = useState("Profile");

  const getList = () => {
    let list: String[] = [];
    if (checkRoutePermissions("profileTab")) {
      list.push("Profile");
    }
    if (checkRoutePermissions("passwordTab")) {
      list.push("Password");
    }
    if (checkRoutePermissions("logoTab")) {
      list.push("Logo");
    }
    if (checkRoutePermissions("platformTab")) {
      list.push("Platform");
    }
    if (checkRoutePermissions("adminsTab")) {
      list.push("Admins");
    }
    return list;
  };

  return checkRoutePermissions("settingPage") ? (
    <div className="mainDiv responsive">
      <div className="notifications-btn-parent">
        <h2 className="notificationHeading">Settings</h2>
      </div>
      <p style={{ marginBottom: "10px" }} className="settingTitle">
        Set up your profile.
      </p>
      <div>
        <CustomTabs
          list={getList()}
          setActiveItem={setActive}
          activeItem={active}
        />
        {active === "Profile" ? (
          <>
            {checkRoutePermissions("profileTab") && <Yourprofile user={user} />}
          </>
        ) : active === "Password" ? (
          <>{checkRoutePermissions("passwordTab") && <Password />}</>
        ) : active === "Logo" ? (
          <>{checkRoutePermissions("logoTab") && <Logo />}</>
        ) : active === "Platform" ? (
          <>{checkRoutePermissions("platformTab") && <Platform />}</>
        ) : active === "Admins" ? (
          <>{checkRoutePermissions("adminsTab") && <Admins />}</>
        ) : (
          <>{checkRoutePermissions("profileTab") && <Yourprofile />}</>
        )}
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Setting;
