
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tabs, TabsProps } from "antd";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  getAllNotificationsData,
  markAsRead,
} from "../../store/services/notifications";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";
import { getAllNotifications } from "../../store/interfaces/notifications/Notifications";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { NotificationStatusIcon } from "../../config/NotificationIcon";
import { MdNotificationsActive } from "react-icons/md";
import { getNotificationsCount } from "../../store/services/notificationCounts";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";

const Notification = () => {
  const { loadingGetAllNotifications, groupAllNotifications, total } =
    useAppSelector((state) => state.notification);
  const [unreadNotifications, setUnreadNotifications] = useState<
    getAllNotifications[]
  >([]);
  const { unreadCount } = useAppSelector((state) => state.notificationCount);
  const [all, setAll] = useState<getAllNotifications[]>([]);
  const [active, setActive] = useState("1");
  const [page, setPage] = useState<number>(1);

  const onChange = (key: string) => {
    setActive(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Unread",
      children: (
        <>
          <div className="notifications-tabs-content notification-custom-scrollbar">
            {unreadNotifications.length > 0 ? (
              unreadNotifications.map((data, index) => (
                <div className="notifications-bg" key={index}>
                  <Row align={"middle"}>
                    <Col xs={24} md={3} lg={2}>
                      {data.type === ""
                        ? NotificationStatusIcon["general"]
                        : NotificationStatusIcon[data.type]}
                    </Col>
                    <Col xs={24} sm={15} md={14} lg={15} xl={17}>
                      <div>
                        <h4>{moment(data?.date).format("hh:mm A")}</h4>
                        <p className="notification-text">{data?.message}</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={3}>
                      <Button
                        className="notificationbtn notification-read"
                        icon={<FaRegCheckCircle className="mark-read" />}
                        onClick={() => handleSingleMarkasRead(data.id)}
                      >
                        Mark as Read
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MdNotificationsActive className="bellNotification"  />
                <h4>No Unread Notifications</h4>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "All",
      children: (
        <>
          <div className="notifications-tabs-content notification-custom-scrollbar">
            {all.length > 0 ? (
              all.map((data, index) => (
                <div
                  key={index}
                  className={
                    data.isRead === 1
                      ? "notifications-bg-unread"
                      : "notifications-bg"
                  }
                >
                  <Row align={"middle"}>
                    <Col xs={24} md={3} lg={2}>
                      {data.type === ""
                        ? NotificationStatusIcon["general"]
                        : NotificationStatusIcon[data.type]}
                    </Col>
                    <Col xs={24} sm={15} md={16} lg={18}>
                      <div>
                        <h4>
                          Admin <div className="dot" />:{" "}
                          {moment(data?.date).format("HH:mm")}
                        </h4>
                        <p className="notification-text">{data?.message}</p>
                      </div>
                    </Col>
                    {data.isRead === 1 ? (
                      <></>
                    ) : (
                      <Col xs={24} sm={3}>
                        <Button
                          className="notificationbtn notification-read"
                          icon={<FaRegCheckCircle className="mark-read" />}
                          onClick={() => handleSingleMarkasRead(data.id)}
                        >
                          Mark as Read
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <MdNotificationsActive size={80} />
                <h4>No New Notifications</h4>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const getData = async () => {
    const pageNumber = page;
    const limit = 5;
    const status = active === "1" ? "unread" : "all";
    try {
      await getAllNotificationsData(pageNumber, limit, status);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const extractNotificationsData = () => {
    const allNotificationData = groupAllNotifications;
    const unreadNotificationData = groupAllNotifications.filter(
      (item: getAllNotifications) => item?.isRead === 0
    );
    setAll(allNotificationData);
    setUnreadNotifications(unreadNotificationData);
  };

  useEffect(() => {
    extractNotificationsData();
  }, [groupAllNotifications]);

  const markAllAsRead = async () => {
    const payload = {
      ids:
        unreadNotifications?.length > 0 &&
        unreadNotifications?.map((item) => item.id),
    };
    const res = await markAsRead(payload);
    if (res) {
      getData();
      await getNotificationsCount();
    }
  };

  const handleSingleMarkasRead = async (value: any) => {
    const payload = {
      ids: [value],
    };
    const res = await markAsRead(payload);
    if (res) {
      getData();
      await getNotificationsCount();
    }
  };

  useEffect(() => {
    getData();
  }, [page, active,unreadCount]);

  return checkRoutePermissions("notificationPage") ? (
    <div className="mainDiv responsive">
      <div className="main_parent" style={{height:"100%"}}>
        <div>
          <div className="notifications-btn-parent">
            <h2 className="notificationHeading">Notifications</h2>
            <div>
              {active === "1"  && (

                <Button
                  className="notificationbtn"
                  icon={<FaRegCheckCircle className="mark-read" />}
                  onClick={markAllAsRead}
                >
                  Mark all as Read
                </Button>
              )}
            </div>
          </div>
          <p className="notification_text" style={{ marginBottom: "10px" }}>
            You have {unreadNotifications.length} notifications to go through.
          </p>
          <hr />
        </div>
        <div className="mian_tab" style={{height:"100%"}}>
          {loadingGetAllNotifications ? (
            <div className="loadingGIF" style={{height:"100%"}}>
              <img
                width={60}
                height={50}
                src={require("../../assets/loadingGrid.gif")}
                alt="loading"
              />
              <p>Loading....</p>
            </div>
          ) : (
            <div style={{height:"100%"}}>
              <Tabs
                defaultActiveKey="1"
                activeKey={active}
                items={items}
                onChange={onChange}
              />
            </div>
          )}
        </div>
        {active === "2" && (
          <div className="main_pagination">
            <CustomPagination
              currentPage={page}
              setCurrentPage={setPage}
              total={total}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Notification;
