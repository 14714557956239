import { privateAPI } from "../../config/constants";



export const fetchSingleExamDetails =async(data:{examId:any})=>{
    try {
        const res = await privateAPI.post("/exam/get-exam-details",data);
        if(res.status === 200){
            return res.data;
        }
    } catch (error) {
        console.log("error exam single details",error);
    }
}