import React from 'react'
import LogoutModal from '../components/Modals/LogoutModal'



const LogOut = () => {
  return (
    <div className='logout'>
      <LogoutModal/>
    </div>
  )
}

export default LogOut
