import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

interface TeacherExamState {
  isLoading: string;
  exams: any;
  examDetail: any;
  currentStudent: any;
  updatedArray: any;
  selectExamId: number;
}

const initialState: TeacherExamState = {
  isLoading: "exam",
  exams: [],
  examDetail: null,
  currentStudent: null,
  updatedArray: [],
  selectExamId: -1,
};

const teacherExamSlice = createSlice({
  name: "teacherExam",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setExams(state, action) {
      state.exams = action.payload;
    },
    setExamDetail(state, action) {
      state.examDetail = action.payload;
    },
    setCurrentStudent(state, action) {
      state.currentStudent = action.payload;
    },
    setCurrentStd(state,action){
      state.currentStudent = state.examDetail.students[action.payload];
    },
    setNextStudent(state) {
      const currentIndex = state.examDetail.students.findIndex(
        (student: any) => student.id === state.currentStudent?.id
      );
      const nextIndex = (currentIndex + 1) % state.examDetail.students.length;
      return {
        ...state,
        currentStudent: state.examDetail.students[nextIndex],
      };
    },
    setPreStudent(state) {
      const currentIndexPrev = state.examDetail.students.findIndex(
        (student: any) => student.id === state.currentStudent?.id
      );
      const prevIndex =
        (currentIndexPrev - 1 + state.examDetail.students.length) %
        state.examDetail.students.length;
      return {
        ...state,
        currentStudent: state.examDetail.students[prevIndex],
      };
    },
    setUpdatedArray(state, action) {
      state.updatedArray = action.payload;
    },
    setSelectExamId(state, action) {
      state.selectExamId = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setExams,
  setExamDetail,
  setCurrentStudent,
  setNextStudent,
  setPreStudent,
  setUpdatedArray,
  setCurrentStd,
  setSelectExamId,
} = teacherExamSlice.actions;

export default teacherExamSlice.reducer;
