import React from "react";
import { Button } from "antd";
import "../../styles/pages/gHeader.css";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { checkRoutePermissions } from "../../utils/permission";

interface GHeaderProps {
  heading: string;
  headingText?: string;
  numberOfPeople?: string | number;
  buttonText?: string;
  onClick?: () => void;
  bool?: boolean;
}

const GHeader: React.FC<GHeaderProps> = ({
  heading,
  numberOfPeople,
  headingText,
  buttonText,
  onClick,
  bool,
}) => {
  const navigate = useNavigate();

  if (bool) {
    navigate("/create-assesment");
  }
  return (
    <div className="gheaderMain">
      <div className="gheader-c">
        <div className="gheader">
          <p className="heading">{heading}</p>
          {numberOfPeople && (
            <div className="people-gheader">
              <p className="people">{numberOfPeople}</p>
            </div>
          )}
        </div>
        <div className="text-parent">
          <p className="text">{headingText}</p>
        </div>
      </div>
      <div className="btn_top">
        {checkRoutePermissions("createStudent") && buttonText && (
          <Button
            className="header-btn"
            icon={<IoMdAdd className="mark-read" />}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GHeader;
