import { Button, Checkbox, Col, Form, Row, Space } from "antd";
import React from "react";
import "../../src/styles/pages/globalResponsive.css";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import acedemicHat from "../assets/acedemic-hat.png";
import LoginHeader from "../components/LoginHeader/LoginHeader";

import screenimg from '../assets/screenimg.png';

interface signupformValues {
  name: string;
  email: string;
  password: string;
}

const SignUp = () => {
  const navigate = useNavigate();
  return (
    <div className='credentials-page'>
      <LoginHeader />
      <Row>
        <Col lg={12}>
          <div className='tank'>
            <div className='auth-form'>

              <Form
                layout="vertical"
              >
                <h1>Sign Up</h1>
                <h3>Please enter your details</h3>
                <Space />

                <Form.Item
                  name="Full Name"
                  label="Full Name"
                  rules={[{ required: true }]}
                >
                  <input className="input" placeholder="Enter Your Full Name" />
                </Form.Item>
                <Form.Item
                  name="Email"
                  label="Email"
                  rules={[{ required: true }]}
                >
                  <input className="input" placeholder="Enter your Email" />
                </Form.Item>
                <Form.Item
                  name="Password"
                  label="Password"
                  rules={[{ required: true }]}
                >
                  <input className="input" placeholder="Enter your Password" />
                </Form.Item>
                <Form.Item>
                  <Checkbox>
                    I agree to Progress Tracker’s Terms of Use and Privacy
                    Policy
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  {/* <Space> */}
                    {/* <SubmitButton form={form}>Submit</SubmitButton> */}
                    <Button className='submit-btn' htmlType='submit'>
                      Sign Up
                    </Button>
                  {/* </Space> */}
                </Form.Item>
              <p className="caution">Already have an account? <span onClick={() => navigate("/login")}>Login</span></p>

              </Form>

              {/* <p>
                Already have an account?
                <strong>
                  <button
                    className="back-to-login"
                    onClick={() => navigate("/")}
                  >
                    LogIn
                  </button>
                </strong>
              </p> */}
            </div>
          </div>
        </Col>

        <Col xs={0} md={12} lg={12}>
          <div className='image-div'>
            <img src={screenimg} alt="" className='screenimg' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
