import React from 'react'

const CustomTabs = ({ list, activeItem, setActiveItem }: any) => {
    return (
         <div className='Custom_tabs_containers'>
            {list?.map((tab:any ,index:any) => {return (
               <div 
               key={index} 
               className={`${activeItem === tab ? "custom_active_tab" : "custom_tab"} custom-tab-all `} 
               onClick={() => setActiveItem(tab)}
             >
               {tab}
             </div>
            )})}
        </div>
    )
}

export default CustomTabs