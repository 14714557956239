import { Row, Col, Flex, Spin, Collapse, theme } from "antd";
import Layout from "../../components/layout/index";
import React, { useEffect, useState } from "react";
import "../../styles/pages/targets.css";
import ProjectSelect from "../../components/ProjectSelect/ProjectSelect";
import SearchableDropdown from "../../components/SearchableDropdown";
import { getLearningapi } from "../../store/services/curriculum";
import { getAllSubject } from "../../store/services/subject";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsChevronExpand } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const panelStyle = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #d9d9d9",
};
const panelStyle2 = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "none",
  fontSize: "10px",
  // color:"red"
};
const openCloseIcon = (isActive: any) => {
  return isActive ? (
    <MdKeyboardArrowDown style={{ width: "20px", height: "20px" }} />
  ) : (
    <BsChevronExpand style={{ width: "20px", height: "20px" }} />
  );
};

const Targets = () => {
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(false);
  const [fetchValue, setFetchValue] = useState([]);
  const [levels, setLevels] = useState<any>([]);
  const [targets, setTarget] = useState<any>([]);
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [currentModule, setCurrentModule] = useState<number | null>(null);
  const navigate = useNavigate();
  const { token } = theme.useToken();

  useEffect(() => {
    sendData();
  }, []);

  const sendData = async () => {
    const getSub = await getAllSubject(0, 10, "", "active");

    if (getSub) {
      setIsLoading(true);
      setDefaultValue(getSub.subjectList[0].subjectName);
      const payload = {
        subjectId: Number(getSub.subjectList[0].subjectId),
      };

      const response = await getLearningapi(payload);
      setCurrentModule(getSub.subjectList[0].subjectId);

      if (response) {
        console.log(response);

        const newLevels = response.newLevelList.map((newLevel: any) => {
          const modules = newLevel.modules.map((module: any) => ({
            name: module.moduleName,
            targets: module.lessonsList.map((lesson: any) => lesson),
            moduleId: module.moduleId,
            status: module.status,
          }));

          const { levelId } = newLevel;
          // setCurrentLevelId(newLevel.levelId);
          return {
            level: newLevel.levelName,
            status: newLevel.status,
            modules: modules,
            levelId: levelId,
          };
        });
        setLevels(newLevels);
        setTarget(newLevels);
      }
      setIsLoading(false);
    }
    // setActiveItem(false);
  };

  const onChange = async (e: any) => {
    const findClass: any = fetchValue.find((item: any) => {
      return e === item.subjectId;
    });
    setDefaultValue(findClass.subjectName);
    setLevels([]);
    setIsLoading(true);
    const payload = {
      subjectId: findClass.subjectId,
    };
    // setCurrentModule(findClass.subjectId);
    const response = await getLearningapi(payload);
    if (response) {
      setIsLoading(true);
      const newLevels = response.newLevelList.map((newLevel: any) => {
        const modules = newLevel.modules.map((module: any) => ({
          name: module.moduleName,
          targets: module.lessonsList.map((lesson: any) => lesson),
          moduleId: module.moduleId,
        }));

        const { levelId } = newLevel;
        // setCurrentLevelId(newLevel.levelId);
        return {
          level: newLevel.levelName,
          modules: modules,
          levelId: levelId,
        };
      });
      setLevels(newLevels);
      setTarget(newLevels);
    }
    setIsLoading(false);
    // setActiveItem(false);
  };

  const handleTargetClick = (
    target: string,
    defaultActiveKey?: any,
    level?: any
  ) => {
    // navigate(`/lessondetails/${target}`);
    navigate(`/lessondetails/${target}`, {
      state: { subject: defaultActiveKey, level: level },
    });
  };

  return (
    <>
      <main className="target-main-container">
        <Row>
          <Col xs={24}>
            <h1 className="targets">Targets</h1>
          </Col>
          <Col xs={24}>
            <h6 className="target-review">Review and manage the targets.</h6>
          </Col>
          {/* tajweed */}
          <Col xs={24}>
            <div style={{ marginTop: "10px" }}>
              <SearchableDropdown
                onChange={onChange}
                setFetchedOptions={setFetchValue}
                url="/subject/get-all"
                mode={false}
                placeholder="Search by subject name"
                columns={["subjectName"]}
                type="subject"
                defaultValue={defaultValue}
                params={{ status: "active"}}
              />
            </div>
          </Col>
          {/* option 1 */}
          <Col xs={24}>
            <div style={{ marginTop: "15px" }}>
              {isLoading ? (
                <Flex
                  align="center"
                  justify="center"
                  style={{ height: "50vh" }}
                  gap="middle"
                >
                  <Spin size="large" />
                </Flex>
              ) : !currentModule ? (
                <h1 style={{ textAlign: "center" }}>Select Level</h1>
              ) : (
                <Collapse
                  className="custom_dashboard_collapse"
                  bordered={false}
                  expandIcon={({ isActive }) => openCloseIcon(isActive)}
                  style={{ background: "transparent" }}
                >
                  {levels.length > 0 ? (
                    levels
                      .filter((level: any) => level?.status === "active")
                      .map((level: any, index: number) => (
                        <Panel
                          header={level.level}
                          key={index}
                          style={panelStyle}
                        >
                          <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => (
                              <IoIosArrowDown
                                size={20}
                                className={`arrow-icon ${
                                  isActive ? "rotate" : ""
                                }`}
                              />
                            )}
                            style={{ background: token.colorBgContainer }}
                            accordion
                            className="_innerCollapse"
                          >
                            {level?.modules
                              ?.filter(
                                (module: any) => module?.status === "active"
                              )
                              .map((module: any, i: number) => (
                                <Panel
                                  key={module?.name}
                                  header={module?.name}
                                  style={panelStyle2}
                                  className="innerpanel"
                                >
                                  <ol
                                    style={{ paddingLeft: "1px" }}
                                    className="custom-list"
                                  >
                                    {module?.targets?.map(
                                      (lesson: any, i: number) => {
                                        if (lesson.status === "active") {
                                          return (
                                            <li
                                              key={i}
                                              onClick={() => {
                                                handleTargetClick(
                                                  lesson?.lessonId,
                                                  defaultValue,
                                                  level
                                                );
                                                console.log(
                                                  defaultValue,
                                                  level,
                                                  lesson
                                                );
                                              }}
                                              style={{
                                                cursor: "pointer",
                                                color: "black",
                                                fontSize: "14px",
                                                margin: "10px",
                                              }}
                                            >
                                              {lesson?.lessonName}
                                            </li>
                                          );
                                        }
                                      }
                                    )}
                                  </ol>
                                </Panel>
                              ))}
                          </Collapse>
                        </Panel>
                      ))
                  ) : (
                    <p style={{ textAlign: "center", color: "gray" }}>
                      No lessons available
                    </p>
                  )}
                </Collapse>
              )}
            </div>
          </Col>
        </Row>
      </main>
    </>
    // <main className="target-main">
    // </main>
  );
};

export default Targets;
