import { Card, Col, Tag, Tooltip , Skeleton} from 'antd';
import React , { useState , useEffect } from 'react';
import { CheckCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import graphdown from "../../assets/graphdown.png";

interface cardProps {
    cardName: string;
    graphImage: string;
    data?: any;
    type: string;
    graphArrow: string;
}

const ReportCard: React.FC<cardProps> = ({ cardName, graphImage, type, graphArrow, data }) => {
    const [dataArray , setDataArray ] = useState(data)
    console.log("🚀 ~ dataArray:", dataArray)
    const className = dataArray[0]?.className ? dataArray[0].className : "Unknown Class";
    const totalProgress = dataArray[0]?.totalProgress ? dataArray[0].totalProgress??0 : 0;
    const studentCount = dataArray ? dataArray?.length : 0;
    const studentNames = dataArray ? dataArray?.map((student: any) => student.name??student.studentName).join(', ') : "";

    useEffect(()=>{
        setDataArray(data)
    },[data])

    return (
        dataArray ? 
        (<Col xs={24} sm={12} lg={12}>
            <Card className="custom_card">
                <div className="cardupdiv">
                    <img src={graphImage} alt="graph" />
                    <h1>{cardName}</h1>
                </div>
                <div className="classlabel">
                    <p className="classtext">{className}</p>
                </div>
                <div className="carddowndiv">
                    {/* Tooltip for student names */}
                    <Tooltip title={studentNames ? studentNames : "No students"}>
                        <h1>{studentCount ?? 12} Students</h1>
                    </Tooltip>
                    <Tag
                        icon={graphArrow === "down" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                        color={type}
                        style={{ borderRadius: "16px", padding: "4px" }}
                    >
                        {totalProgress}%
                    </Tag>
                </div>
            </Card>
        </Col>
        ) :
        (
        <Col xs={24} sm={12} lg={12}>
            <Card className="custom_card">
                <div className="cardupdiv">
                    <Skeleton loading={true} active >
                    </Skeleton>
                </div>
            </Card>
        </Col>
        )
    );
};

export default ReportCard;
