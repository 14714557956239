import useWindowDimensions from "../../../Hook/useWindowDimensions";
import { checkRoutePermissions } from "../../../utils/permission";
import NotAccess from "../../NotAccess";
import DesktopView from "../ClassesProgress/DesktopView";
import MobileView from "./MobileView";

const ClassesProgress = () => {
  const { windowWidth } = useWindowDimensions();

  return checkRoutePermissions("classesProgressPage") ? (
    <>{windowWidth > 767 ? <DesktopView /> : <MobileView />}</>
  ) : (
    <NotAccess />
  );
};

export default ClassesProgress;
