import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Select } from "antd";
import { IoIosAdd } from "react-icons/io";
import { useAppSelector } from "../../../store/hooks";
import { createClasses, updateClass } from "../../../store/services/class";
import { privateAPI } from "../../../config/constants";
import SearchableDropdown from "../../SearchableDropdown";
import { TiDelete } from "react-icons/ti";

const { Option } = Select;

interface ClassModalProps {
  setIsModalOpens: (isOpen: boolean) => void;
  isModalOpens: boolean;
  isEdit: boolean;
  setIsEdit: any;
  modalData: any;
  fetchClass: () => void;
  count: any;
}

const ClassModal: React.FC<ClassModalProps> = ({
  setIsModalOpens,
  isModalOpens,
  isEdit,
  setIsEdit,
  modalData,
  fetchClass,
  count,
}) => {
  const { subjects, classes } = useAppSelector((state) => state.class);
  const [name, setName] = useState("");
  const [change, setChange] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [subject, setSubject] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [progressClassId, setprogressClassId] = useState(0);

  const handleCancel = () => {
    setprogressClassId(0);
    setName("");
    setChange([]);
    setIsModalOpens(false);
    setIsEdit(false);
    setError("");
  };

  // SELECT VALIDATION FUNCRION
  const validateForm = () => {
    if (change.length === 0) {
      setError("Please select at least one subject.");
      return false;
    }
    setError("");
    return true;
  };

  const sendData = async () => {
    if (!name) {
      message.error("Name is required");
      return;
    }
    if (!isEdit && change.length === 0) {
      message.error("subjects are required");
      return;
    }
    setLoad(true);
    if (isEdit) {
      updateData();
    } else {
      const data = {
        className: name,
        subjectIds: change,
      };
      const res = await createClasses(data);
      if (res?.status === 200) {
        handleCancel();
        fetchClass();
        setLoad(false);
        setIsModalOpens(false);
      } else {
        setLoad(false);
      }
    }
  };

  const updateData = async () => {
    if (!validateForm()) return;

    try {
      setLoad(true);
      const filteredSubjects = change.filter(
        (item) =>
          !modalData?.subjects?.some((subj: any) => subj.subjectId === item)
      );
      const data = {
        className: name,
        subjectIds: filteredSubjects,
        classId: modalData.classId,
        progressClassId: progressClassId,
      };
      const res = await updateClass(data);
      if (res?.status === 200) {
        setIsModalOpens(false);
        fetchClass();
        setLoad(false);
        handleCancel();
      } else {
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoad(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setName(modalData?.className);
      // setName({`class-${modalData?.classIndex}`});
      const edit = modalData.subjects.map((item: any) => item.subjectId);
      setSubject(edit);
      setChange(edit);
      setprogressClassId(modalData?.progressClassId || 0);
    } else {
      // setName("");
      // setName(`class-${classes?.length + 1}`);
      setName(`class-${count + 1}`);
      setSubject([]);
      setprogressClassId(0);
      setChange([]);
    }
  }, [isEdit, modalData, classes]);

  const onChange = (e: any) => {
    setChange(e);
  };

  return (
    <Modal
      title={
        <div className="modal_addIcon_parent">
          <IoIosAdd fontSize={"26px"} color="blue" />
        </div>
      }
      open={isModalOpens}
      onCancel={handleCancel}
      footer={null}
      
    >
      <div className="class_modal">
        <h2 className="class_modal_hdng">{isEdit ? "Edit" : "Add"} a class</h2>
        <p className="class_modal_subHdng">
          Fill out the following information to add a new class to the system.
        </p>
        <div>
          <div>
            <label className="input_label">Class Name</label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Class Name"
            />
            <br />
            <br />
            <label className="input_label">Next Class</label>
            <div className="progress_classInput_parent">
              <SearchableDropdown
                url="/class/get-all"
                mode={false}
                placeholder="progress class"
                columns={["className"]}
                type="classes"
                defaultValue={
                  progressClassId === 0 ? undefined : progressClassId
                }
                onChange={(e: any) => setprogressClassId(e)}
                currentData={modalData}
                params={{ status: "active" }}
              />
              {isEdit &&
                modalData.progressClassId > 0 &&
                progressClassId > 0 && (
                  <TiDelete
                    size={30}
                    className="cursor-pointer"
                    onClick={() => setprogressClassId(0)}
                  />
                )}
            </div>
            {/* <Select
              mode="multiple"
              placeholder="Select Subject"
              value={change} // Set value to change state
              onChange={(e) => setChange(e)}
              style={{ width: "100%" }}
            >
              {subjects.map((item: any) => (
                <Option key={item.subjectId} value={item.subjectId}>
                  {item.subjectName}
                </Option>
              ))}
            </Select>
            {error && (
              <p style={{ color: 'red', marginTop: '5px' }}>{error}</p>
            )} {/* Display validation error */}
            <br />
            <label className="input_label">Subject Name</label>
            <SearchableDropdown
              onChange={onChange}
              url="/subject/get-all"
              mode={true}
              defaultValue={change}
              placeholder="Search by subject name"
              columns={["subjectName"]}
              type="subject"
              currentData={modalData}
              params={{ status: "active" }}
            />
            <br />
            <br />
          </div>
        </div>
        <div className="btns-parent">
          <Button onClick={handleCancel}>Cancel</Button>
          {isEdit ? (
            <Button
              loading={load}
              disabled={load}
              className="class_modal_btn"
              onClick={sendData}
            >
              Update
            </Button>
          ) : (
            <Button
              loading={load}
              disabled={load}
              className="class_modal_btn"
              onClick={sendData}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ClassModal;
