import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Space, InputNumber } from "antd";
import addstudent from "../../assets/addstudent.png";
import { addSubject, updateSubject } from "../../store/services/subject";

interface AddTabModalProps {
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  getData?: any;
  editMode?: boolean;
  editItem?: any;
}

interface FormValues {
  subject: string;
  sortNumber: number;
}

const AddTabModal: React.FC<AddTabModalProps> = ({
  isModalVisible,
  handleOk,
  handleCancel,
  getData,
  editMode,
  editItem,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    let response = null;
    if (editMode) {
      response = await updateSubject({
        subjectId: editItem.subjectId,
        subjectName: values.subject,
        sortNumber: values.sortNumber,
      });
    } else {
      response = await addSubject({
        subjectName: values.subject,
        sortNumber: values.sortNumber,
      });
    }
    if (response) {
      getData();
      handleOk();
      form.resetFields();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editMode && editItem) {
      form.setFieldsValue({
        subject: editItem.subjectName,
        sortNumber: editItem.sortNumber,
      });
    }
  }, [editItem, editMode]);

  return (
    <Modal
      open={isModalVisible}
      className="custom-modal"
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <img src={addstudent} alt="logo" />
      <div className="modalheading">
        {editMode ? <h1>Edit Subject</h1> : <h1>Add Subject</h1>}
      </div>
      <div className="form">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="subject"
            label="Subject Name"
            className="labels"
            rules={[
              { required: true, message: "Please input the subject name!" },
            ]}
          >
            <Input placeholder="Add Subject" className="labelinput" />
          </Form.Item>
          <Form.Item
            name="sortNumber"
            label="Sort Number"
            className="labels"
            rules={[
              { required: true, message: "Please input the sort number!" },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              placeholder="Sort Number"
              className="labelinput"
              defaultValue={undefined}
              type="number"
              onKeyDown={(event) => {
                if (
                  !/^[0-9]$/.test(event.key) &&
                  !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(
                    event.key
                  )
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item>
            <Space className="curriculum_btn">
              <Button className="cancelbtn" onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                loading={isLoading}
                disabled={isLoading}
                htmlType="submit"
                className="addbtn"
              >
                {editMode ? "Update" : "Add"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddTabModal;
