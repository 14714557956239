import { createSlice } from "@reduxjs/toolkit";
import { getAllNotificationsStateType } from "../../interfaces/notifications/Notifications";

const initialState: getAllNotificationsStateType = {
  loadingGetAllNotifications: false,
  groupAllNotifications: [],
  total: 0,
};

export const notificationsSlice = createSlice({
  name: "getAllNotifications",
  initialState,
  reducers: {
    setLoadingGetAllNotification: (state, action) => {
      state.loadingGetAllNotifications = action.payload;
    },
    setGroupAllNotification: (state, action) => {
      state.groupAllNotifications = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const {
  setLoadingGetAllNotification,
  setGroupAllNotification,
  setTotal,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
