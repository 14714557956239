import { Progress, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getLearningYears } from "../../store/services/learning";

interface YearType {
  yearId: string;
  value: string;
}

const HistoricalLevel = ({
  loading,
  year,
  setYear,
  progress,
  studentId,
  activeSubject
}: any) => {
  const [years, setYears] = useState<YearType[] | null>(null);

  useEffect(() => {
    const getYears = async () => {
      try {
        const res = await getLearningYears(studentId);
        setYears(res.years);
      } catch (err) {
        console.log("error", err);
      }
    };
    getYears();
  }, [studentId,activeSubject]);

  useEffect(() => {
    if (years && years.length > 0) {
      setYear(years[0].yearId);
    }
  }, [years]);

  return (
    <>
      <div className="tab_learning_text">Historical Level</div>
      <div className="learning_tab">
        <div className="learning_btn_tabs_parent">
          {years &&
            years?.length > 0 &&
            years.map((item: any) => {
              return (
                <div
                  onClick={() => {
                    setYear(item?.yearId);
                  }}
                  className={`learn_btn_tabs_child ${
                    item?.yearId === year && "learn_btn_tabs_child_active"
                  } `}
                >
                  {item?.value}
                </div>
              );
            })}
        </div>
      </div>

      <div className="level_learning_tab custom_scroll_bar">
        {loading ? (
          <div className="history_loading_box">
            <Spin className="student_custom_spinner" />
          </div>
        ) : (
          progress?.map((item: any, index: number) => (
            <div key={index} className="level_learning_child">
              {/* <p>Level at the beginning </p> */}
              <div className="level_learning_child_btn">{item?.name}</div>
              <Progress
                strokeColor="var(--secondary-color)"
                percent={Number((Number(item?.percent) * 100).toFixed(0))}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default HistoricalLevel;
