import { createSlice } from "@reduxjs/toolkit";

import { ScheduleState } from "../interfaces/schedule";

const initialState: ScheduleState = {
  isLoading: true,
  schedules: [],
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSchedules: (state, action) => {
      state.schedules = action.payload;
    },
  },
});

export const { setLoading, setSchedules } = scheduleSlice.actions;

export default scheduleSlice.reducer;
