import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { dateFormat } from "../../../utils";

interface TeacherDashboardState {
  isLoading: boolean;
  upcomingLoading: boolean;
  currentWeekDay: any;
  currentDate: any;
  activeClass: number;
  upcomingClassesData: any;
  topPerformerData: any;
}

const initialState: TeacherDashboardState = {
  isLoading: true,
  upcomingLoading: true,
  currentWeekDay: moment().startOf("week").format(dateFormat),
  currentDate: moment().format(dateFormat),
  activeClass: -1,
  upcomingClassesData: [],
  topPerformerData: [],
};

const teacherDashboardSlice = createSlice({
  name: "teacherDashboard",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUpcomingLoading(state, action) {
      state.upcomingLoading = action.payload;
    },
    setCurrentWeekDay(state, action) {
      state.currentWeekDay = action.payload;
    },
    setCurrentDate(state, action) {
      state.currentDate = action.payload;
    },
    setActiveClass(state, action) {
      state.activeClass = action.payload;
    },
    setUpcomingClasses(state, action) {
      state.upcomingClassesData = action.payload;
    },
    setStudentPerformance(state, action) {
      state.topPerformerData = action.payload;
    },
  },
});

export const {
  setLoading,
  setUpcomingLoading,
  setCurrentWeekDay,
  setCurrentDate,
  setActiveClass,
  setUpcomingClasses,
  setStudentPerformance,
} = teacherDashboardSlice.actions;

export default teacherDashboardSlice.reducer;
