import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { getAllThemes, updateTheme } from "../../store/services/theme";
import { updateThemeState } from "../../store/slices/themeSlice";
import { useDispatch } from "react-redux";

const Platform = () => {
    const dispatch = useDispatch()
  const [selected, setSelected] = useState(null);
  const [theme, setTheme] = useState([]);
  const [loading,setLoading] = useState(false);


  const checked = (item: any) => {
    setSelected(item);
  };

  const gettheme = async () => {
    const response = await getAllThemes();
    setTheme(response?.themes);
  };

  useEffect(() => {
    gettheme();
  }, []);

  useEffect(() => {
    if (theme?.length > 0) {
      const tempSelectedTheme = theme?.filter(
        (item: any) => item?.active === 1
      )?.[0];
      setSelected(tempSelectedTheme);
    }
  }, [theme]);

  const updateHandller = async () => {
    try {
      setLoading(true);
      const resp = await updateTheme(selected?.["theme_id"]);
      if(resp){
  
          dispatch(updateThemeState(selected?.["theme_id"]))
          gettheme()
          setLoading(false);
      }else{
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error",error);
    }
  };

  return (
    <div className="yourprofilemain">
      <div className="profileAndButtons">
        <div className="yourprofile">
          <h2>Platform Color</h2>
          <p>Set your platform palette here.</p>
        </div>
        <div className="buttons">
          <Button disabled={loading}>Cancel</Button>
          <Button disabled={loading} loading={loading} onClick={updateHandller}>Update palette</Button>
        </div>
      </div>
      <hr />

      {theme?.map((item: any) => {
        return (
          <>
            <div className="flatformColor " key={item?.["theme-id"]}>
              <div
                className="colornameAndChecks "
                onClick={() => checked(item)}
              >
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={selected?.["theme-name"] === item["theme-name"]}
                  readOnly
                />
                <h2 className="name">{item["theme-name"]}</h2>
              </div>
              <div className="colorContainer">
                <div
                  className=" colorall greencolor1"
                  style={{ backgroundColor: item["secondary-color"] }}
                />
                <div
                  className=" colorall greencolor2"
                  style={{ backgroundColor: item["primary-color"] }}
                />
                <div
                  className=" colorall greencolor3"
                  style={{ backgroundColor: item["color-1"] }}
                />
                <div
                  className=" colorall greencolor4"
                  style={{ backgroundColor: item["color-2"] }}
                />
              </div>
            </div>
            <hr />
          </>
        );
      })}

      {/* <div className="flatformColor">
        <div
          className="colornameAndChecks "
          onClick={() => checked("Black & Light Green")}
        >
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selected === "Black & Light Green"}
            readOnly
          />

          <h2 className="name">Black & Light Green</h2>
        </div>
        <div className="colorContainer">
          <div className=" colorall blaciandlight1" />
          <div className=" colorall blaciandlight2" />
          <div className=" colorall blaciandlight3" />
          <div className=" colorall blaciandlight4" />
        </div>
      </div>
      <hr />
      <div className="flatformColor">
        <div className="colornameAndChecks" onClick={() => checked("Blue")}>
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selected === "Blue"}
            readOnly
          />

          <h2 className="name">Blue</h2>
        </div>
        <div className="colorContainer">
          <div className=" colorall Bluecolor1" />
          <div className=" colorall Bluecolor2" />
          <div className=" colorall Bluecolor3" />
          <div className=" colorall Bluecolor4" />
        </div>
      </div>
      <hr />
      <div className="flatformColor">
        <div className="colornameAndChecks " onClick={() => checked("Purple")}>
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selected === "Purple"}
            readOnly
          />

          <h2 className="name">Purple</h2>
        </div>
        <div className="colorContainer">
          <div className=" colorall purpul1" />
          <div className=" colorall purpul2" />
          <div className=" colorall purpul3" />
          <div className=" colorall purpul4" />
        </div>
      </div> */}
      <div className="buttonsmobile">
        <Button disabled={loading} loading={loading} onClick={updateHandller}>Update</Button>
        <Button disabled={loading}>Cancel</Button>
      </div>
    </div>
  );
};

export default Platform;
