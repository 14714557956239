import { useEffect, useState } from "react";
import NoteModal from "../Modals/studentBrief/NoteModal";
import CategoryModal from "../Modals/studentBrief/CategoryModal";
import { Button, Col, Collapse, notification, Row } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { BsEmojiLaughing } from "react-icons/bs";
import { MdAddBox, MdOutlineEdit } from "react-icons/md";
import {
  deleteStudentNote,
  getAllStudentNote,
  getStudent,
} from "../../store/services/student";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import Loading from "../Loading/Loading";

interface StudentBriefProps {
  studentData: any;
}

interface StudentCatPoints {
  studentId: string;
}

interface AboutTheStudent {
  categoryName: string;
  studentCatPoints?: StudentCatPoints[];
  studentId: number;
  categoryId: number;
}

interface NoteData {
  studentId: number | null;
  category: any;
  note: any;
  isNoteModalOpen: boolean;
  isNoteModalEditMode: boolean;
}

function StudentBrief({ studentData }: StudentBriefProps) {
  const allNotes = useAppSelector((state) => state?.student?.allNotes);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [noteData, setNoteData] = useState<NoteData>({
    studentId: null,
    category: null,
    note: null,
    isNoteModalOpen: false,
    isNoteModalEditMode: false,
  });
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [aboutStudent, setAboutStudent] = useState<AboutTheStudent[] | null>(
    null
  );
  const { user } = useAppSelector((state) => state.auth);

  const getAllnote = async () => {
    try {
      await getAllStudentNote(studentData.id);
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteNote = async (noteId: number) => {
    try {
      const res = await deleteStudentNote(noteId);
      await getAllnote();
      res &&
        notification.success({
          message: "Note deleted successfully",
        });
    } catch (e) {
      console.log(e);
    }
  };

  const items =
    aboutStudent &&
    aboutStudent.map((item, index) => ({
      key: `${index}`,
      label: (
        <span>
          {item?.categoryName === "character" ? (
            <IoPersonOutline className="personOutlined" />
          ) : (
            <BsEmojiLaughing className="emojiLaughing" />
          )}
          {item?.categoryName}
          <MdAddBox
            className="add-btn"
            onClick={(e: any) => {
              e.stopPropagation();
              setNoteData({
                ...noteData,
                studentId: studentData.id,
                category: { ...item },
                isNoteModalOpen: true,
              });
            }}
          />
        </span>
      ),
      children: (
        <div>
          {allNotes
            ?.filter((note: any) => note.categoryId === item.categoryId)
            ?.map((note: any) => (
              <Row
                key={note.noteId}
                justify="space-between"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Col lg={22}>
                  <div>
                    <h6>{moment(note.createdAt).format("DD MMMM, YYYY")}</h6>
                    <p className="content-one">{note.note}</p>
                  </div>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col lg={12}>
                      <Button
                        className="collapseEditBtn"
                        onClick={() => {
                          setNoteData({
                            ...noteData,
                            studentId: studentData.id,
                            category: { ...item },
                            note: { ...note },
                            isNoteModalOpen: true,
                            isNoteModalEditMode: true,
                          });
                        }}
                      >
                        <MdOutlineEdit />
                      </Button>
                    </Col>
                    <Col lg={12}>
                      <Button
                        className="collapseDeleteBtn"
                        onClick={() => deleteNote(note.noteId)}
                      >
                        <RiDeleteBin6Line />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
        </div>
      ),
    }));

  const fetchStudents = async () => {
    const payload = {
      studentId: studentData?.id,
    };
    const data: any = await getStudent(payload);
      setAboutStudent(data.newStudentCategory as AboutTheStudent[]);
  };

  useEffect(() => {
    if(studentData?.id){
      fetchStudents();
      getAllnote();
    }
  }, [categoryAdded]);

  const handleCategoryAdded = () => {
    setCategoryAdded((prev) => !prev); // Toggle the state to rerun the effect
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  return (
    <>
      <Row justify={"space-between"}>
        <Col lg={21}>
          <h1>Student Brief</h1>
        </Col>
        {user?.role === "teacher" ? (
          " "
        ) : (
          <Col lg={3}>
            <button
              onClick={() => setIsCategoryModalOpen(true)}
              className="addStdCat"
            >
              Add Category
            </button>
          </Col>
        )}
      </Row>
      {items ? (
        <Collapse
          className="project-collapse"
          defaultActiveKey={["0"]}
          onChange={onChange}
          items={items}
        />
      ) : (
        <>
          <br />
          <br />
          <Loading />
        </>
      )}
      <NoteModal
        visible={noteData.isNoteModalOpen}
        onCancel={() =>
          setNoteData({
            ...noteData,
            note: null,
            category: null,
            studentId: null,
            isNoteModalOpen: false,
            isNoteModalEditMode: false,
          })
        }
        data={noteData}
        editMode={noteData.isNoteModalEditMode}
      />
      <CategoryModal
        visible={isCategoryModalOpen}
        onCancel={() => setIsCategoryModalOpen(false)}
        onCategoryAdded={handleCategoryAdded}
      />
    </>
  );
}

export default StudentBrief;
