import { createSlice } from "@reduxjs/toolkit";
import { SettingState } from "../interfaces/settings";

const initialState: SettingState = {
  loading: false,
  settings: null,
  logo:"",
  favicon:""
};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSettings(state, action) {
      state.settings = action?.payload;
    },
    setLogo(state,action){
      state.logo = action.payload
    },
    setFavicon(state,action){
      state.favicon = action.payload
    }
  },
});

export const { setLoading,setLogo,setFavicon, setSettings } = settings.actions;

export default settings.reducer;
