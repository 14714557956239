// // src/store/slices/subject/subjectSlice.ts
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { Subject } from '../../interfaces/Class/Class';

// interface SubjectState {
//   subjects: Subject[];
//   loading: boolean;
// }

// const initialState: SubjectState = {
//   subjects: [],
//   loading: false,
// };

// const subjectSlice = createSlice({
//   name: 'subject',
//   initialState,
//   reducers: {
//     setSubjects(state, action: PayloadAction<Subject[]>) {
//       state.subjects = action.payload;
//     },
//     setLoadingSubjects(state, action: PayloadAction<boolean>) {
//       state.loading = action.payload;
//     },
//     addSubjects(state, action: PayloadAction<Subject>) {
//       state.subjects.push(action.payload);
//     },
//   },
// });

// export const { setSubjects, setLoadingSubjects, addSubjects } = subjectSlice.actions;

// export default subjectSlice.reducer;



// src/store/slices/subject/subjectSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subject } from '../../interfaces/Class/Class';

interface SubjectState {
  subjects: Subject[];
  loading: boolean;
  currentSubjectId: number | null; // Add currentSubjectId property
}

const initialState: SubjectState = {
  subjects: [],
  loading: false,
  currentSubjectId: null, // Initialize with null
};

const subjectSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    setSubjects(state, action: PayloadAction<Subject[]>) {
      state.subjects = action.payload;
    },
    setLoadingSubjects(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    addSubjects(state, action: PayloadAction<Subject>) {
      state.subjects.push(action.payload);
    },
    setCurrentSubjectId(state, action: PayloadAction<number>) {
      state.currentSubjectId = action.payload;
    },
    clearCurrentSubjectId(state) {
      state.currentSubjectId = null;
    },
  },
});

export const { setSubjects, setLoadingSubjects, addSubjects, setCurrentSubjectId, clearCurrentSubjectId } = subjectSlice.actions;

export default subjectSlice.reducer;

