import React, { useEffect, useState } from "react";
import "../../styles/pages/studentoverview.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { GoHome } from "react-icons/go";
import GHeader from "../../components/GeneralHeader/GHeader";
import { getAllSubjects } from "../../store/services/class";
import TeacherCard from "../../components/Cards/TeacherCard";
import SubjectStudentLevel from "../../components/studentOverview/subjectStudentLevel";
import StudentBrief from "../../components/studentOverview/StudentBrief";
import Overview from "../../components/studentOverview/Overview";
import img from "../../assets/attachment.png";
import Reports from "../Reports";
import Exam from "../../components/studentOverview/Exam";

const ClassesStudent: React.FC = () => {
  const [activeItem, setActiveItem] = useState({
    subjectId: null,
    activeTab: "Student Brief",
  });
  const location = useLocation();
  const studentData = location?.state?.studentData;

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!studentData?.id) {
      navigate("/students");
    }
  }, [dispatch]);

  const list = studentData?.subjects && [
    {
      subjectId: null,
      subjectName: "Overview",
    },
    ...studentData?.subjects?.map((sub: any) => {
      return { subjectId: sub.subjectId, subjectName: sub.subjectName };
    }),
    {
      subjectId: null,
      subjectName: "Student Brief",
    },
    {
      subjectId: null,
      subjectName: "Exams",
    },
    {
      subjectId: null,
      subjectName: "Reports",
    },
  ];

  return (
    <>
      <div className="classesStd">
        <div className="_breadcrumb">
          <GoHome
            size={20}
            onClick={() => navigate("/curriculum")}
            style={{ cursor: "pointer" }}
          />{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          <span onClick={() => navigate(-1)}>Students</span>{" "}
          <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
          {studentData && (
            <>
              <span style={{ cursor: "default" }}>
                {studentData?.class} / {studentData?.name}{" "}
                {studentData?.Lastname}
              </span>{" "}
              <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
            </>
          )}
          <p>Student Overview</p>
        </div>
        <GHeader
          heading="Student Overview"
          headingText="Review and manage the classes"
        />
        <div className="Custom_tabs_containers">
          {list?.map((tab: any, index: any) => {
            return (
              <div
                key={index}
                className={`${
                  activeItem?.activeTab === tab.subjectName
                    ? "custom_active_tab"
                    : "custom_tab"
                } custom-tab-all `}
                onClick={() =>
                  setActiveItem({
                    subjectId: tab.subjectId,
                    activeTab: tab.subjectName,
                  })
                }
              >
                {tab.subjectName}
              </div>
            );
          })}
        </div>
        <br />
        <br />
        {studentData && (
          <TeacherCard
            data={{
              firstName: studentData?.name,
              LastName: studentData?.Lastname,
              class: studentData?.class,
              imageUrl: studentData?.avatar ? studentData?.avatar : img,
            }}
          />
        )}{" "}
        <br />
        <br />
        {activeItem.activeTab === "Student Brief" ? (
          <>
            <StudentBrief studentData={studentData} />
          </>
        ) : activeItem.activeTab === "Overview" ? (
          <>
            <Overview studentData={studentData} />
          </>
        ) : activeItem.activeTab === "Exams" ? (
          <>
            <Exam studentData={studentData} />
          </>
        ) : activeItem.activeTab === "Reports" ? (
          <>
            <Reports studentData={studentData} />
          </>
        ) : (
          <SubjectStudentLevel
            studentData={studentData}
            activeSubjectId={activeItem?.subjectId}
          />
        )}
      </div>
    </>
  );
};

export default ClassesStudent;
