import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createModule } from '../../interfaces/Curriculum/Curriculum';


interface ModuleState {
    modules: createModule[];
    loading: boolean;
    currentLevelId: number | null; // Assuming you have a field for the current subject ID
}
  
const initialState: ModuleState = {
    modules: [],
    loading: false,
    currentLevelId: null, // Assuming you have a field for the current subject ID
};
  
const moduleSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
      addModule(state, action: PayloadAction<createModule>) {
        state.modules.push(action.payload);
      },
      
      setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload;
      },
    },
});

export const { setLoading , addModule} = moduleSlice.actions;

export default moduleSlice.reducer;


