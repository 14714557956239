import React, { useEffect, useState, useRef } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/support.css";
import avatar from "../../assets/moulviAvatar.jpeg";
import NotAccess from "../NotAccess";
import { Input, message, Select, Spin } from "antd";
import { IoIosSend } from "react-icons/io";
import { FaRocketchat } from "react-icons/fa";
import TeacherSupports from "./TeacherSupports";
import { useAppSelector } from "../../store/hooks";
import { getAllTeachersList } from "../../store/services/teacher";
import axios from "axios";
import {
  createChatRoom,
  getallMessage,
  getAllRooms,
  getUnreadMessages,
  markReadMessage,
  sendMessage,
} from "../../store/services/supports";
import Loading from "../../components/Loading/Loading";
import { CgAttachment } from "react-icons/cg";
import store from "../../store";
import { setActiveUserChat } from "../../store/slices/support/suportSlice";
import FileUploadModal from "../../components/Modals/supports/FileUploadModal";
import {
  setMessages,
  setUnreadmesg,
} from "../../store/slices/support/suportSlice";
import { useDispatch } from "react-redux";
import { socket } from "../../config/socket";
import SearchableDropdown from "../../components/SearchableDropdown";

interface SelectUser {
  name: string;
  img: string;
  id: number;
  message: number;
  firstName: string;
  LastName: string;
}


const AdminSupport = () => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [fetchValue, setFetchValue] = useState([]);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(0);
  // const [allRooms, setAllRooms] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [loadFirstTime, setLoadFirstTime] = useState(false);
  const [load, setLoad] = useState(false);
  const [FirstTimeLoad, setFirstTimeLoad] = useState(false);
  const [loadedMessage, setLoadedMessage] = useState(true);
  const [messageContent, setMessageContent] = useState("");
  const dispatch = useDispatch();
  const { allTeachers, loadingTeachers } = useAppSelector(
    (state) => state.teacher
  );

  const {
    loading,
    messageloading,
    chatrooms,
    messages,
    unReadmesg,
    activeUserChat,
    singlMessage,
  } = useAppSelector((state) => state.support);

  const { user } = useAppSelector((state) => state.auth);
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const payload = {
          page: pages,
          perPage: 10,
          search: "",
        };
        const res = await getAllTeachersList(payload);
        setCount(res.count);
      } catch (error) {
        console.log("error", error);
      } finally {
      }
    };
    fetchTeachers();
    getAllRooms();
  }, []);

  const setActiveChatUsr = async (item: any) => {
    store.dispatch(setActiveUserChat(item));
  };


  const GetMessage = async (chatroomId: any) => {
    const res = await getallMessage(chatroomId, page);
    console.log(">>>", res)
  
    if (res.data.length === 0 ) {
      setLoadedMessage(false);
    }
    if (res) {
      setLoadFirstTime(true);
      await markReadMessage(chatroomId);
      await getUnreadMessages();
    }
  };
  useEffect(() => {
    if (activeUserChat && loadedMessage) {
      GetMessage(activeUserChat?.id);
    }
  }, [activeUserChat, page]);

  const onChange = async (e: any) => {
    const value: any = fetchValue.find((item: any) => {
      return e === item.firstName;
    });
    console.log(fetchValue);
    console.log(e);

    const res = await createChatRoom({
      userId: value.id,
    });
    if (res) {
      await getAllRooms();
    }
  };

  const sendMessagehandle = async (roomId: any) => {
    setLoad(true);
    if (!messageContent.trim()) {
      setLoad(false);
      return;
    }

    const payload = {
      sender: user?.id,
      content: messageContent,
    };
    const chatMessage = {
      sender_id: user?.id,
      content: messageContent,
      id: Math.random().toString(36).substr(2, 9) + Date.now(),
    };
    const allMessage = [...messages, chatMessage];

    store.dispatch(setMessages(allMessage));

    try {
      const res = await sendMessage(roomId, payload);
      if (res) {
        // socket.emit('sendMessage', { roomId, payload })
        const newMessage = {
          id: res.id || Date.now(),
          sender: user?.id,
          content: messageContent,
        };
        setMessageContent("");
        setLoad(false);
        // GetMessage(activeUserChat?.id);
        setActiveChatUsr(activeUserChat);
        // store.dispatch(setMessages((prevMessages:any) => [...prevMessages, newMessage]));

        // store.dispatch(setMessages([...messages, newMessage]));
        // Clear input field
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setLoad(false);
    }
  };

  const isVideo = (fileUrl: any) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = fileUrl.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  // useEffect(() => {
  //   if (messageContainerRef?.current) {
  //     const chatContainer = messageContainerRef?.current;
  //     const handleScroll = () => {
  //       // console.log("height", chatContainer.scrollTop);
  //       if (chatContainer.scrollTop === 0) {
  //         setPage((prevPage) => prevPage + 1);
  //         console.log("perpageeeeeeeee===>", page);
  //       }
  //     };

  //     chatContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       chatContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [messageContainerRef, messages]);

  useEffect(() => {
    if (messageContainerRef?.current) {
      console.log("height", messageContainerRef?.current);
      const chatContainer = messageContainerRef?.current;
      const handleScroll = () => {
        console.log("height", chatContainer.scrollHeight);
        if (chatContainer.scrollTop === 0) {
          const previousHeight = chatContainer.scrollHeight;

          setPage((prevPage) => prevPage + 1 );

          setTimeout(() => {
            const newHeight = chatContainer.scrollHeight;
            chatContainer.scrollTop = newHeight - previousHeight;
          }, 300); 
        }
      };

      chatContainer.addEventListener("scroll", handleScroll);

      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageContainerRef, messages]);

  useEffect(() => {
    if (messageContainerRef?.current && loadFirstTime) {
      const chatContainer = messageContainerRef.current;

      const previousScrollHeight = chatContainer.scrollHeight;
      const previousScrollTop = chatContainer.scrollTop;

      const handleScrollAdjustment = () => {
        const newScrollHeight = chatContainer.scrollHeight;

        if (previousScrollTop < 400) {
          chatContainer.scrollTop = 400;
        } else {
          const scrollOffset = newScrollHeight - previousScrollHeight;
          chatContainer.scrollTop = previousScrollTop + scrollOffset;
        }
      };

      handleScrollAdjustment();
    }
  }, [messages]);

  //////////

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [loadFirstTime, singlMessage]);

  return checkRoutePermissions("support") ? (
    <div className="support_main">
      <div className="support_left">
        <div className="input-Search-support">
          <SearchableDropdown
            onChange={onChange}
            setFetchedOptions={setFetchValue}
            url="/teacher/get-all"
            mode={false}
            columns={["firstName"]}
            type="firstName"
            placeholder="Search by name"
          />
        </div>
        <div className="support_left_child">
          <div className="child_wraper">
            {loading ? (
              <Loading />
            ) : (
              chatrooms.length > 0 &&
              chatrooms.map((item: any) => {
                // return (
                //   <div key={item.id} className={`profile_support ${item.id === activeUserChat?.id ? "support_user_active" : ""}`}
                //     onClick={() => setActiveChatUsr(item)}
                //   >
                //     <img src={item.imageUrl ? item.img : avatar} className='image_support' alt="profile icon" />
                //     <div className='chat_parent'>
                //       <p className='chat_name'>{item.firstName} {item.LastName}</p>
                //       {
                //         !activeUserChat && unReadmesg?.length > 0 && unReadmesg.map((message: any) => {

                //           return message.chat_room_id === item.id && <p className='chat_messages'>{` ${message?.unread} Unread message`}</p>
                //         })
                //       }

                //     </div>
                //   </div>
                // )
                return (
                  <div
                    key={item.id}
                    className={`profile_support ${
                      item.id === activeUserChat?.id
                        ? "support_user_active"
                        : ""
                    }`}
                    onClick={() => {
                      store.dispatch(setMessages([]))
                      setActiveChatUsr(item);
                      setPage(1);
                      setLoadedMessage(true);
                    }}
                  >
                    <img
                      src={item.imageUr ? item.imageUr : avatar}
                      className="image_support"
                      alt="profile icon"
                    />
                    <div className="chat_parent">
                      {/* <h1>hello</h1> */}
                      <p className="chat_name">
                        {item.firstName} {item.LastName}
                      </p>

                      {unReadmesg?.length > 0 &&
                        unReadmesg.map((message: any) => {
                          return (
                            message.chat_room_id === item.id &&
                            activeUserChat?.id !== item.id && (
                              <p className="chat_messages">{`${message?.unread} Unread message(s)`}</p>
                            )
                          );
                        })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {activeUserChat ? (
        <>
          <div className="support_right">
            <div className="support_right_main">
              <div className="support_chat_profile">
                <img
                  src={activeUserChat.imageUr ? activeUserChat.imageUr : avatar}
                  alt="profile"
                />
                <p>{`${activeUserChat?.firstName ?? "Admin"} ${
                  activeUserChat?.LastName ?? ""
                }`}</p>
              </div>
              <div className="support_messages" ref={messageContainerRef}>
               {messageloading && <Loading />}

                {messages?.map((item: any, index) => (
                  <div key={item?.id || index}>
                   {user?.id == item?.sender_id ? (
                      <>
                        {item.content && (
                          <div className="message_parent message-right">
                            <div className="support_message_text_right">
                              <p>{item.content}</p>
                            </div>
                          </div>
                        )}
                        {item?.file && item?.file !== "null" && (
                          <div className="message_parent message-right">
                            <div
                              className="support_img_text_right support_img-left"
                              style={{ marginTop: item?.content ? "8px" : "" }}
                            >
                              {isVideo(item.file) ? (
                                <video width="300" height="240" controls>
                                  <source src={item.file} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div
                                className="image_hover"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  height: "auto",
                                  width: "50%",
                                  position: "relative",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  src={item.file}
                                  alt="message"
                                />
                                <a
                                  className="img_download_btn"
                                  style={{
                                    display: "none",
                                    justifyContent: "flex-end",
                                  }}
                                  href={item.file}
                                  download={item.file}
                                >
                                  Download
                                </a>
                              </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {item.content && (
                          <div className="message_parent">
                            <img
                              src={
                                activeUserChat.imageUr
                                  ? activeUserChat.imageUr
                                  : avatar
                              }
                              className="message_profile"
                              alt="profile"
                            />
                            <div className="support_message_text">
                              <p>{item.content}</p>
                            </div>
                          </div>
                        )}
                        {item?.file && item?.file !== "null" && (
                          <div className="message_parent">
                            <img
                              src={avatar}
                              className="message_profile"
                              alt="profile"
                            />
                            <div
                              className="support_img_text_right support_img_admin"
                              style={{ marginTop: item?.content ? "8px" : "" }}
                            >
                              {isVideo(item.file) ? (
                                <video width="300" height="240" controls>
                                  <source src={item.file} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div
                                className="image_hover"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  height: "auto",
                                  width: "50%",
                                  position: "relative",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  src={item.file}
                                  alt="message"
                                />
                                <a
                                  className="img_download_btn"
                                  style={{
                                    display: "none",
                                    justifyContent: "flex-start",
                                  }}
                                  href={item.file}
                                  download={item.file}
                                >
                                  Download
                                </a>
                              </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div className="send-message">
                <input
                  className="message_input"
                  placeholder="Write your message"
                  type="text"
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                />
                <CgAttachment onClick={showModal} />
                {load ? (
                  <Spin />
                ) : (
                  <IoIosSend
                    className="iosend"
                    onClick={() => sendMessagehandle(activeUserChat?.id)}
                  />
                )}
              </div>
            </div>
          </div>
          <FileUploadModal
            isModalOpen={isModalOpen}
            load={load}
            setLoad={setLoad}
            setIsModalOpen={setIsModalOpen}
            Page={page}
          />
        </>
      ) : (
        <div className="message_user_to_select">
          <FaRocketchat className="chat_user" />
          <p>Select A User To Chat</p>
        </div>
      )}
    </div>
  ) : (
    <NotAccess />
  );
};

export default AdminSupport;
