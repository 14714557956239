import { privateAPI } from "../../config/constants";

export const createAdmin = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/admin/add", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating admin:", error);
    //@ts-ignore
    notification.error({ message: error.message });
  }
};

export const getAllAdmins = async (data: {
  page?: number;
  perPage?: number;
  search?: string;
  status?:string
}) => {
  try {
    const res= await privateAPI.post("/admin/get-all", data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("error get admins", error);
  }
};

export const updateAdmin = async (formData: FormData) => {
  try {
    const response = await privateAPI.post("/admin/update", formData);
    return response.data;
  } catch (error) {
    console.error("Error updating admin:", error);
    //@ts-ignore
    notification.error({ message: error.message });
  }
};

export const addAdminArchive = async (id:any) => {
  try {
    const response = await privateAPI.post("/admin/archive", id);
    if (response) {
      return response;
    }
  } catch (error) {
    console.error("Error archive admin:", error);
    throw error;
  }
};

export const unArchiveAdmin = async(id:any)=>{
  try {
    const response = await privateAPI.post("/admin/unarchive", id);
    if (response) {
      return response;
    }
  } catch (error) {
    console.error("Error unarchive admin:", error);
    throw error;
  }
}