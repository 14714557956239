import { createSlice } from '@reduxjs/toolkit';
import { subjectListByClassState } from '../../interfaces/Subject/Subject';

const initialState: subjectListByClassState = {
  loadingSubjectList: false,
  subjectListByClass: [],
};

const subjectListByClassSlice = createSlice({
  name: 'subjectListByClass',
  initialState,
  reducers: {
    setLoadingSubjectList(state, action) {
      state.loadingSubjectList = action.payload;
    },
    setSubjectListByClass(state, action) {
      state.subjectListByClass = action.payload;
    },
  },
});

export const { setLoadingSubjectList, setSubjectListByClass } = subjectListByClassSlice.actions;

export default subjectListByClassSlice.reducer;

