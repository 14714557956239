import { GoDotFill } from "react-icons/go";
import SearchStudentModal from "../../../components/Modals/SearchStudentModal";
import { firstLetterCap } from "../../../utils";
import React from "react";
import { LuInfo } from "react-icons/lu";

interface Props {
  student: any;
  search?: boolean;
  underperformance?: any;
  overperformance?: any;
  type?: string;
  bool?:boolean
}

const SingleStudentCard: React.FC<Props> = ({
  student,
  search,
  underperformance,
  overperformance,
  type,
  bool
}) => {
  return (
    <div className="_singleStudentCard">
      <div className="_imageBox">
        <img
          src={
            student?.imageUrl
              ? student?.imageUrl
              : require("../../../assets/user.png")
          }
          alt={"Avatar"}
        />
      </div>
      <div className="_details">
        <h2>
          {firstLetterCap(student?.firstName)}{" "}
          {firstLetterCap(student?.lastName)}
        </h2>
        <div>
          <p>{student?.className || "-"}</p>{" "}
          <GoDotFill color={"rgba(189, 229, 86, 1)"} />
          {underperformance && (
            <div className="_underperformance">
              <LuInfo style={{ marginBottom: "-2px" }} /> Underperfomance
            </div>
          )}
          {overperformance && (
            <div className="_overperformance">
              <LuInfo style={{ marginBottom: "-2px" }} /> Overperfomance
            </div>
          )}
        </div>
      </div>
    
      {  bool !== search && <SearchStudentModal type={type} />}
    </div>
  );
};

export default SingleStudentCard;
