import { Spin, Table } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import React from "react";

interface targetTo {
  report?: boolean;
  loading?: boolean;
  nextLevel?: any;
  tableData?: any;
}

const TargetToReach: React.FC<targetTo> = ({ report ,loading ,nextLevel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        scroll={{ x: 600 }}
        pagination={false}
        className="custom_table_design"
        loading={{spinning: loading, indicator: <Spin className="student_custom_spinner" size="large"/>}}
      >
        <ColumnGroup
          title={
            <span className="custom-column-group-title">{report ? `Past Unmet Targets ${tableData?.length}`: `Targets to reach target ${nextLevel ? nextLevel : 'level 2'}`}</span>
          }
        >
          <Column
            title=""
            dataIndex="name"
            key="name"
            render={(text) => <div style={{ padding: "16px" }}>{text}</div>}
          />
          <Column
            title=""
            dataIndex="curriculum"
            key="curriculum"
            render={(text, record) => (
              <div style={{ padding: "16px" }}>{text}</div>
            )}
          />
          <Column
            title=""
            dataIndex="sub"
            key="sub"
            render={(text, record) => (
              <div style={{ padding: "16px" }}>{text}</div>
            )}
          />
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default TargetToReach;
