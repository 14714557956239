import { Spin } from 'antd'
import React from 'react'

const Loading = ({large}:any) => {
  return (
    <div className='loading-parent'>
        {large?<Spin  size="large"/> :<Spin  />}
      
    </div>
  )
}

export default Loading
