import { Button, Col, Form, Row, Radio } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CiCircleCheck } from "react-icons/ci";
import { sendSignupLinkStudent, sendSignupLinkTeacher } from '../store/services/signup'; // Adjust the import path as needed
// @ts-ignore
import screenimg from '../assets/screenimg.png';
// @ts-ignore

interface SignupLinkValues {
  email: string;
  role: string;
}

const SendSignup: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [load,setLoad] = useState(true);
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [form] = Form.useForm();
  const location = useLocation();

  

  const onFinish = async (values: SignupLinkValues) => {
    console.log("Form submitted with values:", values);
    setLoading(true);
    setError(null);
    try {
      let result;
      if (values.role === 'student') {
        result = await sendSignupLinkStudent(values.email);
      } else if (values.role === 'teacher') {
        result = await sendSignupLinkTeacher(values.email, CiCircleCheck);
      }
      setResponse(JSON.stringify(result)); // Convert result to string
      form.resetFields();
    } catch (err) {
      setError('Failed to send signup link');
    } finally {
      setLoading(false);
    }
  };

  return (

    <div >
      <Row>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className='left signupCol-1' >
            <div className='signupform'>
              <h1 className='signupheading'>Send Signup Link</h1>
              <p className='pl' style={{ left: '-13px' }}>Welcome back please enter your details</p>
              <Form
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                className='signUpForm'
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                  className='labels'
                >
                  <input className='input' placeholder='Enter your Email' />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: 'Please select a role' }]}
                  className='labels'
                >
                  <Radio.Group className='custom-radio-group' defaultValue={location.state === 'teacher'?"teacher":location.state === 'student'?'student':null}>
                    <Radio value="student">Student</Radio>
                    <Radio value="teacher">Teacher</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className='signup_btn' disabled={loading} loading={loading} >
                    Send Link
                  </Button>
                </Form.Item>
              </Form>
              {response && (
                <div>
                  <p>Success: Yes</p>
                  <p>Message: {response}</p>
                </div>
              )}
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
          </div>
        </Col>
        <Col lg={12} md={0}>
          <div className='right'>
            <img src={screenimg} alt="" className='screenimg' />
          </div>
        </Col>
      </Row>
    </div>

  );
};

export default SendSignup;