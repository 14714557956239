import { Button, Checkbox, Col, Form, Row, Space } from 'antd'
import React from 'react'

// @ts-ignore
import screenimg from '../assets/screenimg.png'


// @ts-ignore
import progresstracker from '../assets/Blogo.png'


interface signupformValues{
  name:string,
  email:string,
  password:string,
}

const LoginStudent = () => {
  return (
    <div className='main'>
      <Row>
        <Col lg={12}>
          <div className='left' style={{display: 'flex', flexDirection: 'column'}}>
            <img src={progresstracker} style={{width:'150px', height:"auto"}} />
            <div className='signupform'>
              <h1 className='signupheading'>Log In</h1>
              <p className='pl' style={{left:'-13px'}}>Welcome back please enter your details</p>
            <Form name="validateOnly" layout="vertical" autoComplete="off">
              
              <Form.Item name="Email" label="Email" rules={[{ required: true }]} className='labels'>
                <input className='input' placeholder='Enter your Email' />
              </Form.Item>
              <Form.Item name="Password" label="Password" rules={[{ required: true }]} className='labels'>
              <input className='input' placeholder='Enter your Password' />
              </Form.Item>
              <Form.Item>
                    <Checkbox >Remembar for 30 Days</Checkbox>
                    <label htmlFor="" className='labels' style={{position:'relative', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>Forgot password</label>
              </Form.Item>
              <Form.Item>
                <Space>
                  {/* <SubmitButton form={form}>Submit</SubmitButton> */}
                  <Button className='button'>Log In</Button>
                </Space>
              </Form.Item>
              
            </Form>
             
            {/* <p>Dont have an account?<strong>SignUp</strong></p> */}

            </div>
          </div>
        </Col>

        
        <Col lg={12}>
          <div className='right'>
            
                <img src={screenimg} alt="" className='screenimg'/>
            
           </div>
        </Col>
        
      </Row>

    </div>
  )
}

export default LoginStudent