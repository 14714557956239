// import { Outlet } from 'react-router-dom'
// import Layout from '../components/layout/index'
// // import React from 'react'

// const PrivateWrapper = () => {
//   // const isAuthenticated = localStorage.getItem('token')

//   // if (isAuthenticated) {
//   //   return <Navigate to='/login' />
//   // } else {
//     return (
//       <Layout>
//           <Outlet />
//         </Layout>
//     )
//   // } 
// }

// export default PrivateWrapper

import { Outlet, Navigate } from 'react-router-dom';
import Layout from '../components/layout/index';
import { jwtDecode } from 'jwt-decode';

const PrivateWrapper = () => {
  let isAuthenticated = localStorage.getItem('token');
  if(isAuthenticated){
    const decodedToken = jwtDecode(isAuthenticated);
    if (decodedToken.exp) {
      const isExpired = Date.now() >= decodedToken.exp * 1000;
      if (isExpired) {
        localStorage.removeItem('token');
        isAuthenticated = null;
      }
    }
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  } else {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
}

export default PrivateWrapper;
