import { notification } from "antd";
import { privateAPI } from "../config/constants";
import { setLoadingFalse, setLoadingTrue, setStudentPlanner } from "./slices/studentPlannerDetails/studentPlannerDetails";
import store from ".";
import { setLoading } from "./slices/settingSlice";

export const getPlannerDetails = async(data:{studentId:any,date:string})=>{
    store.dispatch(setLoadingTrue());
    try {
        const res = await privateAPI.post("/student-planner/get",data);
        if(res.status ===200){
            store.dispatch(setStudentPlanner(res.data.planner)); 
            return res;
        }else{
            notification.error({message:res?.data?.message});
        }
    } catch (error) {
        console.log("error 111",error);
    }
    finally{
        store.dispatch(setLoadingFalse());
    }
}

export const createStudentPlanner = async (data:any)=>{
    try {
        const res = await privateAPI.post("/student-planner/create",data);
        if(res.status === 200){
            notification.success({message:res?.data?.message});
            return res;
        }else{
            notification.error({message:res?.data.message});
        }
    } catch (error) {
        console.log("error",error);
    }
}

export const getAllSubjects = async (data:any)=>{
    try {
        const res = await privateAPI.post("/subject/get-subjects-from-class",data);
        if(res.status ===200){
            return res;
        }
    } catch (error) {
        console.log("error subjects",error);
    }
}