import {Button, Row, Col } from 'antd';
import React, {  useState } from 'react';
import { useNavigate } from "react-router-dom";
import ArrowLeftOutlined  from '@ant-design/icons'

// @ts-ignore
import screenimg from '../assets/screenimg.png'

// @ts-ignore
import progresstracker from '../assets/Blogo.png'
import LoginHeader from '../components/LoginHeader/LoginHeader';
import { FaArrowLeft } from 'react-icons/fa';

interface LoginFormValues {
  email: string;
  
}

const Success: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: LoginFormValues) => {
    
    setLoading(true);
    navigate('/login')
    setLoading(false);
  };

  return (
    <div className="credentials-page">
      <LoginHeader />
      <Row>
        <Col lg={12}>
        <div className='tank'>
        <div className='auth-form'>
            <div className='signupform'>
              <h1>Success</h1>
              <p >Your password was successfully reset.</p>
 
                <Button className='submit-btn'  loading={loading} onClick={()=> navigate('/login')}>Ok</Button>
                <p
                onClick={() => navigate('/login')}
                className='back-to-login'
              >
                <FaArrowLeft />
                Back to login
              </p>
                

            </div>
          </div>
          </div>
        </Col>

        
        <Col lg={12}>
          <div className='right'>
            
                <img src={screenimg} alt="" className='screenimg'/>
            
           </div>
        </Col>
        
      </Row>
    </div>
  )
};

export default Success;
