import { notification } from "antd";
import { attachToken, privateAPI, publicAPI } from "../../config/constants";
import {
  createCurriculum,
  getCurriculum,
  createLevel,
  getCurriculumPath,
  createModule,
  updateLevels,
  updateModule,
  deletecurriculum
} from "../interfaces/Curriculum/Curriculum";
// import {createLevel} from '../interfaces/Curriculum/Curriculum';
import store from "..";
import {
  addCurriculum,
  setCurriculum,
} from "../slices/Curriculum/curriculumSlice";
import { addLevel, setLearningPath } from "../slices/Levels/levelsSlice";
import { addModule, setLoading } from "../slices/Modules/moduleSlice";

export const createCurriculumApi = async (payload: createCurriculum) => {
  try {
    const response = await privateAPI.post("/curriculum/create", payload);
    if (response) {
      store.dispatch(addCurriculum(response.data));
      notification.success({ message: "Curriculum created successfully" });
    }

    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getCurriculumApi = async () => {
  try {
    const res = await privateAPI.get("/curriculum/get-all");
    store.dispatch(setCurriculum(res.data));
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const updateCurriculum = async (payload: createCurriculum) => {
  try {
    const response = await privateAPI.post("/curriculum/update", payload);
    if (response) {
      store.dispatch(addCurriculum(response.data));
      notification.success({ message: "Curriculum updated successfully" });
    }
    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};
export const deleteCurriculum = async (payload: deletecurriculum) => {
  try {
    const response = await privateAPI.post("/curriculum/delete", payload);
    if (response) {
      // store.dispatch(addCurriculum(response.data));
      notification.success({ message: "Delete Maqaasid " });
    }
    return response.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const createLevelapi = async (payload: createLevel) => {
  try {
    const res = await privateAPI.post("/level/create", payload);
    if (res.status === 200) {
      store.dispatch(addLevel(res.data));
      notification.success({ message: "Level Add successfully" });
      return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getLearningapi = async (payload: getCurriculumPath) => {
  try {
    const res = await publicAPI.post("/curriculum/get-learning-path", payload);
    if (res) {
      store.dispatch(setLearningPath(res.data));
    }
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const createModuleapi = async (payload: createModule) => {
  try {
    const res = await privateAPI.post("/module/create", payload);
    if (res) {
      store.dispatch(addModule(res.data));
      notification.success({ message: "Module added successfully" });
    }
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const createTarget = async (payload: any) => {
  try {
    const res = await privateAPI.post("/lesson/create", payload);
    if (res) {
     return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const updateTarget = async (payload: any) => {
  try {
    const res = await privateAPI.post("/lesson/update", payload);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getSingleLesson = async (payload: any) => {
  try {
    const res = await privateAPI.post("/lesson/get-single", payload);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getLearnByTarget = async (payload: getCurriculumPath) => {
  try {
    const res = await publicAPI.post("/curriculum/get-learning-by-target", payload);
    if (res) {
      store.dispatch(setLearningPath(res.data));
    }
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const updateLevelApi = async (payload: updateLevels) => {
  try {
    const res = await privateAPI.post("/level/update", payload);
    if (res) {
      notification.success({ message: "Level updated successfully" });
      return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};
export const updateModuleApi = async (payload: updateModule) => {
  try {
    const res = await privateAPI.post("/module/update", payload);
    if (res) {
      notification.success({ message: "Module updated successfully" });
      return res.data;
    }
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};
// ARCHIVE
export const levelArchive = async (data: any) => {
  try {
  const res =  await privateAPI.post("/level/archive", data);
  if(res) {
    notification.success({ message: res.data.message });
    return res
  }
  } catch (error:any) {
    console.error("Error level archive:", error);
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};
export const moduleArchive = async (data: any) => {
  try {
  const res =  await privateAPI.post("/module/archive", data);
  return res
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
export const lessonArchive = async (data: any) => {
  try {
   const res = await privateAPI.post("/lesson/archive", data);
   return res
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
// UNARCHIVE

export const levelUnArchive = async (data: any) => {
  try {
  const res =  await privateAPI.post("/level/unarchive", data);
  return res
  } catch (error) {
    console.error("Error level archive:", error);
    throw error;
  }
};
export const moduleUnArchive = async (data: any) => {
  try {
   const res =  await privateAPI.post("/module/unarchive", data);
   return res
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
export const lessonUnArchive = async (data: any) => {
  try {
  const res =  await privateAPI.post("/lesson/unarchive", data);
    return res
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
