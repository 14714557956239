import avatarStd from "../../assets/avatar_student.png";

interface propsTypes {
  image: string;
  name: string;
  classIndex: string | number;
}

const StudentProfile = ({image ,name, classIndex}:propsTypes) => {
  return (
    <div className="report_card_student">
      <img src={image} alt="avatarStd icon" />
      <div className="std_img_text">
        <div className="std_text_h1">{name}</div>
        <div className="std_text_p">Class {classIndex}</div>
      </div>
    </div>
  );
}

export default StudentProfile