import moment from "moment";
import React from "react";
import { GoDotFill } from "react-icons/go";

interface Props {
  data: any;
  index: number;
}
const TargetCard: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className="_targetCard"
      style={{ borderTop: index === 0 ? "1px solid transparent" : "" }}
    >
      <div className="_iconBox">
        <img src={require("../../assets/tracker.png")} alt={"Icon"} />
      </div>
      <div className="_content">
        <p className="_lessonText">{data?.lessonName}</p>
        <div className="_rightContent">
          <div>
            <img src={require("../../assets/calender.png")} alt="Icon" />
            <p>{moment(data?.createdAt).format("MMM DD, YYYY")}</p>
          </div>
          <GoDotFill color="var(--primary-color)" />
          <p>{data?.subjectName}</p>
        </div>
      </div>
    </div>
  );
};

export default TargetCard;
