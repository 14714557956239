// import { Layout, Switch } from 'antd'

// import { MdOutlineDarkMode, MdDarkMode } from 'react-icons/md'
// import { useDispatch, useSelector } from 'react-redux'

// import { toggleTheme } from '../redux'
// import MenuDrawer from './MenuDrawer'
// import {ReduxStoreI} from '../types/interface/redux'

// const { Content } = Layout

// const MobileLayout = ({ active, children }:{children:any, active?:string }) => {
//   const dispatch = useDispatch()
//   const theme = useSelector((state:ReduxStoreI) => {return state.theme.theme})

//   return (
//     <Layout className='m-layout'>
//       <div className='mobile-header'>
//         <MenuDrawer active={active} />
//         <Switch
//           className='themeSwitch'
//           defaultChecked={theme !== 'light'}
//           checkedChildren={<MdDarkMode style={{ fontSize: '20px', marginRight: '5px' }} />}
//           unCheckedChildren={<MdOutlineDarkMode style={{ fontSize: '20px', marginLeft: '5px' }} />}
//           onChange={() => {return dispatch(toggleTheme("sdsd"))}}
//         />
//       </div>
//       <Content className='m-children'>{children}</Content>
//     </Layout>
//   )
// }

// export default MobileLayout



import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SiderDrawer from "./SiderDrawer";
import Navbar from "../../components/Navbar/Navbar"

const { Content } = Layout;

const MobileLayout: React.FC = () => {
  return (
    <Layout className="m-layout">
      <SiderDrawer />
      {/* <Navbar/> */}
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MobileLayout;
