import { notification } from "antd";
import { privateAPI } from "../../config/constants"



export const getAllMonitoring = async(data:any)=>{
    try {
        const res = await privateAPI.post("/monitoring/get-all",data);
        if(res.status === 200){
            return res.data;
        }
    } catch (error) {
        console.log("error monitoring",error);
    }
}

export const deleteMonitoring = async (data:{monitoringId:number})=>{
    try {
        const res = await privateAPI.post("/monitoring/delete",data);
        if(res.status === 200){
            notification.success({message:"Deleted successfully"});
            return res;
        }else{
            notification.error({message:res.data.message});
        }
    } catch (error) {
        console.log("error delete monitoring",error);
    }
}


export const getStudent = async (data:any)=>{
    try {
        const res = await privateAPI.post("/student/search-student",data);
        if(res.status === 200){
            return res;
        }
    } catch (error) {
        console.log("error",error);
    }
}

export const addMonitoringstudent = async (data:any)=>{
    try {
        const res = await privateAPI.post("/monitoring/create",data);
        return res;
    } catch (error) {
        console.log("error",error);
    }
}

export const searchData = async (data:any)=>{
    try {
        const res = await privateAPI.post("/monitoring/search",data);
        if(res.status === 200){
            return res;
        }
    } catch (error) {
        console.log("error",error);
    }
}