import "./style.css";

const NotAccess = () => {
  return (
    <div className="_notAccessContainer">
      <h2>You don't have permission to access this page!</h2>
    </div>
  );
};

export default NotAccess;
