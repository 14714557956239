import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Correct import
import { useLocation } from "react-router-dom";
import { createTeacher, signupTeacher } from "../store/services/signup";
import { useNavigate } from "react-router-dom";
import "../styles/pages/signupteacher.css";

import screenimg from "../assets/screenimg.png";
import progresstracker from "../assets/Blogo.png";
import LoginHeader from "../components/LoginHeader/LoginHeader";

interface SignupFormValues {
  salutation: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  className: string;
  remember?: string;
  image: { file: File };
}

interface DecodedToken {
  email: string;
}

const { Option } = Select;

const salutationOptions = ["Male", "Female"];

const SignUpTeacher: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  console.log("isChecked", isChecked);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      const decoded: DecodedToken = jwtDecode(token);
      setEmail(decoded.email);
      localStorage.setItem("studenttoken", token);
      console.log(token);
    }
  }, [location]);

  const onFinish = async (values: SignupFormValues) => {
    // if(values.salutation==null|| values.firstname==null || values.lastname==null || values.password==null || values.phone==null || values.image.file==null){
    //   notification.error({
    //     message: "You can't proceed further untill you fill all the filled",
    //   });
    //   return
    // }
    setLoading(true);
    if (!values.remember) {
      notification.error({
        message:
          "You can't proceed further untill you agree with our terms and condition",
      });
      setLoading(false);
      return;
    }
    try {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");

      const formData = new FormData();
      formData.append("salutation", values.salutation);
      formData.append("firstName", values.firstname);
      formData.append("lastName", values.lastname);
      formData.append("password", values.password);
      formData.append("phoneNumber", values.phone);

      formData.append("pictures", values.image.file);

      if (token) {
        formData.append("token", token);
      }

      const result = await signupTeacher(formData);
      console.log("teacherr", result);
      if (result) {
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to create student:", error);
    }
    setLoading(false);
  };

  return (
    <div className="credentials-page">
      <LoginHeader />
      <Row>
        <Col xs={24} lg={12}>
          <div className="login-container">
            <div className="auth-form">
              <h1>Sign Up</h1>
              <p>Please enter your details.</p>
              <Form
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ email }}
              >
                <Form.Item
                  name="salutation"
                  label="Salutation"
                  rules={[
                    { required: true, message: "Please enter your Gender" },
                  ]}
                >
                  <Select
                    style={{ width: "100%", borderRadius: "8px" }}
                    placeholder="Select"
                  >
                    {salutationOptions.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="Enter Your First Name"
                  />
                </Form.Item>

                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <Input className="input" placeholder="Enter Your Last Name" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please enter your password" },
                  ]}
                >
                  <Input
                    type="password"
                    className="input"
                    placeholder="Enter your Password"
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="Enter your Phone Number"
                  />
                </Form.Item>

                <Form.Item
                  name="image"
                  label="Upload Image"
                  rules={[
                    { required: true, message: "Please upload your image" },
                  ]}
                >
                  <Upload beforeUpload={() => false} listType="picture">
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox onChange={() => setIsChecked(!isChecked)}>
                    I agree to Progress Tracker’s Terms of Use and Privacy
                    Policy
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="submit-btn"
                    loading={loading}
                    disabled={!isChecked}
                  >
                    Sign Up
                  </Button>
                </Form.Item>

                <p className="caution">
                  Already have an account?{" "}
                  <span onClick={() => navigate("/login")}>Login</span>
                </p>
              </Form>

              {/* <p
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                already have an account? <strong>Log In</strong>
              </p> */}
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="image-div">
            <img src={screenimg} alt="" className="screenimg" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUpTeacher;
