import React, { useEffect, useState } from 'react'
import "../../styles/pages/examDetails.css"
import { useParams } from 'react-router-dom'
import CustomTable from '../createAssesment/CustomTable'
import { fetchSingleExamDetails } from '../../store/services/ExamDetailService'
import { subjects } from '../../components/ProjectSelect/LevelTwoOptions'
import { Flex, Spin } from 'antd'
import CustomPagination from '../../components/Pagination/CustomPagination'

const ExamDetails = () => {

    const [subjects, setSubjects] = useState<any>(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [grades,setGrades] = useState([]);



    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await fetchSingleExamDetails({ examId: id });
                if (res.subjects.length > 0) {
                    setSubjects(res.subjects);
                    setGrades(res.grads);
                }

            } catch (error) {
                console.log("error", error);
            }
            finally {
                setLoading(false);
            }
        })()
    }, [id]);

    console.log("grads",grades);

    return (
        <div className='mainDiv responsive'>
            <h3 className='exam_heading'>Exam Details</h3>
            <div className='main_details'>
                {
                    loading ? <Flex justify='center' style={{ height: "100%" }} align='center'>
                        <Spin size='large' />
                    </Flex> : <>
                        {
                           subjects && subjects?.length > 0 && <div className='table_details'>
                                <div className="custom-table-parent">

                                    { subjects && subjects?.length>0 && subjects?.map((v: any, i: any) => {
                                        return <CustomTable grades={grades} key={i} tableData={v} />;
                                    })}
                                </div>
                            </div>
                        }
                    </>
                }
            </div>

            {/* {
                subjects.length === 0 || subjects.length <= 0 &&
                <div className='EmptyData'>
                    No Data Found
                </div>
            } */}


        </div>
    )
}

export default ExamDetails;