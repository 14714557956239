import store from "..";
import { privateAPI } from "../../config/constants";
import {
  setLoadingCounts,
  setUnreadCounts,
} from "../slices/notificationCount/NotificationsCount";

export const getNotificationsCount = async () => {
  try {
    store.dispatch(setLoadingCounts(true));
    const response = await privateAPI.get("/notification/get-counts");
    if (response) {
      store.dispatch(setUnreadCounts(response?.data?.unreadCount));
      // setAllUnreadCounts(store.dispatch(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
