import React, { useCallback, useState, useEffect } from "react";
import {
  Input,
  Select,
  Table,
  Space,
  Tooltip,
  Tag,
  Spin,
  Flex,
  Button,
  Breadcrumb,
} from "antd";
import { CiSearch } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import "../../styles/pages/teacherReview.css";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import TeacherCard from "../../components/Cards/TeacherCard";
import { getAllTeachersList, getByClass } from "../../store/services/teacher";
import { useNavigate } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { debounce } from "lodash";

const TeacherReview = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [teacher, setTeacher] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classId, setClassId] = useState<null | number>();
  const { allTeachers, loadingTeachers } = useAppSelector(
    (state) => state.teacher
  );

  const fetchTeachers = async () => {
    try {
      setLoading(true);
      const payload = {
        page: currentPage,
        perPage: 10,
        search: "",
      };
      const res = await getAllTeachersList(payload);
      console.log("res>>>", res);

      // setCount(res.count);
      setTeacher(res?.records);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchByClass = async () => {
    setLoading(true);
    try {
      const payloads = {
        page: 1,
        perPage: 30,
        classId: classId,
        name: search,
      };
      const res = await getByClass(payloads);
      console.log("teacher search >>>>", res?.data.teachers);
      setTeacher(res?.data.teachers);
      //   setCount(res?.data.total);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchByClass = useCallback(debounce(fetchByClass, 1000), [
    search,
    classId,
  ]);

  useEffect(() => {
    if (classId || search) {
      debouncedFetchByClass();
      return () => {
        debouncedFetchByClass.cancel();
      };
    } else {
      fetchTeachers();
    }
  }, [search, classId, debouncedFetchByClass]);

  return (
    <div className="mainDiv responsive">
      <div className="main_parent">
        <div className="wrapper">
          <Breadcrumb
            className="Breadcrumb"
            separator={<MdOutlineChevronRight className="right_icon" />}
            items={[
              {
                title: <GoHome />,
                className: "gohome",
              },
              {
                title: "Dashboard",
                onClick: () => navigate("/"),
              },
              {
                title: "Teachers",
                className: "active_breadCrum",
              },
            ]}
          />
          <div className="header">
            <div className="top">
              <p className="title">Teachers</p>
              <p className="desc">Review and search teachers.</p>
            </div>
          </div>
          <div className="fliters_container">
            <Input
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              prefix={
                <CiSearch
                  style={{ width: "20px", height: "20px", color: "#667085" }}
                />
              }
              className="filter_search"
            />
          </div>
        </div>
        <div className="container" style={{height:'50vh',overflow:'scroll'}}>
          {loading ? (
            <Flex
              align="center"
              justify="center"
              style={{ height: "50vh" }}
              gap="middle"
            >
              <Spin size="large" />
            </Flex>
          ) : (
            <>
              <div className="card">
                {teacher?.map((item: any) => {
                  return <TeacherCard key={item?.name} data={item} />;
                })}
              </div>
            </>
          )}
        </div>
        <div className="main_pagination">
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={allTeachers.length}
          />
        </div>
      </div>
    </div>
  );
};
export default TeacherReview;
