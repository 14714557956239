import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCurriculum } from '../../interfaces/Curriculum/Curriculum';

interface CurriculumState {
    curriculums: createCurriculum ;
    loading: boolean;
}
  
const initialState: CurriculumState = {
   curriculums: {
       subjectId: 0,
       duration: '',
       mindsetToFocusOn: '',
       practicesTopicsToFocusOn: '',
       primaryFocusSubject: '',
       primaryFocusQuaidahQuran: ''
   } ,
    loading: false,
};



const curriculumSlice = createSlice({
    name: 'subject',
    initialState,
    reducers: {
      setCurriculum(state, action: PayloadAction<createCurriculum>) {
        state.curriculums = action.payload;
      },
      setLoadingCurriculums(state, action: PayloadAction<boolean>) {
        state.loading = action.payload;
      },
      addCurriculum(state, action: PayloadAction<createCurriculum>) {
        state.curriculums=(action.payload);
      },
    },
});

export const { setCurriculum, setLoadingCurriculums, addCurriculum } = curriculumSlice.actions;

export default curriculumSlice.reducer;

