import React from 'react'

// @ts-ignore
import AvatarImage from '../../assets/avatar_teacher.png'

const TeacherCard = ({data}:any) => {
  return (
    <div className='teacher_performance_card'>
      <div className='border_blue' />
      <div className='card_left'>
        {
          data.imageUrl?
          <img src={data.imageUrl} alt='profile' />
          :
          <img src={AvatarImage} alt='profile' />
        }
        <div className='info_container'>
          <p className='name additionalTeacherClass'>{data?.firstName} {data?.LastName}</p>
          {data?.class && <p className='student_class'>{data?.class}</p>}
        </div>
      </div>

      {/* <div className='class_tag'>{data?.className}</div> */}
    </div>
  )
}

export default TeacherCard