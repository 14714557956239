import { privateAPI } from "../../config/constants";

export const getLevelProgress = async (
  classId: number,
  subjectId: number,
  studentId: any
) => {
  try {
    const res = await privateAPI.post("/learning/get-current-level-progress", {
      classId,
      subjectId,
      id: studentId,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error monitoring", error);
  }
};

export const getTargetsToReachNextLevel = async (
  classId: number,
  subjectId: number,
  studentId: number
) => {
  try {
    const res = await privateAPI.post("/learning/get-targets-for-next-level", {
      classId,
      subjectId,
      id: studentId,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error monitoring", error);
  }
};

export const getLearningYears = async (studentId: any) => {
  try {
    const res = await privateAPI.post("/learning/get-learnings-year", {
      id: studentId,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log("error get learning years", error);
  }
};

export const getLearningByYear = async (
  year: string,
  subjectId: number,
  id: number
) => {
  try {
    const res = await privateAPI.post("/learning/get-by-year", {
      year,
      subjectId,
      id,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error monitoring", error);
  }
};

export const getpredictionLevel = async (data:any) => {
  try {
    const res = await privateAPI.post("/student/prediction-level", data);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error monitoring", error);
  }
};